










































import { Component, Mixins, Prop } from 'vue-property-decorator'
import ModalHeader from '@/components/modals/components/ModalHeader.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import RepairModule from '@/store/modules/fleet/repair'

@Component({
  components: {
    ModalHeader,
  },
})
export default class RepairToApproveModal extends Mixins(NotifyMixin) {
  @Prop({
    default: 'red',
    validator (value: string) :boolean {
      return !!value.match(/(red|dark-blue|grey-blue)/)
    },
  })
  readonly mod!: string

  @Prop({ default: () => ({}) })
  private options!: {
    vin: string,
    total: string,
    detail: string,
    id: number,
    entity: number,
  }

  private toLocaleDigits (num: any) {
    return new Intl.NumberFormat('ru-RU').format(num)
  }

  private sendApprove () {
    const body: any = {
      status: 'Согласован',
    }
    RepairModule.sendApprove({ inspectionReportId: this.options.id, body })
      .then(() => {
        this.fetchTableData()
        this.$closeModal()
      })
  }

  private fetchTableData () {
    RepairModule.getStatusDictionary(this.options.entity)
    RepairModule.getTableData(this.options.entity)
      .then(() => {
        RepairModule.getStatusDictionary(this.options.entity)
      })
  }
}
