import { render, staticRenderFns } from "./Volvo.vue?vue&type=template&id=458e5e69&scoped=true&lang=pug&"
import script from "./Volvo.vue?vue&type=script&lang=ts&"
export * from "./Volvo.vue?vue&type=script&lang=ts&"
import style0 from "./Volvo.vue?vue&type=style&index=0&id=458e5e69&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458e5e69",
  null
  
)

export default component.exports