


















// CORE
import { Component, Vue } from 'vue-property-decorator'

// STORE
import SystemModule from '@/store/modules/system'

// COMPONENTS
import AddServicesDrawer from '@/components/drawers/AddServicesDrawer.vue'
import ApplicationsFilterForm from '@/components/forms/filters/ApplicationsFilterForm.vue'
import CarparkFilterForm from '@/components/forms/filters/CarparkFilterForm.vue'
import RepairFilterForm from '@/components/forms/filters/RepairFilterForm.vue'
import PoliciesFilterForm from '@/components/forms/filters/PoliciesFilterForm.vue'
import PenaltyFilterForm from '@/components/forms/filters/PenaltyFilterForm.vue'
import InsuranceFilterForm from '@/components/forms/filters/InsuranceFilterForm.vue'
import ReportDrawer from '@/views/role/entity/reports/components/drawers/ReportDrawer.vue'
import NewOrderDrawer from '@/components/drawers/new-order-drawer/NewOrderDrawer.vue'
import BillsFilterForm from '@/components/forms/filters/BillsFilterForm.vue'

@Component({
  components: {
    AddServicesDrawer,
    ApplicationsFilterForm,
    CarparkFilterForm,
    RepairFilterForm,
    PenaltyFilterForm,
    PoliciesFilterForm,
    InsuranceFilterForm,
    BillsFilterForm,
    NewOrderDrawer,
    ReportDrawer,
  },
})
export default class Drawer extends Vue {
  //- STORE
  private set isOpen (payload: boolean) {
    if (!payload) {
      this.$closeDrawer(payload)
    }
  }

  private get isOpen (): boolean {
    return SystemModule.drawer.isOpen
  }

  private get componentName (): string {
    return SystemModule.drawer.componentName
  }

  private get options (): any {
    return SystemModule.drawer.options
  }

  private get heading (): any {
    return SystemModule.drawer?.options?.heading ?? 'Фильтры'
  }

  private get width (): number | string {
    if (this.$vuetify.breakpoint.xlOnly) {
      return 820
    } else if (this.$vuetify.breakpoint.lgOnly) {
      return 708
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return 520
    } else {
      return '100%'
    }
  }
}
