






















































// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// LIBRARIES
import Draggable from 'vuedraggable'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'

// INTERFACES
import { ITableHeader } from '@/store/types'

@Component({
  components: {
    Draggable,
    ModalHeader,
  },
})
export default class TableSettingForm extends Mixins(SystemMixin) {
  @Prop({ default: null })
  private noSortName!: string

  @Prop({ default: () => ([]) })
  private tableHeaders!: ITableHeader[]

  private innerValue: ITableHeader[] = []

  private noSortValue!: ITableHeader | undefined

  private get all (): boolean {
    const vixibleCounter = this.innerValue.filter(item => item.visible).length

    return this.innerValue.length === vixibleCounter
  }

  private set all (value: boolean) {
    this.innerValue.forEach(item => {
      if (item.value !== this.noSortName) {
        item.visible = value
      }
    })
  }

  private get indeterminate (): boolean {
    const vixibleCounter = this.innerValue.filter(item => item.visible).length
    return this.innerValue.length !== vixibleCounter
  }

  private created () {
    if (this.role === 'mechanic') {
      this.innerValue = this.tableHeaders
        .filter((item: ITableHeader) => item.value !== this.noSortName && item.value !== 'monthlyRentalPayment')
        .map((item: ITableHeader) => ({ ...item }))
    } else {
      this.innerValue = this.tableHeaders
        .filter((item: ITableHeader) => item.value !== this.noSortName)
        .map((item: ITableHeader) => ({ ...item }))
    }
    this.noSortValue = this.tableHeaders.find((item: ITableHeader) => item.value === this.noSortName)
  }

  private saveSettings () {
    if (this.noSortValue) {
      this.innerValue.unshift(this.noSortValue)
    }

    this.$emit('update:tableHeaders', this.innerValue);
    (this as any).$closeModal()
  }
}
