





import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class Paragraph extends Vue {
  @Ref()
  ref!: HTMLElement

  @Prop({
    default: 'div',
    validator (value: string): boolean {
      return !!value.match(/(span|p|div)/)
    },
  })
  readonly tag!: string

  @Prop({
    default: 'subtitle',
    validator (value: string): boolean {
      // subtitle - lg, body - md, caption - sm
      // Обозначил так потому что так на макете.
      return !!value.match(/(subtitle|body|caption|label)/)
    },
  })
  readonly size!: string

  @Prop({
    default: 'regular',
    validator (value: string): boolean {
      return !!value.match(/(regular|medium|bold)/)
    },
  })
  readonly weight!: string

  @Prop({
    default: 'default',
    validator (value: string): boolean {
      return !!value.match(/(light|dark|color-grey|color-red|default|inherit|color-dark-blue)/)
    },
  })
  readonly theme!: string

  @Prop({ default: true })
  readonly isInline!: boolean

  @Prop({ default: false })
  readonly isSelectable!: boolean

  private get classes () {
    return [
      `size--${this.size}`,
      `weight--${this.weight}`,
      this.theme,
      {
        'd-inline-block': this.isInline,
        'selectable': this.isSelectable,
      },
    ]
  }

  protected handleClick (e: Event) {
    if (this.isSelectable) {
      e.stopPropagation()
    } else {
      // e.preventDefault()
      this.$emit('click')
    }
  }
}
