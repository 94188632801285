import Vue from 'vue'
import VueMask from 'v-mask'

// METHODS
import CommonMethods from '@/utils/plugins/methods/index'

// LIBRARIES
import vueCompositionApi from '@vue/composition-api'

// COMMON
import Card from '@/components/cards/Card.vue'

// TYPOGRAPHY
import Heading from '@/components/_uikit/typography/Heading.vue'
import Paragraph from '@/components/_uikit/typography/Paragraph.vue'
import ParagraphWithTitle from '@/components/_uikit/typography/ParagraphWithTitle.vue'

// CONTROLS
import Chips from '@/components/_uikit/controls/Chips.vue'
import Combobox from '@/components/_uikit/controls/Combobox.vue'
import DatePicker from '@/components/_uikit/controls/DatePicker.vue'
import Dropdown from '@/components/_uikit/controls/Dropdown.vue'
import RadioGroup from '@/components/_uikit/controls/RadioGroup.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'

// LINKS
import Link from '@/components/_uikit/links/Link.vue'
import LinkBack from '@/components/_uikit/links/LinkBack.vue'
import LinkFile from '@/components/_uikit/links/LinkFile.vue'

// UNCATEGORY
import Breadcrumbs from '@/components/_uikit/Breadcrumbs.vue'
import Chart from '@/components/_uikit/Chart.vue'
import Tabs from '@/components/_uikit/Tabs.vue'

// PLUGINS
Vue.use(VueMask)
Vue.use(vueCompositionApi)
Vue.use(CommonMethods)

// COMPONENTS
// COMMON
Vue.component('Card', Card)
// TYPOGRAPHY
Vue.component('Heading', Heading)
Vue.component('Paragraph', Paragraph)
Vue.component('ParagraphWithTitle', ParagraphWithTitle)
// CONTROLS
Vue.component('Chips', Chips)
Vue.component('Combobox', Combobox)
Vue.component('DatePicker', DatePicker)
Vue.component('Dropdown', Dropdown)
Vue.component('RadioGroup', RadioGroup)
Vue.component('Select', Select)
Vue.component('TextInput', TextInput)
// LINK
Vue.component('Link', Link)
Vue.component('LinkBack', LinkBack)
Vue.component('LinkFile', LinkFile)
// UNCATEGORY
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('Chart', Chart)
Vue.component('Tabs', Tabs)
