






import { Component, Mixins, Prop } from 'vue-property-decorator'
import NotifyMixin from '@/mixins/NotifyMixin'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'
import { openNewTab } from '@/utils/functions'
import { IModalExportOptions } from '@/store/types'
import SystemMixin from '@/mixins/SystemMixin'
import PenaltyExcelDateRangeForm from '@/components/forms/PenaltyExcelDateRangeForm.vue'

interface IDateRange {
  startDate: string,
  endDate: string,
}


@Component({
  components: {
    ModalHeader,
    PenaltyExcelDateRangeForm,
  },
})

export default class ExcelDownloadModal extends Mixins(NotifyMixin, SystemMixin) {
  @Prop({ required: true })
  private options!: IModalExportOptions

  private handleChangeDate (date: IDateRange) {
    try {
      const payload: any = {}
      if (date && date.endDate && date.startDate) {
        payload.endDate = date.endDate
        payload.startDate = date.startDate
      }

      Object.assign(payload, this.options.filters)
      payload.ids = this.options.ids

      openNewTab(`${this.options.exportURL}${+this.entity}`, payload, false)
      this.$closeModal()
    } catch (e) {
      this.notifyError(e)
    }
  }

}
