





import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class Card extends Vue {
  @Prop({
    default: 'div',
    validator (value: string): boolean {
      return !!value.match(/(div|a|span|router-link)/)
    },
  })
  private tag!: string

  @Prop({
    default: 'md',
    validator (value: string): boolean {
      return !!value.match(/xxl|xl|lg|md|sm|xs|min|none/)
    },
  })
  private paddingSize!: string

  @Prop({
    default: 'space-between',
    validator (value: string): boolean {
      return !!value.match(/grid|space-between/)
    },
  })
  private display!: string

  @Prop({
    default: 'full',
    validator (value: string): boolean {
      return !!value.match(/full|half|unique/)
    },
  })
  private gridSize!: string

  @Prop({
    default: 'white',
    validator (value: string): boolean {
      return !!value.match(/white|transparent|red|red-lighten4|grey-blue/)
    },
  })
  private color!: string

  @Prop({
    default: 'transparent',
    validator (value: string): boolean {
      return !!value.match(/transparent|red-lighten4|grey-blue/)
    },
  })
  private borderColor!: string

  @Prop({
    default: false,
  })
  private isTable!: boolean

  private get classes (): string {
    const display = `card--display-${this.display}`
    const gridSize =
      this.display === 'grid'
        ? `card--grid-size-${this.gridSize}`
        : ''
    const paddingSize = `pdng-${this.paddingSize}`
    const color = `card--color-${this.color}`
    const cardsTable = 'cards-table'
    const borderColor = `card--border-color-${this.borderColor}`

    return display + ' ' + paddingSize + ' ' + color + ' ' + gridSize + ' ' + (this.isTable ? cardsTable : '') + ' ' + borderColor
  }
}
