



































// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// COMPONENTS
import Card from '@/components/cards/Card.vue'
import ModalHeader from '@/components/modals/components/ModalHeader.vue'

// HELPERS
import { handlePhoneNumber } from '@/utils/functions'
import OrderModule from '@/store/modules/order'

@Component({
  components: {
    ModalHeader,
    Card,
  },
})
export default class OrderSendSuccessModal extends Mixins(NotifyMixin) {
  @Prop({ default: () => ({}) })
  private options!: { route: any }

  private phone = '+7 (999) 999-99-99'
  private email = 'info@mcintermarkauto.com'

  private created () {
    OrderModule.getOrderContacts(+this.options.route.params.orderId)
      .then((res) => {
        this.phone = res.phone
        this.email = res.email
      })
  }

  private toOrder () {
    if (this.options.route?.name?.length) {
      this.$router.push(this.options.route)
      this.$closeModal()
    } else {
      this.notifyError('Что пошло не так...')
      this.$router.push({ name: 'orders' })
      this.$closeModal()
    }
  }

  private handleClose () {
    this.$router.push({ name: 'orders' })
  }

  private handlePhoneNumber = handlePhoneNumber
}
