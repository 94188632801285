// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store, { GET_DEFAULT_PAGINATOR_META } from '@/store'

// INTERFACES
import {
  ApplicationTabName,
} from '@/store/types'

// SCHEMA TYPES
import {
  DictionaryRequestResource,
  RequestPaginator,
  CountResource,
  UserTypeEnum,
  RequestsCarParkIdGetParams,
} from '@/store/types/schema'

// SCHEMA METHODS
import {
  RequestsCarParkIdGetRequest,
  RequestsCarParkIdCountsGetRequest,
  DictionariesRequestFleetCarParkIdGetRequest,
} from '@/store/types/schema'

interface FleetApplications {
  [key: string]: any,
}

@Module({
  dynamic: true,
  name: 'fleet-applications',
  store,
  namespaced: true,
})
class FleetApplications extends VuexModule {
  // 0==================================================== TABLE DATA ====================================================+o>

  allApplications: RequestPaginator = {
    meta: GET_DEFAULT_PAGINATOR_META(),
    data: [],
    xlsxRoute: '',
  }

  driverApplications: RequestPaginator = {
    meta: GET_DEFAULT_PAGINATOR_META(),
    data: [],
    xlsxRoute: '',
  }

  managerApplications: RequestPaginator = {
    meta: GET_DEFAULT_PAGINATOR_META(),
    data: [],
    xlsxRoute: '',
  }

  @Mutation
  setTableData (payload: { tabName: ApplicationTabName, data: RequestPaginator }) {
    Vue.set(this, `${payload.tabName}Applications`, payload.data)
  }

  @Action({ rawError: true })
  async getTableData (payload: { entity: number, tabName: ApplicationTabName }) {
    const { data } = await RequestsCarParkIdGetRequest(
      payload.entity,
      payload.tabName === 'all'
        ? this[`${payload.tabName}Filters`]
        : {
          ...this[`${payload.tabName}Filters`],
          type: payload.tabName as UserTypeEnum,
        },
    )
    this.setTableData({ tabName: payload.tabName, data })
  }

  // 0==================================================== TABLE STATUSES ====================================================+o>

  allStatuses: CountResource[] = []

  driverStatuses: CountResource[] = []

  managerStatuses: CountResource[] = []

  @Mutation
  setTableStatuses (payload: { tabName: ApplicationTabName, data: CountResource[] }) {
    Vue.set(this, `${payload.tabName}Statuses`, payload.data)
  }

  // NOTE: Запрос статусов со счётчиками, в параметры прокидываем фильтры для верных данных
  @Action({ rawError: true })
  async getTableStatuses (payload: { entity: number, tabName: ApplicationTabName }) {
    const { data } = await RequestsCarParkIdCountsGetRequest(
      payload.entity,
      payload.tabName === 'all'
        ? this[`${payload.tabName}Filters`]
        : {
          ...this[`${payload.tabName}Filters`],
          type: payload.tabName as UserTypeEnum,
        },
    )

    this.setTableStatuses({ tabName: payload.tabName, data })
  }

  // 0==================================================== ACTIVE STATUS ====================================================+o>

  @Mutation
  setActiveStatus (payload: { tabName: ApplicationTabName, data: string | undefined }) {
    Vue.set(this[`${payload.tabName}Filters`], 'status', payload.data)
    Vue.set(this[`${payload.tabName}Filters`], 'page', 1)
  }

  // 0==================================================== TABLE DICTIONARIES ====================================================+o>

  allDictionaries: DictionaryRequestResource = {
    vin: [],
    number: [],
    category: [],
  }

  driverDictionaries: DictionaryRequestResource = {
    vin: [],
    number: [],
    category: [],
  }

  managerDictionaries: DictionaryRequestResource = {
    vin: [],
    number: [],
    category: [],
  }

  @Mutation
  setDictionaries (payload: { tabName: ApplicationTabName, data: DictionaryRequestResource}) {
    Vue.set(this, `${payload.tabName}Dictionaries`, payload.data)
  }

  @Action({ rawError: true })
  async getDictionaries (payload: { entity: number, tabName: ApplicationTabName }) {
    const { data } = await DictionariesRequestFleetCarParkIdGetRequest(
      payload.entity,
      payload.tabName !== 'all' ? payload.tabName : undefined,
    )

    this.setDictionaries({ tabName: payload.tabName, data })
  }

  // 0==================================================== TABLE FILTERS ====================================================+o>

  allFilters: RequestsCarParkIdGetParams = {
    page: 1,
    perPage: 5,
  }

  driverFilters: RequestsCarParkIdGetParams = {
    page: 1,
    perPage: 5,
  }

  managerFilters: RequestsCarParkIdGetParams = {
    page: 1,
    perPage: 5,
  }

  @Mutation
  setTableFilters (payload: { tabName: ApplicationTabName, data: RequestsCarParkIdGetParams }) {
    Vue.set(this, `${payload.tabName}Filters`, {
      ...this[`${payload.tabName}Filters`],
      ...payload.data,
    })
  }

  @Mutation
  resetTableFilters (tabName: ApplicationTabName) {
    Vue.set(this, `${tabName}Filters`, {
      perPage: this[`${tabName}Filters`].perPage,
      page: 1,
    })
  }
}

const FleetApplicationsModule = getModule(FleetApplications)

export default FleetApplicationsModule
