import { render, staticRenderFns } from "./Suzuki.vue?vue&type=template&id=7c5f0732&scoped=true&lang=pug&"
import script from "./Suzuki.vue?vue&type=script&lang=ts&"
export * from "./Suzuki.vue?vue&type=script&lang=ts&"
import style0 from "./Suzuki.vue?vue&type=style&index=0&id=7c5f0732&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c5f0732",
  null
  
)

export default component.exports