// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// INTERFACES
import { ModalModType, IModal, IDrawer } from '@/store/types'

/**
 * Общие для всего приложения функции
 * - спиннер загрузки
 */

@Module({
  dynamic: true,
  name: 'system',
  store,
})
class System extends VuexModule {
  // ---------------------------- Loading ---------------------------- >>

  loading = false
  loadingCounter = 0

  @Mutation
  incrementLoading () {
    this.loadingCounter += 1
    this.loading = true
  }

  @Mutation
  decrementLoading () {
    if (this.loadingCounter > 0) {
      this.loadingCounter -= 1
      if (this.loadingCounter <= 0) {
        this.loading = false
      }
    }
  }

  @Action({ rawError: true })
  loadingStart () {
    this.incrementLoading()
  }

  @Action({ rawError: true })
  loadingEnd () {
    this.decrementLoading()
  }

  // ---------------------------- Drawer ---------------------------- >>
  drawer: IDrawer = {
    isOpen: false,
    componentName: '',
    options: null,
  }

  @Mutation
  openDrawer (payload: { componentName: string, options?: any }) {
    Vue.set(this.drawer, 'isOpen', true)
    Vue.set(this.drawer, 'componentName', payload.componentName)

    if (payload.options) {
      Vue.set(this.drawer, 'options', payload.options)
    }
  }

  @Mutation
  closeDrawer (payload: boolean) {
    Vue.set(this.drawer, 'isOpen', payload)

    setTimeout(() => {
      Vue.set(this.drawer, 'componentName', '')
    }, 300)
  }

  @Mutation
  clearDrawerOptions () {
    this.drawer.options = null
  }

  modal: IModal = {
    isOpen: false,
    componentName: '',
    mod: 'big',
    options: null,
  }

  @Mutation
  openModal (payload: { componentName: string, mod: ModalModType, options?: any }) {
    Vue.set(this.modal, 'isOpen', true)
    Vue.set(this.modal, 'componentName', payload.componentName)
    Vue.set(this.modal, 'mod', payload.mod)

    if (payload.options) {
      Vue.set(this.modal, 'options', payload.options)
    }
  }

  @Mutation
  closeModal (payload: boolean) {
    Vue.set(this.modal, 'isOpen', payload)
    setTimeout(() => {
      Vue.set(this.modal, 'componentName', '')
    }, 300)
  }

  @Mutation
  clearModalOptions () {
    this.drawer.options = null
  }
}

const SystemModule = getModule(System)

export default SystemModule
