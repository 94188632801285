






























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import ModalHeader from '@/components/modals/components/ModalHeader.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import RepairModule from '@/store/modules/fleet/repair'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

@Component({
  components: {
    ModalHeader,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RepairToContestModal extends Mixins(NotifyMixin) {
  @Prop({
    default: 'red',
    validator (value: string) :boolean {
      return !!value.match(/(red|dark-blue|grey-blue)/)
    },
  })
  readonly mod!: string

  @Prop({ default: () => ({}) })
  private options!: {
    vin: string,
    total: string,
    detail: string,
    id: number,
    entity: number,
  }

  private message = ''

  private toLocaleDigits (num: any) {
    return new Intl.NumberFormat('ru-RU').format(num)
  }

  private sendContest () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const body: any = {
            status: 'Оспаривается',
            comment: this.message,
          }
          RepairModule.sendApprove({ inspectionReportId: this.options.id, body })
            .then(() => {
              this.fetchTableData()
              this.$closeModal()
            })
        }
      })

  }

  private fetchTableData () {
    RepairModule.getStatusDictionary(this.options.entity)
    RepairModule.getTableData(this.options.entity)
      .then(() => {
        RepairModule.getStatusDictionary(this.options.entity)
      })
  }
}
