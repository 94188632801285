





















// CORE
import { Component, Prop, Mixins } from 'vue-property-decorator'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// INTERFACES
import { INavigateItem } from '@/store/types'

@Component({})
export default class NavigateList extends Mixins(SystemMixin) {
  @Prop({ default: () => ([]) })
  private items!: INavigateItem[]

  @Prop({
    default: 'red',
    validator (value: string) :boolean {
      return !!value.match(/(red|dark-blue|grey-blue)/)
    },
  })
  readonly mod!: string
}
