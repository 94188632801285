// Core
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// Store
import store from '@/store'

// Schema
import { NewsGetRequest, NewsResource } from '@/store/types/schema'

/**
 * Общие для всего приложения функции
 * - спиннер загрузки
 */

@Module({
  dynamic: true,
  name: 'news',
  store,
})
class News extends VuexModule {
  news: NewsResource[] = []

  @Mutation
  setNews (payload: NewsResource[]) {
    Vue.set(this, 'news', payload)
  }

  @Action({ rawError: true })
  async getNews () {
    const { data } = await NewsGetRequest()

    this.setNews(data)
  }
}

const NewsModule = getModule(News)

export default NewsModule
