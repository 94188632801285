// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// INTERFACES
// import {
//   IOrderTypeName,
// } from '@/store/types'

// SCHEMA TYPES
import {
  CarRequestDataResource,
  DictionariesRequestsCheckReplacementCarParkIdGetRequest,
  NameValueResource,
  RequestsCarParkIdCarGetParams,
} from '@/store/types/schema'

// SCHEMA METHODS
import {
  RequestsInfoRequestIdGetRequest,
  RequestsCarParkIdCarGetRequest,
  DictionariesMaintenanceGetRequest,
  DictionariesTireServiceGetRequest,
} from '@/store/types/schema'

/**
 * Общие для обоих ролей функции заявок
 * - выбранные услуги(CHOSEN SERVICES)
 * - поиск автомобиля(SEARCH CAR)
 * - получить контакты по заявке(CONTACTS FOR ORDER)
 * - получить флаг наличия оплаченной услуги подменный авто (PAID REPLACEMENT CARS)
 */

@Module({
  dynamic: true,
  name: 'order',
  store,
})
class Order extends VuexModule {

  // 0============================== SERVICES SUBTYPE ==============================+o>
  // Сохранение подтипа заявки.

  serviceSubtype = ''

  @Mutation
  setServiceSubtype (payload: string) {
    this.serviceSubtype = payload
  }

  // 0============================== SERVICES DICTIONARIES ==============================+o>
  // Списки услуг для ТО и для Шинного сервиса.

  maintenanceServicesDictionaries: NameValueResource[] = []
  tireServicesDictionaries: NameValueResource[] = []

  @Mutation
  setMaintenanceServicesDictionaries (payload: NameValueResource[]) {
    Vue.set(this, 'maintenanceServicesDictionaries', payload)
  }

  @Mutation
  setTireServicesDictionaries (payload: NameValueResource[]) {
    Vue.set(this, 'tireServicesDictionaries', payload)
  }

  @Action({ rawError: true })
  async getMaintenanceServicesDictionaries () {
    const { data } = await DictionariesMaintenanceGetRequest()

    this.setMaintenanceServicesDictionaries(data.maintenanceServices)
  }

  @Action({ rawError: true })
  async getTireServicesDictionaries () {
    const { data } = await DictionariesTireServiceGetRequest()

    this.setTireServicesDictionaries(data.tireServices)
  }

  // 0============================== SEARCH CAR ==============================+o>
  // Поиск совпадений по VIN, гос.номеру или номеру заказа на аренду для отображения на странице создания заявки.

  carSearchResponse: CarRequestDataResource = {
    id: 0,
    vin: '',
    stateNumber: '',
    orderNumber: '',
    model: '',
    brand: '',
    currentMileage: '',
    region: '',
    haulForStoAndTireFitting: false,
    stsNumber: '',
    stsSeria: '',
    stsStartDate: '',
  }

  carSearchParams: RequestsCarParkIdCarGetParams = {}

  @Mutation
  setSearchParams (payload: RequestsCarParkIdCarGetParams) {
    Vue.set(this, 'carSearchParams', payload)
  }

  @Mutation
  setCarInfo (payload: CarRequestDataResource) {
    Vue.set(this, 'carSearchResponse', payload)
  }

  @Mutation
  setCurrentMileage (payload: string) {
    Vue.set(this.carSearchResponse, 'currentMileage', payload)
  }

  @Action({ rawError: true })
  async getCarInfo (payload: number) {
    const { data } = await RequestsCarParkIdCarGetRequest(payload, this.carSearchParams)

    this.setCarInfo(data)
  }

  // 0============================== MAINTENANCE TYPE ==============================+o>
  maintenanceType = ''

  @Mutation
  setMaintenanceType (payload: string) {
    this.maintenanceType = payload
  }

  // 0============================== CONTACTS FOR ORDER ==============================+o>
  @Action({ rawError: true })
  async getOrderContacts (requestId: number) {
    const { data } = await RequestsInfoRequestIdGetRequest(requestId)

    return data
  }

  // 0============================== PAID REPLACEMENT CARS ==============================+o>
  @Action({ rawError: true })
  async getPaidReplacementCarFlag (carparkId: number) {
    const { data } = await DictionariesRequestsCheckReplacementCarParkIdGetRequest(carparkId)

    return data
  }
}

const OrderModule = getModule(Order)

export default OrderModule
