























import { NameValueResource } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class Select extends Vue {
  @Prop({ default: null })
  private value!: NameValueResource

  @Prop({ default: null })
  private error!: string

  @Prop({ default: false })
  private small!: boolean

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: 'value' })
  private itemValue!: string

  @Prop({ default: false })
  private invalid!: boolean

  private get innerValue (): NameValueResource {
    return this.value
  }

  private set innerValue (value: NameValueResource) {
    this.$emit('input', value)
    this.$emit('changeSelect', value)
  }
}
