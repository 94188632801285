






































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// LIBRARIES
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed } from 'vee-validate/dist/rules'

// TYPES
import { ChangePasswordRequestBody } from '@/store/types'

// COMPONENTS
import ExpansionMessageCard from '@/components/cards/ExpansionMessageCard.vue'
import ProfileModule from '@/store/modules/profile'
import SmallModalFooter from '@/components/modals/components/SmallModalFooter.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

extend('confirmed', {
  ...confirmed,
  message: 'Поля должны совпадать',
})

extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('passwordRequirements', {
  message: 'Пароль не соответствует требованиям',
  validate: (value: string) => {
    const regExpPassword = /^(?=.*[0-9])(?=.*[!@#$%^<>&*;:\\\-,.?/'|_=+)("[\]}{№])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^<>&*;:\\\-,.?/'|_=+)("[\]}{№]{10,}$/
    return !!value.toString().match(regExpPassword)
  },
})

@Component({
  components: {
    ExpansionMessageCard,
    ValidationProvider,
    ValidationObserver,
    SmallModalFooter,
  },
})
export default class ChangePasswordForm extends Mixins(NotifyMixin) {
  private showPassword = false

  private form: ChangePasswordRequestBody = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }

  private handleClickSave () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ProfileModule.changePassword(this.form)
            .then(() => {
              this.$closeModal()
              this.notifySuccess('Пароль успешно изменён')
            })
            // NOTE: Вынимается конкретная ошибка из-за особенностей валидации на бэке
            .catch((error) => {
              this.notifyError(error.response.data.errors.old_password[0] ?? 'Проверьте ввёденые данные')
            })
        }
      })
  }
}
