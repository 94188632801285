// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// SCHEMA TYPES
import {
  RequestTypeEnum,
  CreateRequestMaintenanceRequest,
  CreateTireServiceRequest,
  CreateCarDrivingRequest,
  CreateCarBuybackRequest,
  CreateReviewRequest,
  CreateRatingRequest,
  CarRequestDataResource,
  RequestDictionaryDriver,
  NameValueResource,
  RequestCrashCategory,
  CreateCarCrashRequest,
  // CreateRatingRequest,
} from '@/store/types/schema'

// SCHEMA METHODS
import {
  RequestsDriverRequestIdTypeTypeGetRequest,
  RequestsDriverCarIdMaintenancePostRequest,
  RequestsDriverCarIdTireServicePostRequest,
  RequestsDriverCarIdCarDrivingPostRequest,
  RequestsDriverCarIdCarBuybackPostRequest,
  RequestsDriverCarIdRequestReviewPostRequest,
  RequestsDriverCarIdReviewRequestIdPostRequest,
  RequestsDriverCarIdCarGetRequest,
  DictionariesRequestsDriverCarIdGetRequest,
  RequestsAttorneyPdfDownloadGetRequest,
  DictionariesRequestsCrashCategoriesGetRequest,
  RequestsCarParkIdCarCrashPostRequest,
} from '@/store/types/schema'

/**
 * Просматриваемая заявка (ORDER)
 * Создание заявки на ТО (MAINTENANCE)
 * Создание заявки на Шинный сервис (TAIR SERVICE)
 * Создание заявки на Акт сверки (ACT OF RECONCILIATION)
 * Создание заявки на Выкуп автомобиля (CAR BUYBACK)
 * Создание заявки на Отзы (REVIEW)
 * Создание заявки на Перегон авто (CAR DRIVING)
 * Создание заявки на ДТП (CAR CRASH)
 * Оценить заявку (SEND RATING)
 */

@Module({
  dynamic: true,
  name: 'driver-order',
  store,
  namespaced: true,
})
class DriverOrder extends VuexModule {

  // 0==================================================== ORDER ====================================================+o>

  order: any = {}

  @Mutation
  setOrder (payload: any) {
    Vue.set(this, 'order', payload)
  }

  @Action({ rawError: true })
  async getOrder (payload: { id: number, type: RequestTypeEnum }) {
    const { data } = await RequestsDriverRequestIdTypeTypeGetRequest(payload.id, payload.type)

    this.setOrder(data)
  }

  // 0==================================================== MAINTENANCE ====================================================+o>

  @Action({ rawError: true })
  async sendOrderMaintenance (payload: { entity: number, body: CreateRequestMaintenanceRequest}) {
    const { data } = await RequestsDriverCarIdMaintenancePostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== TIRE SERVICE ====================================================+o>

  @Action({ rawError: true })
  async sendTairService (payload: { entity: number, body: CreateTireServiceRequest }) {
    const { data } = await RequestsDriverCarIdTireServicePostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== CAR BUYBACK ====================================================+o>

  @Action({ rawError: true })
  async sendCarBuyback (payload: { entity: number, body: CreateCarBuybackRequest }) {
    const { data } = await RequestsDriverCarIdCarBuybackPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== REVIEW ====================================================+o>

  @Action({ rawError: true })
  async sendReview (payload: { entity: number, body: CreateReviewRequest }) {
    const { data } = await RequestsDriverCarIdRequestReviewPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== CAR DRIVING ====================================================+o>

  @Action({ rawError: true })
  async sendCarDriving (payload: { entity: number, body: CreateCarDrivingRequest }) {
    const { data } = await RequestsDriverCarIdCarDrivingPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== CAR CRASH ====================================================+o>

  @Action({ rawError: true })
  async sendCarCrash (payload: { entity: number, body: CreateCarCrashRequest }) {
    const { data } = await RequestsCarParkIdCarCrashPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== SEND RATING ====================================================+o>

  @Action({ rawError: true })
  async sendOrderRating (payload: { entity: number, orderID: number, body: CreateRatingRequest }) {
    const { data } = await RequestsDriverCarIdReviewRequestIdPostRequest(payload.entity, payload.orderID, payload.body)
    return data
  }

  // 0==================================================== POWER OF ATTORNEY ===============================================+o>

  @Action({ rawError: true })
  async getAttorneyPdfDownload (body: any) {
    const { data } = await RequestsAttorneyPdfDownloadGetRequest(body)
    return data
  }

  // 0==================================================== CAR ====================================================+o>

  car: CarRequestDataResource = {
    id: 0,
    brand: '',
    currentMileage: '',
    model: '',
    orderNumber: '',
    stateNumber: '',
    vin: '',
    region: '',
    stsNumber: '',
    stsSeria: '',
    stsStartDate: '',
  }

  @Mutation
  setCar (payload: CarRequestDataResource) {
    Vue.set(this, 'car', payload)
  }

  @Action({ rawError: true })
  async getCarForId (payload: number) {
    const { data } = await RequestsDriverCarIdCarGetRequest(payload)

    this.setCar(data)
  }


  // 0============================== DICTIONARIES FOR ORDER ==============================+o>

  vinDictionary: string[] = []
  stateNumberDictionary: string[] = []
  orderNumberDictionary: string[] = []

  operationCitiesDictionary: string[] = []
  serviceCitiesDictionary: string[] = []

  carClassesDictionary: NameValueResource[] = []
  damageClassesDictionary: NameValueResource[] = []

  @Mutation
  setOrderDictionaries (payload: RequestDictionaryDriver) {
    this.vinDictionary = payload.vins
    this.stateNumberDictionary = payload.stateNumbers
    this.orderNumberDictionary = payload.orderNumbers
    this.operationCitiesDictionary = payload.operationCities
    this.serviceCitiesDictionary = payload.serviceCities
    this.carClassesDictionary = payload.carClasses
    this.damageClassesDictionary = payload.damageClasses
  }

  @Action({ rawError: true })
  async getOrderDictionaries (payload: number) {
    const { data } = await DictionariesRequestsDriverCarIdGetRequest(payload)

    this.setOrderDictionaries(data)
  }

  // 0============================== SUBCATEGORIES FOR ORDER ==============================+o>

  @Action({ rawError: true })
  async getSubcategories (payload: RequestCrashCategory ) {
    const { data } = await DictionariesRequestsCrashCategoriesGetRequest(payload.type)

    return data
  }
}

const DriverOrderModule = getModule(DriverOrder)

export default DriverOrderModule
