




// CORE
import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
import TableSettingForm from '@/components/forms/TableSettingForm.vue'

// STORE
import CarparkModule from '@/store/modules/fleet/carpark'

// INTERFACES
import { ITableHeader } from '@/store/types'

@Component({
  components: {
    TableSettingForm,
  },
})
export default class CarparkTableSettingModal extends Vue {
  private get tableHeaders (): ITableHeader[] {
    return CarparkModule.carparkTableHeaders
  }

  private set tableHeaders (value: ITableHeader[]) {
    CarparkModule.setCarparkHeaders(value)
  }

  private save (value: ITableHeader[]) {
    /**
     * #CopyTrouble Сделано так потому что в js есть проблемы с КОПИРОВАНИЕМ массива объектов
     * если делать иначе будем получать ссылку и изменения сразу будут переноситься в стор
     * Read more: https://stackoverflow.com/questions/597588/how-do-you-clone-an-array-of-objects-in-javascript
     */
    this.tableHeaders = value.map((item: ITableHeader) => ({ ...item }))
  }
}
