






















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class ParagraphWithTitle extends Vue {
  @Ref()
  slotRef!: Vue | HTMLElement

  @Prop({ default: false })
  private isOrderPart!: boolean

  @Prop({ default: null })
  private title!: string

  @Prop({ default: null })
  private text!: string

  @Prop({ default: false })
  private isHorizontal!: boolean

  @Prop({ default: false })
  private isSelectable!: boolean

  private get classes () {
    return {
      'vertical': !this.isHorizontal,
      'horizontal': this.isHorizontal,
      'order-part': this.isOrderPart,
    }
  }

  protected handleClick (e: Event) {
    if (this.isSelectable) {
      e.stopPropagation()
    } else {
      e.preventDefault()
      this.$emit('click')
    }
  }
}
