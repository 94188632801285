




























































// Core
import { Component, Mixins, Prop } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// Stores
import ReportsModule from '@/store/modules/reports'
import FleetReportsModule from '@/store/modules/fleet/reports'
import DriverReportsModule from '@/store/modules/driver/driver-reports'

// HELPERS
import { parseDate } from '@/utils/functions'

@Component
export default class ReportDrawer extends Mixins(NotifyMixin, SystemMixin) {
  @Prop({ default: () => ({}) })
  private options!: { isDefault: boolean }

  private get report () {
    return ReportsModule.report
  }

  private get fileSize (): string {
    if (this.report?.file?.size) {
      return Intl.NumberFormat('ru').format(this.report.file.size)
    } else {
      return ''
    }
  }

  private get getColor (): string {
    switch (this.report?.status?.toLowerCase()) {
    case 'сформировано': return 'green'
    case 'не сформирован' : return 'orange'
    case 'ошибка' : return 'red'
    default: return 'red'
    }
  }

  private handleDownload () {
    if (this.report?.file?.url) {
      window.open(this.report.file.url, '_blank')
    } else if (this.report?.id) {
      ReportsModule.fetchReport(this.report.id)
      this.$bus.$emit('startReportGenerating', this.report.id)
    }
  }

  private handleClickDelete () {
    if (!this.report?.id) return

    ReportsModule.deleteReport(this.report.id)
      .then(() => {
        this.fetchReports()
        this.notifySuccess('Отчет успешно удален.')
        this.$closeDrawer()
      })
      .catch(() => {
        this.notifyError()
      })
  }

  private parseDate = parseDate

  private async fetchReports () {
    if (this.role === 'fleet') {
      FleetReportsModule.fetchReports({ carParkId: +this.entity })
    } else {
      DriverReportsModule.fetchReports({ carId: +this.entity })
    }
  }
}
