





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Heading extends Vue {
  @Prop({
    default: 'div',
    validator (value: string): boolean {
      return !!value.match(/(span|p|div|router-link)/)
    },
  })
  readonly tag!: string

  @Prop({
    default: 'h1',
    validator (value: string): boolean {
      return !!value.match(/(h1|h2)/)
    },
  })
  readonly size!: string

  @Prop({
    default: null,
    validator (value: string): boolean {
      return !!value.match(/(uppercase|bold|medium|regular|link)/)
    },
  })
  readonly mod!: string

  @Prop({
    default: null,
    validator (value: string): boolean {
      return !!value.match(/(light|dark|color-grey|color-red)/)
    },
  })
  readonly theme!: string
}
