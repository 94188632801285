







import { Component, Prop, Vue } from 'vue-property-decorator'
import { Chart, DoughnutController, ArcElement, Tooltip, ChartData, ScatterDataPoint, BubbleDataPoint } from 'chart.js'
import { DoughnutChart } from 'vue-chart-3'

Chart.register(DoughnutController, ArcElement, Tooltip)

@Component({
  components: {
    DoughnutChart,
  },
})
export default class CircleChart extends Vue {
  @Prop({ default: () => ['4', '17', '1', '34'] })
  readonly data!: (number | ScatterDataPoint | BubbleDataPoint | null)[]

  @Prop({ default: () => ['Одна часть', 'Две части', 'Три части', 'Четыре части', 'Пять частей', 'Шесть частей', 'Семь частей'] })
  readonly labels!: (number | ScatterDataPoint | BubbleDataPoint | null)[]

  @Prop({ default: 0 })
  readonly sum!: number

  @Prop({
    default: 'red',
    validator (value: string) :boolean {
      return !!value.match(/(red|blue)/)
    },
  })
  readonly mod!: string

  readonly chartOptions: any = {
    cutout: '65%', // вырез в центре
  }

  private get chartData (): ChartData {
    return {
      labels: this.labels,
      datasets: [
        {
          data: this.data,
          backgroundColor: this.mod === 'red'
            ? [
              '#D92B26',
              '#B92520',
              '#981E1B',
              '#771815',
              '#57110F',
            ]
            : [
              '#C9D6ED',
              '#ABB6CA',
              '#8D96A6',
              '#6E7582',
              '#50565F',
              '#32363B',
              '#141618',
            ],
          borderWidth: 0,

          borderRadius: this.borderRadius,

          spacing: this.spacing,

          radius: this.radius,
        },
      ],
    }
  }

  private get borderRadius (): number {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return 6
    } else if (this.$vuetify.breakpoint.xsOnly) {
      return 2
    } else {
      return 4
    }
  }

  private get spacing (): number {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return 4
    } else if (this.$vuetify.breakpoint.xsOnly) {
      return 2
    } else {
      return 3
    }
  }

  private get radius (): number {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return 60
    } else if (this.$vuetify.breakpoint.xsOnly) {
      return 31
    } else {
      return 45
    }
  }
}
