








































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// INTERFACES
import { NameValueResource, RequestTypeEnum, CarRequestDataResource } from '@/store/types'

// STORE
import OrderModule from '@/store/modules/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import DriverOrderModule from '@/store/modules/driver/order'
import NewOrderDrawerContinueButton
  from '@/components/drawers/new-order-drawer/components/NewOrderDrawerContinueButton.vue'

@Component({
  components: { NewOrderDrawerContinueButton },
})
export default class NewOrderDrawer extends Mixins(SystemMixin) {
  // readonly maintenanceOptions: NameValueResource[] = [
  //   {
  //     name: 'Плановое',
  //     value: 'planned',
  //   },
  //   {
  //     name: 'Внеплановое',
  //     value: 'unscheduled',
  //   },
  // ]

  private get tireServiceOptions (): NameValueResource[] {
    return OrderModule.tireServicesDictionaries
  }

  readonly replacementCarOptions: NameValueResource[] = [
    {
      name: 'Включено в заказ на аренду',
      value: 'paid',
    },
    {
      name: 'Дополнительная оплата',
      value: 'notpaid',
    },
  ]

  private currentAppType = ''
  private currentAppSubtype = ''

  private tireServiceValues: NameValueResource | null = null
  private maintenanceValues: NameValueResource | null = null

  private get maintenanceOptions (): NameValueResource[] {
    return OrderModule.maintenanceServicesDictionaries
  }

  private paid = false

  // Переменная для VExpansionPanels
  private activePanel = ''

  private requestTypeEnum = RequestTypeEnum

  private get isShowContinueButton (): boolean {
    return !!this.currentAppType?.length
      && (
        !!this.currentAppSubtype?.length
        || !!this.tireServiceValues?.value
        || !!this.maintenanceValues?.value
      )
  }

  private get car () : CarRequestDataResource {
    return DriverOrderModule.car
  }

  private created () {
    if (this.role === 'driver') {
      DriverOrderModule.getCarForId(+this.entity)
    } else if (this.role === 'fleet') {
      OrderModule.getPaidReplacementCarFlag(+this.entity)
        .then(res => {
          this.paid = res.paid
        })
    }

    OrderModule.getMaintenanceServicesDictionaries()
    OrderModule.getTireServicesDictionaries()
  }

  private goTo (routeName: string) {
    this.$closeDrawer()

    if (routeName === this.requestTypeEnum.TIRE_SERVICE) {
      this.$router.push({
        name: `create-order.${routeName}`,
        params: {
          services: encodeURI(JSON.stringify(this.tireServiceValues)),
        },
      })
    } else {
      this.$router.push({ name: `create-order.${routeName}` })
    }
  }

  private handleClick () {
    if (this.tireServiceValues?.value && this.currentAppType.length) {
      this.goTo(this.currentAppType)
    }

    if (this.maintenanceValues?.value && this.currentAppType === this.requestTypeEnum.MAINTENANCE) {
      this.$router.push({
        name: 'create-order.maintenance',
        params: { maintenanceType: this.currentAppSubtype, services: encodeURI(JSON.stringify(this.maintenanceValues)) },
      })
      // this.$openDrawer('AddServicesDrawer', {
      //   name: 'Техническое обслуживание',
      //   type: this.currentAppSubtype,
      //   btnLabel: 'Продолжить',
      //   heading: 'Новая заявка',
      // })
    }

    if (this.currentAppType?.length && this.currentAppSubtype?.length) {
      OrderModule.setServiceSubtype(this.currentAppSubtype)

      if (this.currentAppType === this.requestTypeEnum.REPLACEMENT_CAR) {
        this.goTo(this.currentAppType + '-' + this.currentAppSubtype)
      } else {
        this.goTo(this.currentAppType)
      }
    }
  }

  private handlePanelOpen (appType: string) {
    this.currentAppType = this.currentAppType !== appType ? appType : ''
    this.currentAppSubtype = ''
    this.tireServiceValues = null
    this.maintenanceValues = null
  }

  private handleSubtypeChosen (appSubtype: string) {
    this.currentAppSubtype = appSubtype
  }
}
