



































































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import CarparkModule from '@/store/modules/fleet/carpark'
import SystemMixin from '@/mixins/SystemMixin'
import { DictionaryCarParkResource, ICarparkFilterForm, NameValueResource } from '@/store/types'

// HELPERS
import { tableOptionsToFilters } from '@/utils/functions'
import { GET_DEFAULT_TABLE_FILTER } from '@/store'

@Component({
  components: {
    DrawerFooter,
  },
})
export default class CarparkFilterForm extends Mixins(SystemMixin) {

  private form: ICarparkFilterForm = GET_DEFAULT_TABLE_FILTER()

  private get activeFilters (): ICarparkFilterForm {
    return tableOptionsToFilters(CarparkModule.filters)
  }

  private get activeFiltersCount (){
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof ICarparkFilterForm] && !item.includes('EndDate')){
        count++
      }
    }
    return count
  }

  private get filtersDictionary (): DictionaryCarParkResource {
    return CarparkModule.filtersDictionary
  }

  private get stateNumbers (): NameValueResource[] {
    return this.filtersDictionary.stateNumber.map(item => ({
      name: item.name.slice(0,6) + ' ' + item.name.slice(6),
      value: item.value,
    }))
  }

  private get returnPeriod (): any {
    const period = []
    if (this.form.returnStartDate && this.form.returnEndDate){
      period.push(this.form.returnStartDate)
      period.push(this.form.returnEndDate)
    }
    return period
  }

  private set returnPeriod (value: any) {
    this.form.returnStartDate = value[0]
    this.form.returnEndDate = value[1]
  }

  private get transferPeriod (): any {
    const period = []
    if (this.form.transferStartDate && this.form.transferEndDate){
      period.push(this.form.transferStartDate)
      period.push(this.form.transferEndDate)
    }
    return period
  }

  private set transferPeriod (value: any) {
    this.form.transferStartDate = value[0]
    this.form.transferEndDate = value[1]
  }

  private get plannedReturnPeriod (): any {
    const period = []
    if (this.form.plannedReturnStartDate && this.form.plannedReturnEndDate){
      period.push(this.form.plannedReturnStartDate)
      period.push(this.form.plannedReturnEndDate)
    }
    return period
  }

  private set plannedReturnPeriod (value: any) {
    this.form.plannedReturnStartDate = value[0]
    this.form.plannedReturnEndDate = value[1]
  }

  private created () {
    CarparkModule.getFiltersDictionary(+this.entity)

    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }

  private clearFilters () {
    this.form = {
      ...GET_DEFAULT_TABLE_FILTER(),
      vin: '',
      orderNumber: '',
      stateNumber: '',
      carModelId: '',
      regionId: '',
      returnStartDate: '',
      returnEndDate: '',
      transferStartDate: '',
      transferEndDate: '',
      plannedReturnStartDate: '',
      plannedReturnEndDate: '',
      leaseTerm: '',
      payment: '',
      currentMileage: '',
      repairCost: '',
      status: undefined,
    }

    this.applyFilters(false)
  }

  private applyFilters (close = true) {
    CarparkModule.setFilters(this.form)
    this.$bus.$emit('changeCarparkFilters', this.form)

    if (close) {
      this.$closeDrawer()
    }
  }
}
