import Vue from 'vue'
import Router from 'vue-router'

import middlewarePipeline from '@/router/_middleware/pipeline'
import auth from '@/router/_middleware/auth'

import paymentRoutes from './payment'
import authRoutes from './auth'
import profileRoutes from './profile'
import carparkRoutes from './carpark'
import billsRoutes from './bills'
import policiesRoutes from './policies'
import contactsRoutes from './contacts'
import ordersRoutes from './orders'
import orderRoutes from './order'
import documentsRoutes from './documents'
import carInfoRoutes from './car-info'
import reportsRoutes from './reports'
import roleValidate from './_middleware/roleValidate'

Vue.use(Router)
const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    ...paymentRoutes,
    ...authRoutes,
    {
      component: () => import('@/views/role/Selection.vue'),
      name: 'role',
      meta: { middleware: [auth] }, // auth обязателен для всех страниц кроме авторизации
      path: '/',

      children: [
        {
          component: () => import('@/views/role/entity/Selection.vue'),
          name: 'role.entity',
          meta: { middleware: [auth, roleValidate] }, // roleValidate обязателен для всех страниц кроме авторизации и выбора роли
          path: '/:role',

          children: [
            {
              component: () => import('@/views/role/entity/EntityValidate.vue'),
              meta: { middleware: [auth, roleValidate] },
              name: 'entityValidate',
              path: '/:role/:entity',
              children: [
                {
                  component: () => import('@/views/role/entity/Penalty.vue'),
                  meta: { middleware: [auth, roleValidate] },
                  name: 'penalty',
                  path: '/:role/:entity/penalty',
                },
                {
                  component: () => import('@/views/role/entity/DashboardWrapper.vue'),
                  meta: { middleware: [auth, roleValidate] },
                  name: 'main',
                  path: '/:role/:entity/main',
                },
                ...carparkRoutes,
                ...profileRoutes,
                ...policiesRoutes,
                ...contactsRoutes,
                ...ordersRoutes,
                ...orderRoutes,
                ...documentsRoutes,
                ...carInfoRoutes,
                ...billsRoutes,
                ...reportsRoutes,
              ],
            },
          ],
        },
      ],
    },
    {
      component: () => import('@/views/ErrorPage.vue'),
      name: 'not_found',
      path: '*',
      meta: { middleware: [auth] },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next, to }

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1),
  })
})
export default router
