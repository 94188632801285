
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class Combobox extends Vue {
  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private small!: boolean

  @Prop({ default: null })
  private error!: string

  @Prop({ default: 'Text Field' })
  private label!: string

  @Prop({ default: null })
  private hint!: string

  @Prop({ default: false })
  private deletableChips!: boolean

  @Prop({ default: () => ([]) })
  private values!: string[]

  @Prop({ default: false })
  private required!: boolean

  @Prop({ default: null })
  private icon!: string

  @Prop({ default: false })
  private readonly!: boolean

  private innerValues: string[] = []

  private mounted () {
    this.innerValues = this.values
  }

  @Watch('values')
  private watchValues (values: string[]) {
    this.innerValues = values
  }

  @Watch('innerValues')
  private watchInnerValues () {
    this.$emit('input', this.innerValues)
  }
}
