









import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
import ChangePasswordForm from '@/components/forms/ChangePasswordForm.vue'
import CharacterForm from '@/components/forms/CharacterForm.vue'
import PersonForm from '@/components/forms/PersonForm.vue'
import ExcelDownloadModal from '@/components/modals/ExcelDownloadModal.vue'
import PenaltyExcelDownloadModal from '@/components/modals/PenaltyExcelDownloadModal.vue'
import OrderSendSuccessModal from '@/components/modals/OrderSendSuccessModal.vue'
import OrderRatingModal from '@/components/modals/OrderRatingModal.vue'
import PDFDownloadModal from '@/components/modals/download/PDFDownloadModal.vue'
import RepairToApproveModal from '@/components/modals/RepairToApproveModal.vue'
import RepairToContestModal from '@/components/modals/RepairToContestModal.vue'

// COMPONENTS TABLE SETTINGS
import CarparkTableSettingModal from '@/components/modals/CarparkTableSettingModal.vue'
import RepairTableSettingModal from '@/components/modals/RepairTableSettingModal.vue'

// COMPONENTS EXCEL
import CarparkDownloadModal from '@/components/modals/download/CarparkDownloadModal.vue'
import RepairDownloadModal from '@/components/modals/download/RepairDownloadModal.vue'
import InsuranceStatisticsDownloadModal from '@/components/modals/download/InsuranceStatisticsDownloadModal.vue'

// STORE
import SystemModule from '@/store/modules/system'

@Component({
  components: {
    CarparkTableSettingModal,
    CarparkDownloadModal,
    ChangePasswordForm,
    CharacterForm,
    ExcelDownloadModal,
    InsuranceStatisticsDownloadModal,
    OrderRatingModal,
    OrderSendSuccessModal,
    PDFDownloadModal,
    PersonForm,
    PenaltyExcelDownloadModal,
    RepairDownloadModal,
    RepairTableSettingModal,
    RepairToApproveModal,
    RepairToContestModal,
  },
})
export default class Modal extends Vue {
  private set isOpen (payload: boolean) {
    SystemModule.closeModal(payload)
  }

  private get isOpen (): boolean {
    return SystemModule.modal.isOpen
  }

  private get contentName () {
    return SystemModule.modal.componentName
  }

  private get options () {
    return SystemModule.modal.options
  }

  private get mod (): string {
    return SystemModule.modal.mod
  }
}
