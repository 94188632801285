// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// LIBRARIES
import { omit } from 'lodash'

// STORE
import store, { GET_DEFAULT_PAGINATOR } from '@/store'

// INTERFACES
import {
  CountResource,
  DictionaryPenaltyResource,
  NameValueResource,
  PenaltyCarParkIdGetParams,
  PaginationMeta,
  PenaltySum,
  IPenaltySumToPay,
  PenaltyPaginator,
  PenaltyZipGetParams,
} from '../../types'

// SCHEMA METHODS
import {
  DictionariesPenaltyFleetCarParkIdGetRequest,
  PenaltyCarParkIdGetRequest,
  PenaltySumCarParkIdGetRequest,
  PenaltyCountCarParkIdGetRequest,
  PenaltyPenaltyIdPutRequest,
  PenaltyZipGetRequest,
} from '../../types/schema'

// HELPERS
import { deleteEmpty } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'penalty',
  store,
  namespaced: true,
})
class FleetPenalty extends VuexModule {
  // 0==================================================== PENALTIES ====================================================+o>

  @Mutation
  setFleetPaginationMeta (payload: PaginationMeta) {
    Vue.set(this, 'paginator', payload)
  }

  fleetPenalties: PenaltyPaginator = GET_DEFAULT_PAGINATOR()
  fleetPenaltiesHasFile = true

  @Mutation
  setFleetPenalties (payload: PenaltyPaginator) {
    Vue.set(this, 'fleetPenalties', payload)
  }

  @Mutation
  setFleetPenaltiesHasFile (payload: boolean) {
    Vue.set(this, 'fleetPenaltiesHasFile', payload)
  }

  @Action({ rawError: true })
  async getFleetPenalties (payload: number) {
    const { data } = await PenaltyCarParkIdGetRequest(payload, deleteEmpty(this.penaltyFilter))

    this.setFleetPenalties(data.penalties)
    this.setFleetPenaltiesHasFile(data.hasFile)
  }

  // Отправить на оспаривание
  @Action({ rawError: true })
  async submitForDispute (payload: number) {
    await PenaltyPenaltyIdPutRequest(payload)
  }

  // 0==================================================== SUMS ====================================================+o>

  fleetPenaltiesSum: NameValueResource[] = []
  fleetPenaltiesSumToPay: IPenaltySumToPay = {}

  @Mutation
  setFleetPenaltiesSum (payload: PenaltySum) {
    Vue.set(this, 'fleetPenaltiesSum', payload.sums)
    Vue.set(this, 'fleetPenaltiesSumToPay', {
      cost: payload.cost,
      discountSum: payload.discountSum,
    })
  }

  @Action({ rawError: true })
  async getFleetPenaltiesSum (payload: number) {
    const { data } = await PenaltySumCarParkIdGetRequest(payload)

    this.setFleetPenaltiesSum(data)
  }

  // 0==================================================== DICTIONARY ====================================================+o>

  statusesDictionary: CountResource[] = []

  filtersDictionary: DictionaryPenaltyResource = {
    vin: [],
    carModelId: [],
    protocolNumber: [],
    articleViolation: [],
    eventPlace: [],
    authority: [],
    stateNumber: [],
  }

  @Mutation
  setStatusesDictionary (payload: CountResource[]) {
    Vue.set(this, 'statusesDictionary', payload)
  }

  @Mutation
  setFiltersDictionary (payload: DictionaryPenaltyResource) {
    Vue.set(this, 'filtersDictionary', payload)
  }

  @Action({ rawError: true })
  async getStatusesDictionary (payload: number) {
    const { data } = await PenaltyCountCarParkIdGetRequest(payload, deleteEmpty({ ...omit(this.penaltyFilter, 'status') }))

    this.setStatusesDictionary(data)
  }

  @Action({ rawError: true })
  async getFiltersDictionary (payload: number) {
    const { data } = await DictionariesPenaltyFleetCarParkIdGetRequest(payload)

    this.setFiltersDictionary(data)
  }

  // 0==================================================== REQUEST-PARAMS ====================================================+o>

  penaltyFilter: PenaltyCarParkIdGetParams = {
    page: 1, // default
    perPage: 5, // default
  }

  @Mutation
  setPerPage (payload: number) {
    Vue.set(this.penaltyFilter, 'perPage', payload)
  }

  @Mutation
  setPage (payload: number) {
    Vue.set(this.penaltyFilter, 'page', payload)
  }

  @Mutation
  setStatus (payload: string | undefined) {
    Vue.set(this.penaltyFilter, 'status', payload)
  }

  @Mutation
  setFilters (payload: PenaltyCarParkIdGetParams) {
    Vue.set(this, 'penaltyFilter', { ...this.penaltyFilter, ...payload })
  }

  @Mutation
  resetFilters () {
    Vue.set(this, 'penaltyFilter', { perPage: this.penaltyFilter.perPage, page: 1 })
  }

  // 0======================================== DOWNLOAD =========================================+o>

  @Action({ rawError: true })
  async getPenaltiesPDFLink (payload: PenaltyZipGetParams) {
    const { data } = await PenaltyZipGetRequest(payload, deleteEmpty(this.penaltyFilter))

    return data
  }
}

const FleetPenaltyModule = getModule(FleetPenalty)

export default FleetPenaltyModule
