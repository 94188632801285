import { omit } from 'lodash'
import { DateTime } from 'luxon'
import qs from 'qs'
import snakeCaseKeys from 'snakecase-keys'

// Мутация параметров компонента VDataTable к внутреннему типу данных
export function tableOptionsToParams (options: any) {
  let sortOrder = undefined

  for (const prop in options) {
    if (typeof options[prop] === 'boolean') {
      options[prop] = options[prop] ? 1 : 0
    }

    if (!options[prop]) {
      delete options[prop]
    }
  }

  if (options.sortBy?.length) {
    if (options.sortDesc === 1) {
      sortOrder = 'desc'
    } else {
      sortOrder = 'asc'
    }
  }

  const result = {
    ...omit(options, ['groupBy', 'groupDesc', 'itemsPerPage', 'multiSort', 'mustSort', 'sortBy', 'sortDesc']),
    perPage: options.itemsPerPage || 5,
    sortOrder,
  }

  if (typeof (options.sortBy) === 'string') {
    result.sortField = options.sortBy?.length ? options.sortBy : undefined
  } else {
    result.sortField = options.sortBy?.length ? options.sortBy[0] : undefined
  }

  return result
}

// Мутация параметров компонента VDataTable к внутреннему типу данных
export function booleanToNumber (options: any) {
  for (const prop in options) {
    if (typeof options[prop] === 'boolean') {
      options[prop] = options[prop] ? 1 : 0
    }

    if (!options[prop]) {
      delete options[prop]
    }
  }

  return options
}

// Убирает поля равные пустой строке
export function deleteEmpty (filters: any) {
  for (const prop in filters) {
    if (!filters[prop]) {
      delete filters[prop]
    }
  }

  return filters
}

// Возвращает только фильтры из параметров запроса (getParams to filters)
export function tableOptionsToFilters (options: any) {
  for (const prop in options) {
    if (!options[prop]) {
      delete options[prop]
    }
  }

  return {
    ...omit(options, [
      'groupBy',
      'groupDesc',
      'itemsPerPage',
      'multiSort',
      'mustSort',
      'sortBy',
      'sortDesc',
      'page',
      'perPage',
      'status',
    ]),
  }
}

// Функция для преобразования фильтров в строку для get запросов
export function getQueryStringFromParams (params: any) {
  return qs.stringify(params, {
    arrayFormat: 'indices',
    encode: true,
  })
}

// Редирект пользователя на новый таб. Используется, например, для скачивания выгрузок
export function openNewTab (link: string | undefined, filter?: any, isTable = true, baseURL?: string ) {

  const endpoint = baseURL || process.env.VUE_APP_BACKEND_API_ENDPOINT
  const filterNormalize = isTable && filter ? tableOptionsToParams(filter) : filter
  const filterQueryString = filter ? `?${getQueryStringFromParams(snakeCaseKeys(filterNormalize))}` : ''

  const tab = window.open(`${endpoint}${link}${filterQueryString}`, '_blank')

  if (tab) {
    tab.focus()
  }
}

// обработка строки номера для ссылки и для отображения
/**
 * NOTE: На случай если с бэка телефон придёт не в том формате
 * или если надо будет на бэке формат другой принимать/отдавать
 * Example:
 * Link(
 *  :href="handlePhoneNumberWrapper('link', phone)"
 * ) {{ handlePhoneNumberWrapper('view', phone) }}
 */
export function handlePhoneNumber (type: string, phone: string) {
  if (phone) {
    if (type === 'link') {
      // выпиливаем лишние символы чтобы не было проблем в ссылке
      const result = phone.replaceAll(/\s/g, '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')
      return 'tel:' + result
    } else if (type === 'view') {
      // приводим к нужному формату телефона если не соответствует
      let result = ''
      const clearedPhone = phone.replace(/\D+/g, '')
      result = '+' + 7 + ' (' + clearedPhone.slice(1,4) + ') ' + clearedPhone.slice(4,7) + '-' + clearedPhone.slice(7,9) + '-' + clearedPhone.slice(9,11)

      return result
    } else {
      return phone
    }
  }
}

// обработка телефона с добавочным номером
export function handleWorkNumber (phoneWithCode: string) {
  if (phoneWithCode) {
    const index = phoneWithCode.indexOf('p') < 0 ? phoneWithCode.indexOf('#') : phoneWithCode.indexOf('p')
    const result = {} as { main: string, additional: string }

    if (index >= 0) {
      result.main = phoneWithCode.substring(0, index)
      result.additional = phoneWithCode.substring(index + 1).split(/[^\d]/)[0]
    } else {
      result.main = phoneWithCode
      result.additional = ''
    }

    return result
  }
}

export function parseDate (date: string, delimiter = '.') {
  const d = delimiter

  if (date) {
    return DateTime.fromFormat(date.slice(0,10), 'yyyy-MM-dd').toFormat(`dd${d}MM${d}yy`)
  } else {
    return ''
  }
}

export function formatCost (cost: number | string, isFraction = true) {
  if (!isNaN(+cost)) {
    const whole = Math.floor(+cost)
    const fraction = Math.round(+cost % Math.floor(+cost) * 100)

    if (isFraction) {
      return Intl.NumberFormat('ru').format(whole) + '.' + ('0' + fraction).slice(-2)
    }
    return Intl.NumberFormat('ru').format(whole)
  }

  return ''
}
