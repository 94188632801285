import { PluginObject } from 'vue'

import store from '@/store'

import SystemModule from '@/store/modules/system'
import { ModalModType } from '@/store/types'

const CommonMethods: PluginObject<unknown> = {
  // eslint-disable-next-line
  install (Vue: any, options?: any) {
    Vue.prototype.$openDrawer = (componentName: string, options: any) => {
      SystemModule.openDrawer({ componentName, options })
    }

    Vue.prototype.$closeDrawer = () => {
      store.commit('closeDrawer', false)
      store.commit('clearDrawerOptions')
    }

    Vue.prototype.$clearDrawerOptions = () => {
      SystemModule.clearDrawerOptions()
    }

    Vue.prototype.$openModal = (componentName: string, mod: ModalModType, options?: any) => {
      SystemModule.openModal({ componentName, mod, options })
    }

    Vue.prototype.$closeModal = () => {
      store.commit('closeModal', false)
    }

    Vue.prototype.$clearModalOptions = () => {
      SystemModule.clearModalOptions()
    }
  },
}

export default CommonMethods
