// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// LIBRARIES
import { omit } from 'lodash'

// STORE
import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'

// INTERFACE
import {
  CarParksCarParkIdGetRequest,
  CarParksCarParkIdGetParams,
  CarParkPaginator,
  CarParksCountCarParkIdGetRequest,
  DictionariesCarParkCarParkIdGetRequest,
  DictionaryCarParkResource,
} from '../../types/schema'
import { ITableHeader, CountResource } from '../../types'

// HELPERS
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Хардкодные заголовки для таблицы
 * Настройки таблицы
 * Сохранение их в локал сторейдж
 * Запрос/фильтрация данных для таблицы
 */

@Module({
  dynamic: true,
  name: 'carpark',
  store,
  namespaced: true,
})
class Carpark extends VuexModule {
  // 0==================================================== TABLE ====================================================+o>
  carparkTableHeaders: ITableHeader[] = [
    {
      visible: true,
      text: 'VIN',
      value: 'vin',
      align: 'start',
    },
    {
      visible: true,
      text: 'Гос. №',
      value: 'stateNumber',
    },
    {
      visible: true,
      text: '№ заказа',
      value: 'orderNumber',
      cellClass: 'small-cell',
      class: 'small-cell',
    },
    {
      visible: true,
      text: 'Модель',
      value: 'car',
    },
    {
      visible: true,
      text: 'Город эксплуатации',
      value: 'region',
    },
    {
      visible: true,
      text: 'Дата передачи',
      value: 'dateOfActOfTransfer',
    },
    {
      visible: true,
      text: 'Плановая дата возврата',
      value: 'plannedReturnDate',
    },
    {
      visible: true,
      text: 'Срок договора аренды',
      value: 'leaseTerm',
    },
    {
      visible: true,
      text: 'Текущий пробег',
      value: 'currentMileage',
    },
    {
      visible: true,
      text: 'Пробег',
      value: 'contractMileage',
    },
    {
      visible: true,
      text: 'Платеж',
      value: 'monthlyRentalPayment',
    },
    {
      visible: true,
      text: 'Программа тех. обслуживания',
      value: 'maintenanceProgram',
    },
    {
      visible: true,
      text: 'Дата регистрации текущего пробега',
      value: 'regDateCurrentMileage',
    },
    {
      visible: true,
      text: 'Подменное ТС на время ремонта',
      value: 'replacementCar',
    },
    {
      visible: true,
      text: 'Перегоны на ТО и ш/м',
      value: 'haulTireFitting',
    },
    {
      align: 'center',
      visible: true,
      text: 'КАСКО',
      value: 'casco',
      sortable: false,
      cellClass: 'extra-small-cell',
      class: 'extra-small-cell',
    },
    {
      align: 'center',
      visible: true,
      text: 'ОСАГО',
      value: 'osago',
      sortable: false,
      cellClass: 'extra-small-cell',
      class: 'extra-small-cell',
    },
  ]

  carparkData: CarParkPaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setCarparkHeaders (headers: ITableHeader[], isStorage = true) {
    Vue.set(this, 'carparkTableHeaders', headers)

    if (isStorage) { // чтобы не записывать при чтении из локал сторейдж
      // Сохранили в локал сторейдж
      localStorage.setItem('carparkTableHeaders', JSON.stringify(this.carparkTableHeaders))
    }
  }

  @Mutation
  setCarparkData (payload: CarParkPaginator) {
    Vue.set(this, 'carparkData', payload)
  }

  @Action({ rawError: true })
  getCarparkHeaders () {
    // проверяем наличие сохранённых параметров
    if (localStorage.getItem('carparkTableHeaders')) {
      // записываем из локал сторейдж в стор
      this.setCarparkHeaders(JSON.parse(localStorage.getItem('carparkTableHeaders') as string), false)
    }
  }

  @Action({ rawError: true })
  async getCarparkData (payload: number) {
    const { data } = await CarParksCarParkIdGetRequest(payload, tableOptionsToParams(this.filters))
    this.setCarparkData(data)
  }

  // 0==================================================== DICTIONARIES ====================================================+o>
  // STATUSES (наполнение для чипсов-фильтров по статусу)
  statusDictionary: CountResource[] = []

  @Mutation
  setStatusDictionary (payload: CountResource[]) {
    Vue.set(this, 'statusDictionary', payload)
  }

  @Action({ rawError: true })
  async getStatusDictionary (payload: number) {
    const { data } = await CarParksCountCarParkIdGetRequest(payload, { ...omit(this.filters, 'status') })

    this.setStatusDictionary(data)
  }

  // FILTERS (наполнения для селектов в дровере с фильтрами для таблицы)

  filtersDictionary: DictionaryCarParkResource = {
    vin: [],
    stateNumber: [],
    orderNumber: [],
    carModelId: [],
    regionId: [],
    leaseTerm: [],
    currentMileage: [],
    monthlyRentalPayment: [],
    repairCost: [],
  }

  @Mutation
  setFiltersDictionary (payload: DictionaryCarParkResource) {
    Vue.set(this, 'filtersDictionary', payload)
  }

  @Action({ rawError: true })
  async getFiltersDictionary (payload: number) {
    const { data } = await DictionariesCarParkCarParkIdGetRequest(payload)

    this.setFiltersDictionary(data)
  }

  // 0==================================================== REQUEST-PARAMS ====================================================+o>

  filters: CarParksCarParkIdGetParams = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setActiveStatus (payload: string | undefined) {
    Vue.set(this.filters, 'status', payload)
    Vue.set(this.filters, 'page', 1)
  }

  @Mutation
  setFilters (payload: CarParksCarParkIdGetParams) {
    Vue.set(this, 'filters', { ...GET_DEFAULT_TABLE_FILTER(), ...payload })
  }

  @Mutation
  resetFilters () {
    Vue.set(this, 'filters', GET_DEFAULT_TABLE_FILTER())
  }
}

const CarparkModule = getModule(Carpark)

export default CarparkModule
