var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"form person-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form__field-row"},[_c('Heading',{attrs:{"size":"h2","mod":"medium"}},[_vm._v("Редактирование")]),_c('div',[(_vm.options.formPartId !== 'Add')?_c('div',{staticClass:"inline-additional sp-r-min"},[_vm._v(_vm._s(_vm.options.formPartId))]):_vm._e(),_c('Paragraph',{attrs:{"tag":"span","theme":"color-dark-blue"}},[_vm._v(_vm._s(_vm.options.formPartName))])],1)],1),_c('div',{staticClass:"form__field-row person-form__display-contents"},[_c('ValidationProvider',{staticClass:"form__field form__field--left",attrs:{"tag":"div","rules":_vm.options.required ? 'required' : '',"name":"surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{attrs:{"label":"Фамилия*","error":errors[0],"required":true},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Фамилия"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__field person-form__order-6",attrs:{"tag":"div","rules":_vm.options.required ? 'required|engOnlyEmail' : '',"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"E-mail*","error":errors[0],"required":true,"icon":"mdi-email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("E-mail"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"form__field-row person-form__display-contents"},[_c('ValidationProvider',{staticClass:"form__field form__field--left",attrs:{"tag":"div","rules":_vm.options.required ? 'required' : '',"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Имя*","error":errors[0],"required":true},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Имя"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__field person-form__order-7",attrs:{"tag":"div","rules":_vm.options.required ? { regex: _vm.regExpPhone, required: { allowFalse: true } } : '',"name":"mobilePhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Телефон мобильный*","error":errors[0],"mask":"+7 (###) ###-##-##","required":true,"icon":"mdi-phone"},model:{value:(_vm.form.personalPhone),callback:function ($$v) {_vm.$set(_vm.form, "personalPhone", $$v)},expression:"form.personalPhone"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Телефон мобильный"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"form__field-row form__field-row--half person-form__display-contents"},[_c('ValidationProvider',{staticClass:"form__field",attrs:{"tag":"div","rules":"","name":"patronymic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":"Отчество","error":errors[0],"required":true},model:{value:(_vm.form.patronymic),callback:function ($$v) {_vm.$set(_vm.form, "patronymic", $$v)},expression:"form.patronymic"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Отчество")])],1)]}}],null,true)})],1),_c('SmallModalFooter',{staticClass:"person-form__order-9",on:{"click:action":_vm.handleSaveForm},scopedSlots:_vm._u([{key:"action",fn:function(){return [_vm._v("Сохранить")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }