













import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

// COMPONENT
import Drawer from '@/components/drawers/Drawer.vue'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/modals/Modal.vue'
import Snackbar from '@/components/Snackbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import TechWorkPage from './views/TechWorkPage.vue'

// STORE
import SystemModule from '@/store/modules/system'
import api from './utils/services/api'
import { endpoint } from './utils/services/config'

@Component({
  components: {
    Drawer,
    Loader,
    Modal,
    Snackbar,
    Sidebar,
    TechWorkPage,
  },
})
export default class App extends Vue {
  private get isNoSidebarPage (): boolean {
    const name = this.$route.name
    if (name) {
      const isAuth = name.includes('auth')
      const isRole = name.includes('role')
      const isNotFound = name.includes('not_found')
      const isPayment = name.includes('payment')

      return (isAuth || isRole || isNotFound || isPayment) ?? false
    }

    return true
  }

  private get loading () {
    return SystemModule.loading
  }

  mounted () {
    if ((process.env.NODE_ENV === 'development')) {
      api.get(`${endpoint}/sanctum/csrf-cookie`)
    } else {
      api.get(`${window.location.origin}/sanctum/csrf-cookie`)
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'MC Intermark Auto',
      titleTemplate: '%s',
    }
  }
}
//height: calc(100vh - 68px);
