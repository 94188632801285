
























// CORE
import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
// import CaptionCard from '@/components/cards/CaptionCard.vue'
// import DrawerHeader from '@/components/drawers/DrawerHeader.vue'

@Component({
  components: {
    // CaptionCard,
    // DrawerHeader,
  },
})
export default class NotFound extends Vue {
  private handleClick () {
    const role = this.$route.path.split('/')[1]
    const entity = this.$route.path.split('/')[2]

    this.$router.replace({ name: 'main', params: { role, entity } })
  }
}
