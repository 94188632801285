// HOOKS
import '@/utils/services/hooks'

// STYLES
import '@/styles/base.scss'

// CORE
import Vue from 'vue'

// MODULES
import router from '@/router'
import store from '@/store'

// PLUGINS
import vuetify from '@/utils/plugins/vuetify'
import '@/utils/plugins/meta'
import '@/utils/plugins/global'
import VuePortal from '@linusborg/vue-simple-portal'

// APP
import App from './App.vue'

Vue.config.productionTip = false

/**
 * Шина событий сквозь все приложение
 * Использование: this.$bus.$on('SOME_MESSAGE', this.someMethod)
 */
Object.defineProperty(Vue.prototype,'$bus', {
  get: function () {
    return this.$root.bus
  },
})

Vue.use(VuePortal, {
  name: 'VPortal',
})

new Vue({
  data: {
    bus: new Vue({}),
  },
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount('#app')
