import { render, staticRenderFns } from "./CardsDiamond.vue?vue&type=template&id=d2ff1d8c&scoped=true&lang=pug&"
import script from "./CardsDiamond.vue?vue&type=script&lang=ts&"
export * from "./CardsDiamond.vue?vue&type=script&lang=ts&"
import style0 from "./CardsDiamond.vue?vue&type=style&index=0&id=d2ff1d8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2ff1d8c",
  null
  
)

export default component.exports