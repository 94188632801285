



























// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'
import SmallModalFooter from '@/components/modals/components/SmallModalFooter.vue'

@Component({
  components: {
    ModalHeader,
    SmallModalFooter,
  },
})
export default class ExcelDateRangeForm extends Mixins(NotifyMixin) {
  private date: [string?, string?] = []

  private emitValue () {
    if (this.date[0]?.length && this.date[1]?.length) {
      this.$emit('click:download', {
        startDate: this.date[0],
        endDate: this.date[1],
      })
    } else {
      this.$emit('click:download')
    }
  }
}
