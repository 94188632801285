
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { DateTime } from 'luxon'

@Component
export default class DatePicker extends Vue {
  // COMMON
  @Prop({ default: () => ([]) })
  private value!: string[] | string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private small!: boolean

  @Prop({ default: null })
  private error!: string

  @Prop({ default: 'Text Field' })
  private label!: string

  @Prop({ default: null })
  private hint!: string

  // UNCOMMON
  @Prop({ default: false })
  private range!: boolean

  @Prop({ default: false })
  private isNotPrevDate!: boolean

  @Prop({ default: false })
  private isNextMaxDate!: boolean

  private innerDateValue: string[] | string = this.range ? [] : ''
  private menuIsOpen = false

  private get now (): string {
    return this.isNotPrevDate ? DateTime.now().toFormat('yyyy-MM-dd') : ''
  }

  private get maxDate (): string {

    return this.isNextMaxDate ? this.getTomorrow() : ''
  }

  private get formattedDateTime () {
    if (this.range && this.innerDateValue.length) {
      this.innerDateValue = (this.innerDateValue as string[]).sort()
      let dateFirst = DateTime.fromSQL(`${this.innerDateValue[0]}`).toFormat('dd.MM.yyyy')
      let dateSecond = DateTime.fromSQL(`${this.innerDateValue[1]}`).toFormat('dd.MM.yyyy')
      if (dateFirst === dateSecond) {
        return dateFirst
      } else {
        return dateFirst + ' – ' + dateSecond
      }
    } else if (this.innerDateValue.length) {
      return DateTime.fromSQL(`${this.innerDateValue}`).toFormat('dd.MM.yyyy')
    } else {
      return ''
    }
  }

  private created () {
    if (this.value.length) {
      this.sync(this.value)
    }
  }

  private getTomorrow (date = new Date()): string {
    date.setDate(date.getDate() + 1)

    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
  }

  private sync (value: string[] | string) {
    try {
      if (value.length) {
        if (this.range) {
          (this.innerDateValue as string[])[0] = DateTime.fromSQL(value[0]).toFormat('yyyy-MM-dd');
          (this.innerDateValue as string[])[1] = DateTime.fromSQL(value[1]).toFormat('yyyy-MM-dd')
          this.$emit('input', this.innerDateValue)
        } else {
          this.innerDateValue = DateTime.fromSQL(value as string).toFormat('yyyy-MM-dd')
          this.$emit('input', this.innerDateValue)
        }
      } else {
        this.innerDateValue = this.range ? [] : ''
        this.$emit('input', this.innerDateValue)
      }
    } catch (error) {
      console.error(
        this.range
          ? 'Props type "value" must be string[].'
          : 'Props type "value" must be a string.',
        value)
    }
  }

  private handleCancel () {
    this.menuIsOpen = false
    // this.sync('')
  }

  private headerDateFormatter (value: string) {
    return DateTime.fromISO(value).toFormat('LLLL yyyy', { locale: 'ru-RU' })
  }

  private monthFormatter (value: string) {
    return DateTime.fromISO(value).toFormat('LLLL', { locale: 'ru-RU' })
  }

  private weekdayFormatter (value: string) {
    return DateTime.fromISO(value).toFormat('ccc', { locale: 'ru-RU' })
  }

  @Watch('value')
  private watchValue () {
    this.sync(this.value)
  }
}
