import auth from '@/router/_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  {
    component: () => import('@/views/role/entity/carpark/CarparkLayout.vue'),
    name: 'carpark',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/carpark',
    redirect: '/:role/:entity/carpark/car',
    children: [
      {
        name: 'carpark.car',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/carpark/car',
        component: () => import('@/views/role/entity/carpark/tabs/CarparkTab.vue'),
      },
      {
        name: 'carpark.repair',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/carpark/repair',
        component: () => import('@/views/role/entity/carpark/tabs/RepairTab.vue'),
      },
    ],
  },
]
