

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DrawerFooter extends Vue {
  @Prop({
    default: 'filters',
    validator (value: string): boolean {
      return !!value.match(/(filters|other)/)
    },
  })
  readonly mod!: string

  @Prop({ required: true })
  private activeFiltersCount!: number

  @Prop({ default: 'Очистить фильтры' })
  private leftButtonText!: string

  @Prop({ default: 'Применить' })
  private rightButtonText!: string

  private get clearText (): string {
    if (this.leftButtonText) {
      return this.leftButtonText
    } else if (this.mod === 'filters') {
      return 'Очистить фильтры'
    } else {
      return 'Left button'
    }
  }

  private get confirmText (): string {
    if (this.leftButtonText) {
      return this.rightButtonText
    } else if (this.mod === 'filters') {
      return 'Применить'
    } else {
      return 'Right button'
    }
  }
}
