



















// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// INTERFACES
import { UserAuthResource } from '@/store/types'

// STORE
import ProfileModule from '@/store/modules/profile'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

@Component
export default class AvatarCard extends Mixins(SystemMixin) {
  @Prop({ default: null })
  readonly url!: string

  @Prop({
    default: 'div',
    validator (value: string): boolean {
      return !!value.match(/(span|a|router-link|div)/)
    },
  })
  readonly tag!: string

  @Prop({
    default: null,
    validator (value: string): boolean {
      return !!value.match(/(icon-only)/)
    },
  })
  readonly mod!: string

  private get user (): UserAuthResource {
    return ProfileModule.userResource.user
  }
}
