




































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'

// STORE
import InsuranceModule from '@/store/modules/insurance-statistics'

// INTERFACES
import { CarParksCarParkIdGetParams, ITableHeader } from '@/store/types'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

interface INameValueBooleanResource {
  name: string,
  value: boolean,
}

@Component({
  components: {
    ModalHeader,
  },
})
export default class CarparkDownloadModal extends Mixins(SystemMixin, NotifyMixin) {
  private get tableHeaders (): ITableHeader[] {
    return InsuranceModule.tableHeaders
  }

  private get filters (): CarParksCarParkIdGetParams {
    return InsuranceModule.filters
  }


  private get indeterminate (): boolean {
    const vixibleCounter = this.innerValue.filter(item => item.value).length
    return this.innerValue.length !== vixibleCounter && vixibleCounter !== 0
  }

  private get all (): boolean {
    const vixibleCounter = this.innerValue.filter(item => item.value).length

    return this.innerValue.length === vixibleCounter
  }

  private set all (value: boolean) {
    this.innerValue.forEach(item => {
      item.value = value
    })
  }

  private innerValue: INameValueBooleanResource[] = []

  private created () {
    this.innerValue = this.tableHeaders.map((item: ITableHeader) => ({
      label: item.text,
      name: item.value,
      value: !!item.visible,
    }))
  }

  private download () {
    const headers: any = {}

    for (let i = 0; i < this.innerValue.length; i++) {
      headers[this.innerValue[i].name] = this.innerValue[i].value ? 1 : 0
    }
    this.notifyInfo('Метод выгрузки еще в разработке')
    this.$closeModal()
  }
}
