





















import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ExpansionMessageCard extends Vue {
  @Prop({ default: 'mdi-lock' })
  private icon!: string

  @Prop({ default: 'Требования к паролю' })
  readonly heading!: string

  private isOpen = false
}
