
















import { INameValueItem } from '@/store/types'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
@Component({
  inheritAttrs: false,
})
export default class RadioGroup extends Vue {
  @Prop({ default: () => ([]) })
  readonly items!: INameValueItem[]

  @Prop({ default: null })
  readonly message!: string

  @PropSync('value', { default: null })
  private innerValue!: string
}
