




































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'

// STORE
import RepairModule from '@/store/modules/fleet/repair'

// INTERFACES
import { ITableHeader } from '@/store/types'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import { openNewTab } from '@/utils/functions'


interface INameValueBooleanResource {
  name: string,
  value: boolean,
}

@Component({
  components: {
    ModalHeader,
  },
})
export default class RepairDownloadModal extends Mixins(SystemMixin, NotifyMixin) {
  private get tableHeaders (): ITableHeader[] {
    return RepairModule.tableHeaders
  }

  private get filter () {
    return RepairModule.filters
  }

  private get indeterminate (): boolean {
    const vixibleCounter = this.innerValue.filter(item => item.value).length
    return this.innerValue.length !== vixibleCounter && vixibleCounter !== 0
  }

  private get all (): boolean {
    const vixibleCounter = this.innerValue.filter(item => item.value).length

    return this.innerValue.length === vixibleCounter
  }

  private set all (value: boolean) {
    this.innerValue.forEach(item => {
      item.value = value
    })
  }

  private innerValue: INameValueBooleanResource[] = []

  private created () {
    this.innerValue = this.tableHeaders.map((item: ITableHeader) => ({
      label: item.text,
      name: item.value,
      value: !!item.visible,
    }))
  }

  private download () {
    const headers: any = {}

    for (let i = 0; i < this.innerValue.length; i++) {
      headers[this.innerValue[i].name] = this.innerValue[i].value ? 1 : 0
    }
    // this.notifyInfo('Метод выгрузки еще в разработке')
    openNewTab(
      `/repair/export/${+this.entity}`,
      Object.assign(this.filter, { headers }),
    )
    this.$closeModal()
  }
}
