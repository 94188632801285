import auth from '@/router/_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  {
    component: () => import('@/views/role/entity/Profile.vue'),
    name: 'profile',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/profile',
  },
]
