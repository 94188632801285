// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store, { GET_DEFAULT_PAGINATOR_META } from '@/store'

// INTERFACES
import {
  ApplicationTabName,
} from '@/store/types'

// SCHEMA TYPES
import {
  DictionaryRequestResource,
  RequestPaginator,
  CountResource,
  RequestsDriverCarIdGetParams,
} from '@/store/types/schema'

// SCHEMA METHODS
import {
  RequestsDriverCarIdGetRequest,
  RequestsDriverCarIdCountsGetRequest,
  DictionariesRequestDriverCarIdGetRequest,
} from '@/store/types/schema'

@Module({
  dynamic: true,
  name: 'driver-applications',
  store,
  namespaced: true,
})
class DriverApplications extends VuexModule {
  // 0==================================================== TABLE DATA ====================================================+o>

  applications: RequestPaginator = {
    meta: GET_DEFAULT_PAGINATOR_META(),
    data: [],
    xlsxRoute: '',
  }

  @Mutation
  setTableData (payload: RequestPaginator) {
    Vue.set(this, 'applications', payload)
  }

  @Action({ rawError: true })
  async getTableData (payload: number) {
    const { data } = await RequestsDriverCarIdGetRequest(payload, this.filters)

    this.setTableData(data)
  }

  // 0==================================================== TABLE STATUSES ====================================================+o>

  statusesDictionaries: CountResource[] = []

  @Mutation
  setStatusesDictionaries (payload: CountResource[]) {
    Vue.set(this, 'statusesDictionaries', payload)
  }

  @Action({ rawError: true })
  async getTableStatuses (payload: number) {
    const { data } = await RequestsDriverCarIdCountsGetRequest(payload, this.filters)

    this.setStatusesDictionaries(data)
  }

  // 0==================================================== ACTIVE STATUS ====================================================+o>

  @Mutation
  setActiveStatus (payload: string | undefined) {
    Vue.set(this.filters, 'status', payload)
    Vue.set(this.filters, 'page', 1)
  }

  // 0==================================================== TABLE DICTIONARIES ====================================================+o>

  filtersDictionaries: DictionaryRequestResource = {
    vin: [],
    number: [],
    category: [],
  }

  @Mutation
  setfiltersDictionaries (payload: DictionaryRequestResource) {
    Vue.set(this, 'filtersDictionaries', payload)
  }

  @Action({ rawError: true })
  async getDictionaries (payload: { entity: number, tabName: ApplicationTabName }) {
    const { data } = await DictionariesRequestDriverCarIdGetRequest(payload.entity)

    this.setfiltersDictionaries(data)
  }

  // 0==================================================== TABLE FILTERS ====================================================+o>

  filters: RequestsDriverCarIdGetParams = {
    page: 1,
    perPage: 5,
  }

  @Mutation
  setTableFilters (payload: RequestsDriverCarIdGetParams) {
    Vue.set(this, 'filters', {
      ...this.filters,
      ...payload,
    })
  }

  @Mutation
  resetTableFilters () {
    Vue.set(this, 'filters', {
      perPage: this.filters.perPage,
      page: 1,
    })
  }
}

const DriverApplicationsModule = getModule(DriverApplications)

export default DriverApplicationsModule
