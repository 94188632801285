import auth from '@/router/_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  // CREATE
  {
    name: 'create-order',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/create-order',
    component: () => import('@/views/role/entity/order/OrderCreate.vue'),
    redirect: (to: any) => ({
      name: 'orders.tabs',
      params: { ...to.params, tab: 'all' },
    }),
    children: [
      {
        name: 'create-order.maintenance',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/maintenance-:maintenanceType/:services+',
        component: () => import('@/views/role/entity/order/create/MaintenanceOrderCreate.vue'),
      },
      {
        name: 'create-order.tire_service',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/tire-service/:services+',
        component: () => import('@/views/role/entity/order/create/TireServiceOrderCreate.vue'),
      },
      {
        name: 'create-order.act_of_reconciliation',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/act-of-reconciliation',
        component: () => import('@/views/role/entity/order/create/ActOfReconciliationOrderCreate.vue'),
      },
      {
        name: 'create-order.car_buyback',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/car-buyback',
        component: () => import('@/views/role/entity/order/create/CarBuybackOrderCreate.vue'),
      },
      {
        name: 'create-order.review',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/review',
        component: () => import('@/views/role/entity/order/create/ReviewOrderCreate.vue'),
      },
      {
        name: 'create-order.car_driving',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/car-driving',
        component: () => import('@/views/role/entity/order/create/CarDrivingOrderCreate.vue'),
      },
      {
        name: 'create-order.car_crash',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/car-crash',
        component: () => import('@/views/role/entity/order/create/CarCrashOrderCreate.vue'),
      },
      {
        name: 'create-order.replacement_car-notpaid',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/replacement-car-notpaid',
        component: () => import('@/views/role/entity/order/create/CarReplacementOrderCreate.vue'),
      },
      {
        name: 'create-order.replacement_car-paid',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/replacement-car-paid',
        component: () => import('@/views/role/entity/order/create/CarReplacementPaidOrderCreate.vue'),
      },
      {
        name: 'create-order.power_attorney_car-driving',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/create-order/power-attorney-car-driving',
        component: () => import('@/views/role/entity/order/create/CarPowerAttorneyForDrivingCreate.vue'),
      },

    ],
  },
  // VIEW
  {
    name: 'view-order',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/view-order/',
    component: () => import('@/views/role/entity/order/OrderView.vue'),
    redirect: (to: any) => ({
      name: 'orders.tabs',
      params: { ...to.params, tab: 'all' },
    }),
    children: [
      {
        name: 'view-order.maintenance',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/maintenance/:orderId',
        component: () => import('@/views/role/entity/order/view/MaintenanceOrderView.vue'),
      },
      {
        name: 'view-order.tire_service',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/tire-service/:orderId',
        component: () => import('@/views/role/entity/order/view/TireServiceOrderView.vue'),
      },
      {
        name: 'view-order.act_of_reconciliation',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/act-of-reconciliation/:orderId',
        component: () => import('@/views/role/entity/order/view/ActOfReconciliationOrderView.vue'),
      },
      {
        name: 'view-order.car_buyback',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/car-buyback/:orderId',
        component: () => import('@/views/role/entity/order/view/CarBuybackOrderView.vue'),
      },
      {
        name: 'view-order.review',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/review/:orderId',
        component: () => import('@/views/role/entity/order/view/ReviewOrderView.vue'),
      },
      {
        name: 'view-order.car_driving',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/car-driving/:orderId',
        component: () => import('@/views/role/entity/order/view/CarDrivingOrderView.vue'),
      },
      {
        name: 'view-order.crash',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/car-crash/:orderId',
        component: () => import('@/views/role/entity/order/view/CarCrashOrderView.vue'),
      },
      {
        name: 'view-order.replacement_car',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/replacement-car/:orderId',
        component: () => import('@/views/role/entity/order/view/CarReplacementOrderView.vue'),
      },
      {
        name: 'view-order.replacement_car_paid',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/replacement-car-paid/:orderId',
        component: () => import('@/views/role/entity/order/view/CarReplacementPaidOrderView.vue'),
      },
      {
        name: 'view-order.power_attorney_car_driving',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/view-order/power-attorney-car-driving/:orderId',
        component: () => import('@/views/role/entity/order/view/CarPowerAttorneyForDrivingView.vue'),
      },
    ],
  },
]
