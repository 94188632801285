/* eslint-disable */
import { AxiosResponse, Method } from "axios";

import api from "@/utils/services/api";

type QueryParams = { [key: string]: any }

export interface RequestArgs extends RequestInit {
  loading?: boolean,
  params?: QueryParams | null,
}

export async function request<T>(url: string, rest: RequestArgs): Promise<AxiosResponse<T>> {
  return api({
    data: rest.body,
    headers: rest.headers,
    method: rest.method as Method,
    params: {
      ...rest.params,
      loading: rest.loading,
    },
    url,
  });
}

export interface Pagination {
  perPage?: number,
  page?: number,
}
/**
 * This file is generated automatically. Don't make changes manually
 */

export interface LoggedInUserResource {
  user: UserAuthResource,
  carParks: CarParksResource[],
  carGarages: CarParksResource[],
  cars: CarsResource[],
  email?: string,
  permissions?: PermissionEnum[],
}

export interface UserResource {
  user: UserAuthResource,
  carParks: CarParksResource[],
  carGarages: CarParksResource[],
  cars: CarsResource[],
}

export interface ProfileResource {
  user?: UserAuthResource,
  carPark?: ParkResource,
  carGarage?: ParkResource,
  car?: CarResource,
}

export interface CarParksResource {
  id: number,
  name: string,
  partnerNumber: string,
}

export interface ParkResource {
  id?: number,
  name?: string,
}

export interface CarsResource {
  id: number,
  vin: string,
  carModel: string,
  stateNumber: string,
}

export interface CarResource {
  id?: number,
  car?: CarModelResource,
}

export interface UserAuthResource {
  id?: number,
  name?: string,
  surname?: string,
  patronymic?: string,
  email?: string,
  personalPhone?: string,
  workPhone?: string,
  role?: string,
}

// Статусы операций платежной системы <br/> `created` - Новый заказ, к которому не применялось никаких операций<br/> `waiting` - Заказ в обработке<br/> `declined` - Заказ отменен<br/> `paid` - Заказ оплачен<br/> `refund` - За заказ возвращены средства<br/>
export enum PaymentStatusEnum {
  CREATED = 'created',
  WAITING = 'waiting',
  DECLINED = 'declined',
  PAID = 'paid',
  REFUND = 'refund',
}

// Список разрешений пользователей
export enum PermissionEnum {
  USERS_BROWSE = 'users:browse',
  USERS_READ = 'users:read',
  USERS_EDIT = 'users:edit',
  CAR_PARK_EDIT = 'car_park:edit',
  REQUEST_FLEET_BROWSE = 'request_fleet:browse',
  REQUEST_FLEET_ADD = 'request_fleet:add',
  REQUEST_DRIVER_BROWSE = 'request_driver:browse',
  REQUEST_DRIVER_ADD = 'request_driver:add',
  INSURANCE_BROWSE = 'insurance:browse',
  PENALTY_BROWSE = 'penalty:browse',
  PENALTY_STATUS = 'penalty:status',
  PENALTY_DRIVER_BROWSE = 'penalty_driver:browse',
  PENALTY_DRIVER_STATUS = 'penalty_driver:status',
}

export interface CarParkShortResource {
  id: number,
  name: string,
}

export interface CarParkResource {
  partnerNumber: number | string
  id: number,
  vin: string,
  stateNumber: string,
  orderNumber: string|null,
  car: string,
  region: string,
  dateOfActOfTransfer: string,
  dateOfActOfReturn: string,
  leaseTerm: number,
  currentMileage: string,
  contractMileage: string,
  monthlyRentalPayment: number,
  plannedReturnDate: string,
  maintenanceProgram: string,
  regDateCurrentMileage: string,
  replacementCar: boolean,
  haulTireFitting: boolean,
  casco: string|null,
  osago: string|null,
}

export interface CarParkRepairResource {
  inspectionReportId?: number,
  costAll?: string,
  costStandard?: string,
  costNotstandard?: string,
  costReinvoiceservice?: string,
  status?: InspectionReportStatus,
  comment?: string,
  autoStatusChangeAt?: string,
  fileExcel?: FileResource,
  filePdf?: FileResource,
  id: number,
  vin: string,
  stateNumber: string,
  orderNumber: string|null,
  car: string,
  region: string,
  dateOfActOfTransfer: string,
  dateOfActOfReturn: string,
  repairCost: number,

}

export interface CarModelResource {
  brand: string,
  model: string,
}

export interface CarParkPaginator {
  data: CarParkResource[],
  meta: PaginationMeta,
}

export interface CarParkRepairPaginator {
  data: CarParkRepairResource[],
  meta: PaginationMeta,
}

export interface CarRequestDataResource {
  id: number,
  vin: string,
  stateNumber: string,
  orderNumber: string,
  model: string,
  brand: string,
  currentMileage: string,
  region: string,
  isRepairing?: boolean,
  haulForStoAndTireFitting?: boolean,
  stsNumber: string,
  stsSeria: string,
  stsStartDate: string,
}

export interface RequestPaginator {
  data: RequestShortResource[],
  meta: PaginationMeta,
  xlsxRoute: string,
}

export interface RatingRequest {
  id: number,
  rating: number,
  comment: string,
}

export interface RequestShortResource {
  id: number,
  type: string,
  category: string,
  user: UserRequestShortResource,
  userType: UserTypeEnum,
  status: string|null,
  carModel: string,
  carBrand: string,
  vin: string,
  date: string,
  number: string,
  closeDate: string,
  rating: number,
}

export interface VehicleDataResource {
  brand: string,
  car: string,
  carCharacteristic: CarCharacteristicResource,
  termsOfAnAgreement: TermsOfAnAgreementResource,
  carDrivers: CarDriversResource[],
  insurancePolicy: InsuranceResource[],
  request?: RequestResource[],
  penalty?: PenaltyShortResource[],
}

export interface CarCharacteristicResource {
  id: number,
  vin: string,
  stateNumber: string,
  productionYear: string,
  overrunCompensation: string,
  region: string,
  carStatus: string,
  maintenanceProgram: string,
  insuranceLitigationProgram: string,
  tireProgram: string,
  haulTireFitting: boolean,
  replacementCar: boolean,
  currentMileage: number,
  regDateCurrentMileage: string,
  overMileageCompensation: number,
  stsEndDate: string,
}

export interface TermsOfAnAgreementResource {
  id: number,
  orderNumber: string,
  frameworkAgreementWithBuyer: string,
  leaseTerm: number,
  mileageContract: number,
  tenant: string,
  monthlyRentalPayment: number,
  plannedReturnDate: string,
  dateOfActOfTransfer: string,
  dateOfActOfReturn: string,
  coordinator: CoordinatorSelectResource,
  manager: string|null,
}

export interface CarDriversResource {
  id: number,
  name: string,
  phoneNumber: string,
  email: string,
}

export interface InsurancePolicyResource {
  id: number,
  city: string,
  type: InsurancePolicyEnum,
  insuranceCompany: string,
  policyNumber: string,
  startDate: string,
  finishDate: string,
  fileLink: string,
}

export interface RequestResource {
  id: number,
  dateEntered: string,
  number: string,
  user: UserRequestShortResource,
  category: string,
  status: string,
}

export interface PenaltyShortResource {
  id: number,
  protocolDate: string,
  numberProtocol?: string,
  violationPhoto: string,
  violationDate: string,
  violationArticle: string,
  violationArticleDescription: string,
  violationPlace: string,
  dateOfGettingOriginal: string,
  cost: number,
  discountCost: number,
  discountEndDate: string,
  authority: string,
  fileLink: string,
  status: string,
}

export enum InsurancePolicyEnum {
  OSAGO = 'osago',
  CASCO = 'casco',
}

export interface RequestActOfReconciliationResource {
  id: number,
  type: RequestTypeEnum,
  year: string,
  name: string,
  quarter: QuarterResource[],
  user: UserRequestResource,
  userType: UserTypeEnum,
  comment: string,
  rating: number,
  card: RequestCardResource,
}

export enum InspectionReportStatus {
  _____ = 'Новый',
  __________ = 'Согласован',
  ____________ = 'Оспаривается',
}


export interface QuarterResource {
  quarter: 1|2|3|4,
}

export interface RequestCarDrivingResource {
  id: number,
  type: RequestTypeEnum,
  name: string,
  serviceTitle: string,
  carData: CarRequestResource,
  currentMileage: string,
  transferAddress: string,
  receivingAddress: string,
  date: string,
  sender: UserTransferResource,
  receiver: UserTransferResource,
  userType: UserTypeEnum,
  comment: string,
  rating: number,
  card: RequestCardResource,
}

export interface RequestReplacementCarResource {
  id: number,
  type: RequestTypeEnum,
  paid: boolean,
  carClass: string,
  startDate: string,
  endDate: string,
  address: string,
  name: string,
  carData: CarRequestResource,
  region: string,
  date: string,
  user?: UserRequestResource,
  userType: UserTypeEnum,
  rating: number,
  card: RequestCardResource,
  regionReturn: string|null,
}

export interface RequestReviewResource {
  id: number,
  type: RequestTypeEnum,
  name: string,
  serviceTitle: string,
  carData: CarRequestResource,
  date: string,
  user: UserRequestResource,
  userType: UserTypeEnum,
  comment: string,
  rating: number,
  card: RequestCardResource,
}

export interface RequestCarBuybackResource {
  id: number,
  type: RequestTypeEnum,
  name: string,
  carData: CarRequestResource,
  currentMileage: string,
  city: string,
  date: string,
  user: UserRequestResource,
  userType: UserTypeEnum,
  comment: string,
  rating: number,
  card: RequestCardResource,
}

export interface RequestCarCrashResource {
  id: number,
  type: RequestTypeEnum,
  crashType: TypeDamageEnum,
  name: string,
  carData: CarRequestResource,
  currentMileage?: string,
  user: UserRequestResource,
  userType: UserTypeEnum,
  documents: FileResource[],
  comment: string,
  rating: number,
  card: RequestCardResource,
}

export interface UserTransferResource {
  name: string,
  surname: string,
  patronymic: string,
  phone: string,
  email: string,
}

export interface RequestTireServiceResource {
  id: number,
  serviceTitle: string,
  type: RequestTypeEnum,
  name: string,
  services: ServicesResource[],
  carData: CarRequestResource,
  currentMileage?: string,
  city: string,
  date: string,
  user: UserRequestResource,
  userType: UserTypeEnum,
  comment: string,
  rating: number,
  carDriving?: UserRequestResource,
  card: RequestCardResource,
}

export interface RequestMaintenanceResource {
  id: number,
  serviceTitle: string,
  type: RequestTypeEnum,
  name: string,
  services: ServicesResource[],
  otherService: string,
  carData: CarRequestResource,
  currentMileage?: string,
  city: string,
  date: string,
  user: UserRequestResource,
  userType: UserTypeEnum,
  comment: string,
  rating: number,
  carDriving?: UserRequestResource,
  card: RequestCardResource,
}

export interface UserRequestResource {
  name: string|null,
  surname: string|null,
  patronymic: string|null,
  email: string,
  personalPhone: string|null,
}

export interface CarDrivingResource {
  user: UserRequestResource,
  date: string,
  address: string,
}

export interface RequestCardResource {
  userType: UserTypeEnum,
  user: UserRequestShortResource,
  status: string,
  date: string,
  number: string|null,
  closeDate: string,
  rating: string,
}

export interface UserRequestShortResource {
  name: string|null,
  surname: string|null,
  patronymic: string|null,
}

export interface CarRequestResource {
  id: number,
  vin: string,
  stateNumber: string,
  orderNumber: string|null,
  brand: string,
  model: string,
  currentMilieage: string,
}

export interface CountRequestResource {
  placed: number,
  inWork: number,
  done: number,
}

export interface OtherServiceResource {
  id: number,
  name: string,
  description: string,
}

export interface ServicesResource {
  id: number,
  name: string,
}

export interface AuthorRequest {
  name: string,
  surname: string,
  patronymic: string,
  personalPhone: string,
  email: string,
  type: UserTypeEnum,
}

export interface CarDrivingUserResource {
  name: string,
  surname: string,
  patronymic: string,
  phone: string,
  email: string,
}

export interface CarDrivingRequest {
  user: UserRequestResource,
  address: string,
  date: string,
}

export enum UserTypeEnum {
  MANAGER = 'manager',
  DRIVER = 'driver',
  MECHANIC = 'mechanic',
}

export enum InsuranceTypeEnum {
  CASCO = 'casco',
  OSAGO = 'osago',
}

export enum ReplacementCarRequestTypeEnum {
  ADDITIONAL_CHARGES = 'additional_charges',
  INCLUDED_IN_ORDER = 'included_in_order',
}

export enum TypeDamageEnum {
  GLASS_DAMAGE = 'glass_damage',
  BUMPER_DAMAGE = 'bumper_damage',
  HEADLIGHT_DAMAGE = 'headlight_damage',
}

export enum RequestTypeEnum {
  MAINTENANCE = 'maintenance',
  TIRE_SERVICE = 'tire_service',
  ACT_OF_RECONCILIATION = 'act_of_reconciliation',
  CAR_DRIVING = 'car_driving',
  CRASH = 'crash',
  CAR_BUYBACK = 'car_buyback',
  REPLACEMENT_CAR = 'replacement_car',
  REPLACEMENT_CAR_PAID = 'replacement_car_paid',
  REVIEW = 'review',
  POWER_ATTORNEY_CAR_DRIVING = 'power_attorney_car_driving',
}

export enum StatusRequestEnum {
  SEND_MAIL = 'send_mail',
  CANCEL = 'cancel',
  PLACEMENT = 'placement',
  IN_WORK = 'in_work',
  DONE = 'done',
}

export interface InsurancePaginator {
  data: InsuranceResource[],
  meta: PaginationMeta,
}

export interface InsuranceResource {
  id: number,
  car: string,
  vin: string,
  region: string,
  orderNumber: string,
  stateNumber: string,
  osago: InsurancePolicyShortResource,
  casco: InsurancePolicyShortResource,
}

export interface InsurancePolicyShortResource {
  id: number,
  type: 'osago'|'casco',
  startDate: string,
  endDate: string,
  policyNumber: string,
  insuranceCompany: string,
}

export interface CountResource {
  value: string,
  name: string,
  count: number,
}

export interface PenaltySum {
  cost: number,
  discountSum: number,
  sums: CountResource[],
}

export interface PenaltyPaginator {
  data: PenaltyResource[],
  meta: PaginationMeta,
}

export interface PenaltyPaginatorAndFile {
  penalties: PenaltyPaginator,
  hasFile: boolean,
}

export interface PenaltyResource {
  id: number,
  carModel: string,
  vin: string,
  stateNumber: string,
  protocolDate: string,
  protocolNumber: string,
  violationPhoto: string|null,
  violationDate: string,
  violationArticle: string,
  violationArticleDescription: string,
  violationPlace: string,
  dateOfGettingOriginal: string,
  cost: number,
  discountCost: number,
  discountEndDate: string,
  authority: string,
  paymentLink: string|null,
  fileLink: string,
  status: PenaltyStatusEnum,
  paid: boolean,
  paymentInvoiceNumber?: string,
  paymentInvoiceDate?: string,
  challenging: boolean,
}

export interface PDFPenaltyResource {
  link: string|null,
  errors: string|null,
}

export enum PenaltyStatusEnum {
  NEW = 'new',
  CHALLENGING = 'challenging',
  PAID = 'paid',
  NOT_PAID = 'not_paid',
}

export interface PageYearsResource {
  year: string,
  months: NameValueResource[],
}

export interface CarPageResource {
  id: number,
  model: string,
  brand: string,
  vin: string,
  stateNumber: string,
  region: string,
  plannedReturnDate: string,
  dateOfTransfer: string,
  serviceInterval: string,
  fillingLimit: string,
  waybill: string,
  fuelCard: string,
  casco: string,
  cascoLink: string,
  osago: string,
  osagoLink: string,
}

export interface ContactResource {
  name: string,
  email: string,
  phone: string,
  phoneWithCode: string,
  position: string,
}

export interface InvoiceResource {
  id: number,
  startDate: string,
  finishDate: string,
  invoiceNumber: string,
  invoiceCategory: string,
  amount: string,
}

export interface InsuranceStatisticsPaginator {
  data: InsuranceStatisticsResource[],
  meta: PaginationMeta,
}

export interface InsuranceStatisticsResource {
  id: number,
  vin: string,
  stateNumber: string,
  crashDate: string,
  category: string,
  type: string,
  damage: string,
  guilt: string,
}

export interface NameValueResource {
  name: string, // Текст для показа пользователю
  value: number|string, // Значения для отправки на бэк
}

export interface UserSelectResource {
  id: number,
  name: string,
  surname: string,
  patronymic: string,
  email: string,
  phone: string,
}

export interface CoordinatorSelectResource {
  name: string,
  lastName: string,
  value: string,
}

export interface FileResource {
  id: number,
  type: string,
  filename: string,
  url: string,
  size: number,
  format: string,
}

export interface NewsResource {
  id: number,
  text: string,
  icon: string,
}

export interface RequestContactResource {
  email: string,
  phone: string,
}

export interface DashboardFleetResource {
  carPark: NameValueResource[],
  penalties: DashboardPenaltiesResource,
  requests: DashboardRequestResource,
}

export interface DashboardMechanicResource {
  carPark: NameValueResource[],
  penalties: DashboardPenaltiesResource,
  requests: DashboardRequestResource,
}


export interface DashboardDriverResource {
  penalties: DashboardPenaltiesResource,
  requests: DashboardRequestResource,
}

export interface DashboardCarParkResource {
  counts: CountResource[],
}

export interface DashboardPenaltiesResource {
  counts: NameValueResource[],
  cost: number,
  discountCost: number,
}

export interface DashboardRequestResource {
  counts: CountResource[],
  all: NameValueResource[],
  worked?: NameValueResource[],
  placed: NameValueResource[],
  done: NameValueResource[],
}

// Метаданные для ответов с пагинацией
export interface PaginationMeta {
  perPage: number,
  total: number,
  currentPage: number,
  lastPage: number,
  from: number,
  to: number,
}

export interface DictionaryInsuranceResource {
  vin: NameValueResource[],
  carModelId: NameValueResource[],
  stateNumber: NameValueResource[],
  insuranceCompany: NameValueResource[],
  regionId: NameValueResource[],
  orderNumber: NameValueResource[],
  policyNumber: NameValueResource[],
}

export interface DictionaryCarParkResource {
  vin: NameValueResource[],
  stateNumber: NameValueResource[],
  orderNumber: NameValueResource[],
  carModelId: NameValueResource[],
  regionId: NameValueResource[],
  leaseTerm: NameValueResource[],
  currentMileage: NameValueResource[],
  monthlyRentalPayment: NameValueResource[],
  repairCost: NameValueResource[],
}

export interface DictionaryCarParkRepairResource {
  vin: NameValueResource[],
  stateNumber: NameValueResource[],
  orderNumber: NameValueResource[],
  carModelId: NameValueResource[],
  regionId: NameValueResource[],
  repairCost: NameValueResource[],
}

export interface RequestDictionary {
  vins: string[],
  stateNumbers: string[],
  orderNumbers: string[],
  operationCities: string[],
  serviceCities: string[],
  carClasses: NameValueResource[],
  damageClasses: NameValueResource[],
}

export interface RequestDictionaryCategory {
  types?: NameValueResource[],
}

export interface RequestCheckReplacement {
  paid: boolean,
}

export interface RequestDictionaryDriver {
  vins: string[],
  stateNumbers: string[],
  orderNumbers: string[],
  operationCities: string[],
  serviceCities: string[],
  carClasses: NameValueResource[],
  damageClasses: NameValueResource[],
}

export interface DictionaryInsuranceStatisticsResource {
  vin: NameValueResource[],
  stateNumber: NameValueResource[],
  category: NameValueResource[],
  guilt: NameValueResource[],
  numberPolicy: NameValueResource[],
  insuranceCompany: NameValueResource[],
  regionId: NameValueResource[],
  carModelId: NameValueResource[],
}

export interface DictionaryPenaltyResource {
  vin: NameValueResource[],
  carModelId: NameValueResource[],
  protocolNumber: NameValueResource[],
  articleViolation: NameValueResource[],
  eventPlace: NameValueResource[],
  authority: NameValueResource[],
  stateNumber: NameValueResource[],
}

export interface DictionaryRequestResource {
  vin: NameValueResource[],
  number: NameValueResource[],
  category: NameValueResource[],
}

export interface DictionaryMaintenanceResource {
  maintenanceServices: NameValueResource[],
}

export interface DictionaryTireServicesResource {
  tireServices: NameValueResource[],
}

export interface DictionaryInvoiceResource {
  invoiceNumber: NameValueResource[],
  invoiceCategory: NameValueResource[],
}

export interface ReportCustomResource {
  id: number,
  type: string,
  name: string,
  categories: string[],
  status: string,
  date: string,
  url: string,
}

export interface ReportResource {
  id: number,
  name: string,
  status: string,
  type: string,
}

export interface ReportFieldsResource {
  id: number,
  subtitle: string|null,
  title: string,
  subcategories: NameValueResource[],
}

export interface RequestCrashCategoryResource {
  name: string,
  value: string
}

export interface ReportCategoryFieldResource {
  id: number,
  category: string,
  subtitle: string|null,
  subcategories: NameValueResource[],
}

export interface ReportEditResource {
  id: number,
  name: string,
  file: FileResource,
  categories: ReportCategoryFieldResource[],
  status: string,
  date: string,
}

export interface ReportsResource {
  type: 'default'|'custom',
  reports: ReportDataResource[],
}

export interface ReportDataResource {
  id: number,
  format: string,
  type: 'default'|'custom',
  name: string,
  categories: string[],
  status: string,
  date: string,
  url: string,
}

// Список категорий для отчетов
export enum CategoriesEnum {
  CAR = 'car',
  CONTRACT = 'contract',
  CAR_ACCIDENT = 'car_accident',
  PENALTY = 'penalty',
  REPAIR_AND_SERVICE = 'repair_and_service',
  CASCO = 'casco',
  OSAGO = 'osago',
}

export interface DocumentResource {
  file: FileResource,
  type: string,
}

export interface RegisterFleetManagerRequest {
  email: string,
  password: string,
  passwordConfirmation: string,
  remember: boolean,
  partnerIds: string[],
}

export interface RegisterCarDriverRequest {
  email: string,
  password: string,
  passwordConfirmation: string,
  remember: boolean,
  vin: string,
}

export interface RegisterCarMechanicRequest {
  email: string,
  password: string,
  passwordConfirmation: string,
  remember: boolean,
  partnerIds: string[],
}


export interface LoginRequestBody {
  email: string,
  password: string,
  remember: boolean,
}

export interface StartPasswordResetRequestBody {
  email: string,
}

export interface ResetPasswordRequestBody {
  token: string, // Токен из письма
  password: string,
  passwordConfirmation: string,
  remember: boolean,
}

export interface ProfileRequestBody {
  name: string,
  surname: string,
  patronymic: string,
  email: string,
  phone: string,
  phoneCode: string,
}

export interface ChangePasswordRequestBody {
  oldPassword: string,
  newPassword: string,
  newPasswordConfirmation: string,
}

export interface CreateRatingRequest {
  rating: number,
  comment: string,
}

export interface CreateRequestMaintenanceRequest {
  servicesIds: number[],
  serviceTitle: string,
  carId: number,
  author: UserAuthResource,
  carDriving?: CarDrivingRequest,
  city: string,
  date: string,
  otherService: string|null,
  comment: string|null,
  currentMileage?: string,
  letterIds?: number[]
}

export interface CreateTireServiceRequest {
  serviceTitle: string,
  servicesIds: number[],
  carId: number,
  author: UserAuthResource,
  carDriving?: CarDrivingRequest,
  city: string,
  date: string,
  comment: string | null,
  currentMileage?: string,
  letterIds?: number[]
}

export interface AgreementInspectionReportRequestBody {
  status: InspectionReportStatus,
  comment?: string,
}

export interface CreateActOfReconciliationRequest {
  author: AuthorRequest,
  quarters: string[],
  year: string,
  comment: string|null,
}

export interface CreateCarDrivingRequest {
  serviceTitle: string,
  carId: number,
  transferAddress: string,
  date: string,
  comment: string|null,
  receivingAddress: string,
  sender: UserAuthResource,
  receiver: UserAuthResource,
  currentMileage?: string,
}

export interface CreateCarCrashRequest {
  carId: number,
  author: AuthorRequest,
  comment: string|null,
  crashType: string,
  crashSubType: string,
  guilt: string,
  damage: string,
  crashDate: string,
  fileIds: number[],
  photoIds: number[],
  currentMileage?: string,
}

export interface CreateCarBuybackRequest {
  carId: number,
  author: UserAuthResource,
  city: string,
  date: string,
  comment: string|null,
  currentMileage?: string,
}

export interface RequestsAttorneyPdfDownloadGetParams {
  carId: number, // ID ТС
  issued1: string, // Выдан1 (Паспорт)
  issued2: string, // Выдан2 (Паспорт)
  issued3: string, // Подразделение (Паспорт)
  passport: string, // Паспорт гражданина РФ, серия
  fio: string, // ФИО (Паспорт)
  carBrand: string, // Марка ТС
  carModel: string, // Модель ТС
  carYear: string, // Год изготовления ТС
  carVin: string, // VIN
  carNumber: string, // Гос. Регистрационный знак
  carSts: string, // СТС
  order?: string, // Номер доверенности
  date?: string, // Дата доверенности
}


export interface CreateReplacementCarRequest {
  carId?: number,
  city?: string,
  startDate: string,
  endDate: string,
  paid: boolean,
  comment: string|null,
  carDriving?: CarDrivingRequest,
  carClass?: string,
  currentMileage?: string,
  cityReturn?: string|null,
}

export interface CreateReviewRequest {
  comment: string|null,
  carId?: number,
  author: AuthorRequest,
  serviceTitle: string,
}

export interface PenaltyPaymentRequest {
  penaltyIds: number[],
}

export interface UploadFileRequestBody {
  file: Blob,
}

export interface CreateReportRequest {
  name: string,
  fieldIds: number[],
}

export interface AuthResetPasswordVerifyTokenGetParams {
  token: string, // Токен из письма
}

export interface CarParksCarParkIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  vin?: string,
  orderNumber?: string,
  stateNumber?: string,
  carModelId?: string,
  regionId?: string,
  dateTransfer?: string,
  dateReturn?: string,
  leaseTerm?: string,
  payment?: string,
  currentMileage?: string,
  repairCost?: string,
  status?: string,
  plannedReturnStartDate?: string,
  plannedReturnEndDate?: string,
  returnStartDate?: string,
  returnEndDate?: string,
  transferStartDate?: string,
  transferEndDate?: string,
}

export interface CarParksCountCarParkIdGetParams {
  vin?: string,
  orderNumber?: string,
  stateNumber?: string,
  carModelId?: string,
  regionId?: string,
  dateTransfer?: string,
  dateReturn?: string,
  leaseTerm?: string,
  payment?: string,
  currentMileage?: string,
  repairCost?: string,
  status?: string,
  plannedReturnStartDate?: string,
  plannedReturnEndDate?: string,
  returnStartDate?: string,
  returnEndDate?: string,
  transferStartDate?: string,
  transferEndDate?: string,
}

export interface CarParksRepairCarParkIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  vin?: string,
  orderNumber?: string,
  stateNumber?: string,
  carModelId?: string,
  regionId?: string,
  dateTransfer?: string,
  dateReturn?: string,
  repairCost?: number,
  plannedReturnStartDate?: string,
  plannedReturnEndDate?: string,
  returnStartDate?: string,
  returnEndDate?: string,
  transferStartDate?: string,
  transferEndDate?: string,
}

export interface RequestsCarParkIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  status?: StatusRequestEnum,
  type?: UserTypeEnum,
  vin?: string,
  number?: string,
  requestStartDate?: string,
  requestEndDate?: string,
  closeStartDate?: string,
  closeEndDate?: string,
  category?: string,
}

export interface RequestsMechanicGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  status?: StatusRequestEnum,
  type?: UserTypeEnum,
  vin?: string,
  number?: string,
  requestStartDate?: string,
  requestEndDate?: string,
  closeStartDate?: string,
  closeEndDate?: string,
  category?: string,
}


export interface RequestCrashCategory {
  type: string,
}

export interface RequestsCarParkIdCarGetParams {
  vin?: string,
  stateNumber?: string,
  orderNumber?: string,
}

export interface RequestsCarParkIdCountsGetParams {
  status?: StatusRequestEnum,
  type?: UserTypeEnum,
  vin?: string,
  number?: string,
  dateRequest?: string,
  endDate?: string,
  category?: string,
  isUserAuthor?: number,
}

export interface RequestsDriverCarIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  status?: StatusRequestEnum,
  type?: UserTypeEnum,
  vin?: string,
  number?: string,
  dateRequest?: string,
  endDate?: string,
  category?: string,
}

export interface RequestsDriverCarIdCountsGetParams {
  status?: StatusRequestEnum,
  type?: UserTypeEnum,
  vin?: string,
  number?: string,
  dateRequest?: string,
  endDate?: string,
  category?: string,
}

export interface PenaltyCarParkIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  vin?: string,
  status?: 'new'|'challenging'|'paid'|'not_paid',
  carModelId?: string,
  protocolNumber?: string,
  articleViolation?: string,
  eventPlace?: string,
  authority?: string,
  dateProtocolStartDate?: string,
  dateProtocolEndDate?: string,
  eventDateStartDate?: string,
  eventDateEndDate?: string,
  gettingOriginalStartDate?: string,
  gettingOriginalEndDate?: string,
  endSaleStartDate?: string,
  endSaleEndDate?: string,
  stateNumber?: string,
  paymentInvoiceNumber?: string,
}

export interface PenaltyCountCarParkIdGetParams {
  vin?: string,
  status?: 'new'|'challenging'|'paid'|'not_paid',
  carModelId?: string,
  protocolNumber?: string,
  articleViolation?: string,
  eventPlace?: string,
  authority?: string,
  dateProtocol?: string,
  eventDate?: string,
  gettingOriginal?: string,
  endSale?: string,
}

export interface PenaltyDriverCarIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  vin?: string,
  status?: 'Новые'|'Оспариавние'|'Неоплаченно'|'Оплаченно',
  carModelId?: string,
  protocolNumber?: string,
  articleViolation?: string,
  eventPlace?: string,
  authority?: string,
  dateProtocol?: string,
  eventDate?: string,
  gettingOriginal?: string,
  endSale?: string,
}

export interface PenaltyTotalGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  vin?: string,
  status?: 'Новые'|'Оспариавние'|'Неоплаченно'|'Оплаченно',
  carModelId?: string,
  protocolNumber?: string,
  articleViolation?: string,
  eventPlace?: string,
  authority?: string,
  dateProtocolStartDate?: string,
  dateProtocolEndDate?: string,
  eventDateStartDate?: string,
  eventDateEndDate?: string,
  gettingOriginalStartDate?: string,
  gettingOriginalEndDate?: string,
  endSaleStartDate?: string,
  endSaleEndDate?: string,
  stateNumber?: string,
  paymentInvoiceNumber?: string,
}

export interface PenaltyCountTotalGetParams {
  vin?: string,
  status?: 'Новые'|'Оспариавние'|'Неоплаченно'|'Оплаченно',
  carModelId?: string,
  protocolNumber?: string,
  articleViolation?: string,
  eventPlace?: string,
  authority?: string,
  dateProtocol?: string,
  eventDate?: string,
  gettingOriginal?: string,
  endSale?: string,
}


export interface PenaltyDriverCountCarIdGetParams {
  vin?: string,
  status?: 'Новые'|'Оспариавние'|'Неоплаченно'|'Оплаченно',
  carModelId?: string,
  protocolNumber?: string,
  articleViolation?: string,
  eventPlace?: string,
  authority?: string,
  dateProtocol?: string,
  eventDate?: string,
  gettingOriginal?: string,
  endSale?: string,
}

export interface PenaltyZipGetParams {
  ids?: number[],
}

export interface InsuranceZipCarParkIdGetParams {
  insuranceIds: number[], // Список ID полисов
}

export interface InsuranceCarParkIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  type?: InsuranceTypeEnum,
  vin?: string,
  carModelId?: string,
  stateNumber?: string,
  numberPolicy?: string,
  orderNumber?: string,
  startDate?: string,
  endDate?: string,
  insuranceCompany?: string,
  regionId?: string,
  status?: string,
}

export interface InsuranceCountCarParkIdGetParams {
  type?: InsuranceTypeEnum,
  vin?: string,
  carModelId?: string,
  stateNumber?: string,
  numberPolicy?: string,
  orderNumber?: string,
  startDate?: string,
  endDate?: string,
  insuranceCompany?: string,
  regionId?: string,
  status?: string,
}

export interface IntermarkCarIdGetParams {
  type?: InsuranceTypeEnum,
  vin?: string,
  carModelId?: number,
  stateNumber?: string,
  numberPolicy?: string,
  orderNumber?: string,
  startDate?: string,
  endDate?: string,
  insuranceCompany?: string,
  regionId?: number,
  status?: string,
}

export interface InvoiceCarParkIdGetParams {
  invoiceNumber?: string,
  invoiceCategoryId?: string,
  postStartDate?: string,
  postEndDate?: string,
  checkoutStartDate?: string,
  checkoutEndDate?: string,
}

export interface InsuredEventCarParkIdGetParams {
  page?: number, // Номер страницы
  perPage?: number, // Количество элементов на странице
  vin?: string,
  stateNumber?: string,
  category?: string,
  guilt?: string,
  status?: string,
  accidentStartDate?: string,
  accidentEndDate?: string,
}

export interface DictionariesRequestsCarParkIdGetParams {
  hasRepair?: boolean, // Флаг для списка данных либо с восстановительным ремонтом либо без
  paid?: boolean, // Флаг для списка данных либо с машинами на подменный авто
}

export interface DictionariesRequestsCarParkIdInServiceGetParams {
  hasRepair?: boolean, // Флаг для списка данных либо с восстановительным ремонтом либо без
  paid?: boolean, // Флаг для списка данных либо с машинами на подменный авто
}

export interface DictionariesRequestsCheckReplacementCarParkIdGetParams {
  paid?: boolean, // Флаг для списка данных либо с восстановительным ремонтом либо без
}

export interface DashboardFleetCarParkIdGetParams {
  pageYearId?: number,
}

export interface DashboardDriverCarIdGetParams {
  pageYearId?: number,
}

export interface DashboardMechanicCarParkIdGetParams {
  pageYearId?: number,
}

export interface ReportsCarParkCarParkIdGetParams {
  type?: 'custom'|'default',
}

export interface ReportsDriverCarCarIdGetParams {
  type?: 'custom'|'default',
}

// Зарегистрировать как флит мендежер
export const AuthRegisterFleetPostRequest = (body: RegisterFleetManagerRequest, options?: any) => request<UserResource>('/auth/register-fleet', { body, method: 'POST', ...options })

// Зарегистрировать как водитель
export const AuthRegisterDriverPostRequest = (body: RegisterCarDriverRequest, options?: any) => request<UserResource>('/auth/register-driver', { body, method: 'POST', ...options })

// Зарегистрировать как механик
export const AuthRegisterMechanicPostRequest = (body: RegisterCarMechanicRequest, options?: any) => request<UserResource>('/auth/register-mechanic', { body, method: 'POST', ...options })

// Войти в аккаунт
export const AuthLoginPostRequest = (body: LoginRequestBody, options?: any) => request<UserResource>('/auth/login', { body, method: 'POST', ...options })

// Выйти из аккаунта
export const AuthLogoutPostRequest = (options?: any) => request<any>('/auth/logout', { method: 'POST', ...options })

// Получить pdf "Доверенность"
export const RequestsAttorneyPdfDownloadGetRequest = (params: RequestsAttorneyPdfDownloadGetParams, options?: any) => request<any>('/requests/attorney-pdf-download', { method: 'GET', params, ...options })

// Начать смену пароля
export const AuthResetPasswordPostRequest = (body: StartPasswordResetRequestBody, options?: any) => request<any>('/auth/reset-password', { body, method: 'POST', ...options })

// Сменить пароль
export const AuthResetPasswordPutRequest = (body: ResetPasswordRequestBody, options?: any) => request<UserResource>('/auth/reset-password', { body, method: 'PUT', ...options })

// Проверить валидность токена для смены пароля
export const AuthResetPasswordVerifyTokenGetRequest = (params: AuthResetPasswordVerifyTokenGetParams, options?: any) => request<any>('/auth/reset-password/verify-token', { method: 'GET', params, ...options })

// Получить данные авторизованного пользователя
export const MeGetRequest = (options?: any) => request<LoggedInUserResource>('/me', { method: 'GET', ...options })

// Изменить профиль пользователя
export const ProfilePutRequest = (body: ProfileRequestBody, options?: any) => request<UserResource>('/profile', { body, method: 'PUT', ...options })

// Получить профиль пользователя по автопарку или водителю
export const ProfileTypeTypeIdGetRequest = (type: string, typeId: number, options?: any) => request<ProfileResource>(`/profile/${type}/${typeId}`, { method: 'GET', ...options })

// Изменить пароль
export const ChangePasswordPutRequest = (body: ChangePasswordRequestBody, options?: any) => request<any>('/change-password', { body, method: 'PUT', ...options })

// Получить автопарки пользователя
export const CarParksGetRequest = (options?: any) => request<CarParkShortResource[]>('/car-parks/', { method: 'GET', ...options })

// Получить автомобили автопарка
export const CarParksCarParkIdGetRequest = (carParkId: number, params?: CarParksCarParkIdGetParams, options?: any) => request<CarParkPaginator>(`/car-parks/${carParkId}`, { method: 'GET', params, ...options })

// Получить количество статусов автопарков
export const CarParksCountCarParkIdGetRequest = (carParkId: number, params?: CarParksCountCarParkIdGetParams, options?: any) => request<CountResource[]>(`/car-parks/count/${carParkId}`, { method: 'GET', params, ...options })

// Получить автомобили автопарка восстановительный ремонт
export const CarParksRepairCarParkIdGetRequest = (carParkId: number, params?: CarParksRepairCarParkIdGetParams, options?: any) => request<CarParkRepairPaginator>(`/car-parks/repair/${carParkId}`, { method: 'GET', params, ...options })

// Получить характеристики авто
export const CarParksCarCarIdGetRequest = (carId: number, options?: any) => request<VehicleDataResource>(`/car-parks/car/${carId}`, { method: 'GET', ...options })

// Отправить отзыв заявке
export const RequestsCarParkIdReviewRequestIdPostRequest = (carParkId: number, requestId: number, body: CreateRatingRequest, options?: any) => request<RatingRequest>(`/requests/${carParkId}/review/${requestId}`, { body, method: 'POST', ...options })

// Получить заявки автопарка
export const RequestsCarParkIdGetRequest = (carParkId: number, params?: RequestsCarParkIdGetParams, options?: any) => request<RequestPaginator>(`/requests/${carParkId}`, { method: 'GET', params, ...options })

// Получить заявки автопарков механика
export const RequestsMechanicGetRequest = (params?: RequestsMechanicGetParams, options?: any) => request<RequestPaginator>('/requests/mechanic', { method: 'GET', params, ...options })

// Получить автомобиль автопарка
export const RequestsCarParkIdCarGetRequest = (carParkId: number, params?: RequestsCarParkIdCarGetParams, options?: any) => request<CarRequestDataResource>(`/requests/${carParkId}/car`, { method: 'GET', params, ...options })

// Получить количество по статусам заявок
export const RequestsCarParkIdCountsGetRequest = (carParkId: number, params?: RequestsCarParkIdCountsGetParams, options?: any) => request<CountResource[]>(`/requests/${carParkId}/counts`, { method: 'GET', params, ...options })

// Получить заявку
export const RequestsRequestIdTypeTypeGetRequest = (requestId: number, type: RequestTypeEnum, options?: any) => request<any>(`/requests/${requestId}/type/${type}`, { method: 'GET', ...options })

// Создать заявку на тех обслуживание
export const RequestsCarParkIdMaintenancePostRequest = (carParkId: number, body: CreateRequestMaintenanceRequest, options?: any) => request<RequestMaintenanceResource>(`/requests/${carParkId}/maintenance`, { body, method: 'POST', ...options })

// Создать заявку шинный сервис
export const RequestsCarParkIdTireServicePostRequest = (carParkId: number, body: CreateTireServiceRequest, options?: any) => request<RequestTireServiceResource>(`/requests/${carParkId}/tire-service`, { body, method: 'POST', ...options })

// Создать заявку акт сверки
export const RequestsCarParkIdActOfReconciliationPostRequest = (carParkId: number, body: CreateActOfReconciliationRequest, options?: any) => request<RequestActOfReconciliationResource>(`/requests/${carParkId}/act-of-reconciliation`, { body, method: 'POST', ...options })

// Создать заявку перегон авто
export const RequestsCarParkIdCarDrivingPostRequest = (carParkId: number, body: CreateCarDrivingRequest, options?: any) => request<RequestCarDrivingResource>(`/requests/${carParkId}/car-driving`, { body, method: 'POST', ...options })

// Создать заявку ДТП
export const RequestsCarParkIdCarCrashPostRequest = (carParkId: number, body: CreateCarCrashRequest, options?: any) => request<RequestCarCrashResource>(`/requests/${carParkId}/car-crash`, { body, method: 'POST', ...options })

// Создать заявку выкуп авто
export const RequestsCarParkIdCarBuybackPostRequest = (carParkId: number, body: CreateCarBuybackRequest, options?: any) => request<RequestCarBuybackResource>(`/requests/${carParkId}/car-buyback`, { body, method: 'POST', ...options })

// Создать заявку подменный авто
export const RequestsCarParkIdReplacementCarPostRequest = (carParkId: number, body: CreateReplacementCarRequest, options?: any) => request<RequestReplacementCarResource>(`/requests/${carParkId}/replacement-car`, { body, method: 'POST', ...options })

// Создать заявку на отзыв
export const RequestsCarParkIdRequestReviewPostRequest = (carParkId: number, body: CreateReviewRequest, options?: any) => request<RequestReviewResource>(`/requests/${carParkId}/request-review`, { body, method: 'POST', ...options })

// Отправить отзыв заявке
export const RequestsDriverCarIdReviewRequestIdPostRequest = (carId: number, requestId: number, body: CreateRatingRequest, options?: any) => request<RatingRequest>(`/requests-driver/${carId}/review/${requestId}`, { body, method: 'POST', ...options })

// Получить автомобиль автопарка
export const RequestsDriverCarIdCarGetRequest = (carId: number, options?: any) => request<CarRequestDataResource>(`/requests-driver/${carId}/car`, { method: 'GET', ...options })

// Получить заявки водителя
export const RequestsDriverCarIdGetRequest = (carId: number, params?: RequestsDriverCarIdGetParams, options?: any) => request<RequestPaginator>(`/requests-driver/${carId}`, { method: 'GET', params, ...options })

// Получить количество по статусам заявок
export const RequestsDriverCarIdCountsGetRequest = (carId: number, params?: RequestsDriverCarIdCountsGetParams, options?: any) => request<CountResource[]>(`/requests-driver/${carId}/counts`, { method: 'GET', params, ...options })

// Получить заявку
export const RequestsDriverRequestIdTypeTypeGetRequest = (requestId: number, type: RequestTypeEnum, options?: any) => request<any>(`/requests-driver/${requestId}/type/${type}`, { method: 'GET', ...options })

// Создать заявку на тех обслуживание
export const RequestsDriverCarIdMaintenancePostRequest = (carId: number, body: CreateRequestMaintenanceRequest, options?: any) => request<RequestMaintenanceResource>(`/requests-driver/${carId}/maintenance`, { body, method: 'POST', ...options })

// Создать заявку шинный сервис
export const RequestsDriverCarIdTireServicePostRequest = (carId: number, body: CreateTireServiceRequest, options?: any) => request<RequestTireServiceResource>(`/requests-driver/${carId}/tire-service`, { body, method: 'POST', ...options })

// Создать заявку перегон авто
export const RequestsDriverCarIdCarDrivingPostRequest = (carId: number, body: CreateCarDrivingRequest, options?: any) => request<RequestCarDrivingResource>(`/requests-driver/${carId}/car-driving`, { body, method: 'POST', ...options })

// Создать заявку выкуп авто
export const RequestsDriverCarIdCarBuybackPostRequest = (carId: number, body: CreateCarBuybackRequest, options?: any) => request<RequestCarBuybackResource>(`/requests-driver/${carId}/car-buyback`, { body, method: 'POST', ...options })

// Согласовать или оспорить отчет по восстановительному ремонту
export const InspectionReportAgreementInspectionReportIdPutRequest = (inspectionReportId: number, body: AgreementInspectionReportRequestBody, options?: any) => request<any>(`/inspection-report/agreement/${inspectionReportId}`, { body, method: 'PUT', ...options })

// Создать заявку подменный авто
export const RequestsDriverCarIdReplacementCarPostRequest = (carId: number, body: CreateReplacementCarRequest, options?: any) => request<RequestReplacementCarResource>(`/requests-driver/${carId}/replacement-car`, { body, method: 'POST', ...options })

// Создать заявку на отзыв
export const RequestsDriverCarIdRequestReviewPostRequest = (carId: number, body: CreateReviewRequest, options?: any) => request<RequestReviewResource>(`/requests-driver/${carId}/request-review`, { body, method: 'POST', ...options })

// Получить штрафы
export const PenaltyCarParkIdGetRequest = (carParkId: number, params?: PenaltyCarParkIdGetParams, options?: any) => request<PenaltyPaginatorAndFile>(`/penalty/${carParkId}`, { method: 'GET', params, ...options })

// Получить все штрафы в Системе
export const PenaltyTotalGetRequest = (params?: PenaltyTotalGetParams, options?: any) => request<PenaltyPaginatorAndFile>('/penalty/total', { method: 'GET', params, ...options })

// Получить количество всех штрафов в Системе
export const PenaltyCountTotalGetRequest = (params?: PenaltyCountTotalGetParams, options?: any) => request<CountResource[]>('/penalty/count/total', { method: 'GET', params, ...options })

// Получить сумму всех штрафов в Системе
export const PenaltySumTotalGetRequest = (options?: any) => request<PenaltySum>('/penalty/sum/total', { method: 'GET', ...options })

// Получить количество штрафов
export const PenaltyCountCarParkIdGetRequest = (carParkId: number, params?: PenaltyCountCarParkIdGetParams, options?: any) => request<CountResource[]>(`/penalty/count/${carParkId}`, { method: 'GET', params, ...options })

// Получить сумму штрафов
export const PenaltySumCarParkIdGetRequest = (carParkId: number, options?: any) => request<PenaltySum>(`/penalty/sum/${carParkId}`, { method: 'GET', ...options })

// Отправить на оспарение штраф
export const PenaltyPenaltyIdPutRequest = (penaltyId: number, options?: any) => request<any>(`/penalty/${penaltyId}/`, { method: 'PUT', ...options })

// Смена статуса при раскрытии строки флит менеджер
export const PenaltyReadPenaltyIdPutRequest = (penaltyId: number, options?: any) => request<any>(`/penalty/read/${penaltyId}`, { method: 'PUT', ...options })

// Получить штрафы
export const PenaltyDriverCarIdGetRequest = (carId: number, params?: PenaltyDriverCarIdGetParams, options?: any) => request<PenaltyPaginator>(`/penalty-driver/${carId}`, { method: 'GET', params, ...options })

// Получить количество штрафов
export const PenaltyDriverCountCarIdGetRequest = (carId: number, params?: PenaltyDriverCountCarIdGetParams, options?: any) => request<CountResource[]>(`/penalty-driver/count/${carId}`, { method: 'GET', params, ...options })

// Получить сумму штрафов
export const PenaltyDriverSumCarIdGetRequest = (carId: number, options?: any) => request<PenaltySum>(`/penalty-driver/sum/${carId}`, { method: 'GET', ...options })

// Отправить на оспарение штраф
export const PenaltyDriverPenaltyIdPutRequest = (penaltyId: number, options?: any) => request<any>(`/penalty-driver/${penaltyId}`, { method: 'PUT', ...options })

// Смена статуса при раскрытии строки водитель
export const PenaltyDriverReadPenaltyIdPutRequest = (penaltyId: number, options?: any) => request<any>(`/penalty-driver/read/${penaltyId}`, { method: 'PUT', ...options })

// Получить данные для выгрузки штрафов в пдф
export const PenaltyZipGetRequest = (params?: PenaltyZipGetParams, options?: any) => request<PDFPenaltyResource>('/penalty-zip', { method: 'GET', params, ...options })

// Получить данные для выгрузки полисов в пдф
export const InsuranceZipCarParkIdGetRequest = (carParkId: number, params: InsuranceZipCarParkIdGetParams, options?: any) => request<PDFPenaltyResource>(`/insurance-zip/${carParkId}`, { method: 'GET', params, ...options })

// Получить полисы
export const InsuranceCarParkIdGetRequest = (carParkId: number, params?: InsuranceCarParkIdGetParams, options?: any) => request<InsurancePaginator>(`/insurance/${carParkId}`, { method: 'GET', params, ...options })

// Получить количество полисов
export const InsuranceCountCarParkIdGetRequest = (carParkId: number, params?: InsuranceCountCarParkIdGetParams, options?: any) => request<CountResource[]>(`/insurance/count/${carParkId}`, { method: 'GET', params, ...options })

// Получить ссылку на оплату
export const PenaltyPaymentPostRequest = (body: PenaltyPaymentRequest, options?: any) => request<string>('/penalty-payment', { body, method: 'POST', ...options })

// Получить статус заказа
export const PenaltyPaymentStatusTokenGetRequest = (token: string, options?: any) => request<PaymentStatusEnum>(`/penalty-payment/status/${token}`, { method: 'GET', ...options })

// Получить года и месяца для страницы мой интермарк
export const IntermarkYearsGetRequest = (options?: any) => request<PageYearsResource[]>('/intermark/years', { method: 'GET', ...options })

// Получить данные авто
export const IntermarkCarIdGetRequest = (carId: number, params?: IntermarkCarIdGetParams, options?: any) => request<CarPageResource>(`/intermark/${carId}`, { method: 'GET', params, ...options })

// Получить контакты автопарка
export const ContactsCarParkCarParkIdGetRequest = (carParkId: number, options?: any) => request<ContactResource[]>(`/contacts/car-park/${carParkId}`, { method: 'GET', ...options })

// Получить контакты для водителя
export const ContactsDriverCarIdGetRequest = (carId: number, options?: any) => request<ContactResource[]>(`/contacts/driver/${carId}`, { method: 'GET', ...options })

// Получить счета автопарка
export const InvoiceCarParkIdGetRequest = (carParkId: number, params?: InvoiceCarParkIdGetParams, options?: any) => request<InvoiceResource[]>(`/invoice/${carParkId}`, { method: 'GET', params, ...options })

// Получить страховую сатистику автопарка
export const InsuredEventCarParkIdGetRequest = (carParkId: number, params?: InsuredEventCarParkIdGetParams, options?: any) => request<InsuranceStatisticsPaginator>(`/insured-event/${carParkId}`, { method: 'GET', params, ...options })

// Получить список сущностей полисов для автопарка
export const DictionariesInsuranceCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryInsuranceResource>(`/dictionaries/insurance/${carParkId}`, { method: 'GET', ...options })

// Получить список сущностей автопарка
export const DictionariesCarParkCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryCarParkResource>(`/dictionaries/car-park/${carParkId}`, { method: 'GET', ...options })

// Получить список сущностей штрафов флит
export const DictionariesPenaltyFleetCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryPenaltyResource>(`/dictionaries/penalty-fleet/${carParkId}`, { method: 'GET', ...options })

// Получить список сущностей штрафов водитель
export const DictionariesPenaltyDriverCarIdGetRequest = (carId: number, options?: any) => request<DictionaryPenaltyResource>(`/dictionaries/penalty-driver/${carId}`, { method: 'GET', ...options })

// Получить список сущностей заявок флита
export const DictionariesRequestFleetCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryRequestResource>(`/dictionaries/request-fleet/${carParkId}`, { method: 'GET', ...options })

// Получить список сущностей заявок водителя
export const DictionariesRequestDriverCarIdGetRequest = (carId: number, options?: any) => request<DictionaryRequestResource>(`/dictionaries/request-driver/${carId}`, { method: 'GET', ...options })

// Получить список сущностей заявок водителя
export const DictionariesInvoicesCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryInvoiceResource>(`/dictionaries/invoices/${carParkId}`, { method: 'GET', ...options })

// Получить список сервисов для ТО
export const DictionariesMaintenanceGetRequest = (options?: any) => request<DictionaryMaintenanceResource>('/dictionaries/maintenance', { method: 'GET', ...options })

// Получить список сервисов для шинного сервиса
export const DictionariesTireServiceGetRequest = (options?: any) => request<DictionaryTireServicesResource>('/dictionaries/tire-service', { method: 'GET', ...options })

// Получить список для восстановительного ремонта
export const DictionariesCarParkRepairCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryCarParkRepairResource>(`/dictionaries/car-park-repair/${carParkId}`, { method: 'GET', ...options })

// Получить список данных для заявок флит
export const DictionariesRequestsCarParkIdGetRequest = (carParkId: number, params?: DictionariesRequestsCarParkIdGetParams, options?: any) => request<RequestDictionary>(`/dictionaries/requests/${carParkId}`, { method: 'GET', params, ...options })

// Получить список данных для заявок флит с автомобилями только со статусом в обслуживании
export const DictionariesRequestsCarParkIdInServiceGetRequest = (carParkId: number, params?: DictionariesRequestsCarParkIdInServiceGetParams, options?: any) => request<RequestDictionary>(`/dictionaries/requests/${carParkId}/in-service`, { method: 'GET', params, ...options })

// Получить список категорий для заявки
export const DictionariesRequestsCategoryCategoryGetRequest = (type: string, options?: any) => request<RequestDictionaryCategory>(`/dictionaries/requests/category/${type}`, { method: 'GET', ...options })

// Проверить существует ли подменный авто
export const DictionariesRequestsCheckReplacementCarParkIdGetRequest = (carParkId: number, params?: DictionariesRequestsCheckReplacementCarParkIdGetParams, options?: any) => request<RequestCheckReplacement>(`/dictionaries/requests-check-replacement/${carParkId}`, { method: 'GET', params, ...options })

// Получить список данных для заявок водителя
export const DictionariesRequestsDriverCarIdGetRequest = (carId: number, options?: any) => request<RequestDictionaryDriver>(`/dictionaries/requests-driver/${carId}`, { method: 'GET', ...options })

// Получить список данных для страховой статистики
export const DictionariesInsuredEventCarParkIdGetRequest = (carParkId: number, options?: any) => request<DictionaryInsuranceStatisticsResource>(`/dictionaries/insured-event/${carParkId}`, { method: 'GET', ...options })

// Получить документы
export const DocumentsRoleGetRequest = (role: 'fleet'|'driver', options?: any) => request<DocumentResource[]>(`/documents/${role}`, { method: 'GET', ...options })

// Получить дашборды флит
export const DashboardFleetCarParkIdGetRequest = (carParkId: number, params?: DashboardFleetCarParkIdGetParams, options?: any) => request<DashboardFleetResource>(`/dashboard/fleet/${carParkId}`, { method: 'GET', params, ...options })

// Получить дашборды водитель
export const DashboardDriverCarIdGetRequest = (carId: number, params?: DashboardDriverCarIdGetParams, options?: any) => request<DashboardDriverResource>(`/dashboard/driver/${carId}`, { method: 'GET', params, ...options })

// Получить дашборды флит последние актуальные данные
export const DashboardFleetCurrentCarParkIdGetRequest = (carParkId: number, options?: any) => request<DashboardFleetResource>(`/dashboard/fleet-current/${carParkId}`, { method: 'GET', ...options })

// Получить дашборды водитель последние актуальные данные
export const DashboardDriverCurrentCarIdGetRequest = (carId: number, options?: any) => request<DashboardDriverResource>(`/dashboard/driver-current/${carId}`, { method: 'GET', ...options })

// Получить дашборды механика
export const DashboardMechanicCarParkIdGetRequest = (carParkId: number, params?: DashboardMechanicCarParkIdGetParams, options?: any) => request<DashboardFleetResource>(`/dashboard/mechanic/${carParkId}`, { method: 'GET', params, ...options })

// Получить дашборды механика последние актуальные данные
export const DashboardMechanicCurrentCarParkIdGetRequest = (carParkId: number, options?: any) => request<DashboardFleetResource>(`/dashboard/mechanic-current/${carParkId}`, { method: 'GET', ...options })

// Загрузить файл
export const UploadFilePostRequest = (body: UploadFileRequestBody, options?: any) => request<FileResource>('/upload-file', { body, method: 'POST', ...options })

// Новости
export const NewsGetRequest = (options?: any) => request<NewsResource[]>('/news', { method: 'GET', ...options })

// Получить контакты о заявке
export const RequestsInfoRequestIdGetRequest = (requestId: number, options?: any) => request<RequestContactResource>(`/requests/info/${requestId}`, { method: 'GET', ...options })

// Создать отчет
export const ReportsCarParkIdPostRequest = (carParkId: number, body: CreateReportRequest, options?: any) => request<ReportResource>(`/reports/${carParkId}`, { body, method: 'POST', ...options })

// Создать отчет только настройки
export const ReportsSettingsCarParkIdPostRequest = (carParkId: number, body: CreateReportRequest, options?: any) => request<ReportResource>(`/reports/settings/${carParkId}`, { body, method: 'POST', ...options })

// Создать отчет водитель
export const ReportsDriverCarIdPostRequest = (carId: number, body: CreateReportRequest, options?: any) => request<ReportResource>(`/reports-driver/${carId}`, { body, method: 'POST', ...options })

// Создать отчет водитель только настройки
export const ReportsDriverSettingsCarIdPostRequest = (carId: number, body: CreateReportRequest, options?: any) => request<ReportResource>(`/reports-driver/settings/${carId}`, { body, method: 'POST', ...options })

// Удалить отчет
export const ReportsReportIdDeleteRequest = (reportId: number, options?: any) => request<any>(`/reports/${reportId}`, { method: 'DELETE', ...options })

// Получить отчет
export const ReportsReportIdGetRequest = (reportId: number, options?: any) => request<ReportEditResource>(`/reports/${reportId}`, { method: 'GET', ...options })

// Редактировать отчет
export const ReportsReportIdCarParkIdPutRequest = (reportId: number, carParkId: number, body: CreateReportRequest, options?: any) => request<ReportEditResource>(`/reports/${reportId}/${carParkId}`, { body, method: 'PUT', ...options })

// Редактировать отчет водитель
export const ReportsDriverReportIdCarIdPutRequest = (reportId: number, carId: number, body: CreateReportRequest, options?: any) => request<ReportEditResource>(`/reports-driver/${reportId}/${carId}`, { body, method: 'PUT', ...options })

// Пересоздать файл отчета
export const ReportsCreateReportIdPutRequest = (reportId: number, options?: any) => request<ReportEditResource>(`/reports/create/${reportId}`, { method: 'PUT', ...options })

// Получить список отчетов
export const ReportsCarParkCarParkIdGetRequest = (carParkId: number, params?: ReportsCarParkCarParkIdGetParams, options?: any) => request<ReportsResource[]>(`/reports/car-park/${carParkId}`, { method: 'GET', params, ...options })

// Получить список отчетов водитель
export const ReportsDriverCarCarIdGetRequest = (carId: number, params?: ReportsDriverCarCarIdGetParams, options?: any) => request<ReportsResource[]>(`/reports-driver/car/${carId}`, { method: 'GET', params, ...options })

// Получить список полей
export const ReportsFieldsGetRequest = (options?: any) => request<ReportFieldsResource[]>('/reports/fields', { method: 'GET', ...options })

// Получить список подкатегорий дтп
export const DictionariesRequestsCrashCategoriesGetRequest = (category: string, options?: any) => request<RequestCrashCategoryResource[]>(`/dictionaries/requests/category/${category}`, { method: 'GET', ...options })
