// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'
import {
  CountResource,
  DictionariesInvoicesCarParkIdGetRequest,
  DictionaryInvoiceResource,
  InvoiceCarParkIdGetParams,
  InvoiceCarParkIdGetRequest,
  InvoiceResource,
} from '@/store/types'
import { deleteEmpty } from '@/utils/functions'


@Module({
  dynamic: true,
  name: 'bills',
  store,
  namespaced: true,
})

class Bills extends VuexModule {
  /**
   * Работа со статусами - чипсами
   */
  statuses: CountResource[] = []

  statusId: number | string | undefined = ''

  @Mutation
  setStatuses (payload: CountResource[]) {
    Vue.set(this, 'statuses', payload)
  }

  @Mutation
  setStatusId (payload: number | string | undefined) {
    Vue.set(this, 'statusId', payload)
  }


  /**
   * Работа со счетами
   */

  bills: InvoiceResource[] = []

  @Action({ rawError: true })
  async getBills (carParkId: number) {
    const { data } = await InvoiceCarParkIdGetRequest(carParkId, this.billsFilter)

    this.setBills(data)

    const requestData = [
      {
        value: 'all',
        name: 'все',
        count: data.length,
      },
      {
        value: 'available',
        name: 'доступно',
        count: data.filter((item: InvoiceResource) => new Date(item.finishDate).getTime() > new Date().getTime()).length,
      },
      {
        value: 'lateToPay',
        name: 'просрочено',
        count: data.filter((item: InvoiceResource) => new Date(item.finishDate).getTime() < new Date().getTime()).length,
      },
    ]
    this.setStatuses(requestData as CountResource[])
  }

  @Mutation
  setBills (payload: InvoiceResource[]) {
    Vue.set(this, 'bills', payload)
  }

  /**
   * Справочники для селектов
   */
  dictionaries: DictionaryInvoiceResource = {
    invoiceNumber: [],
    invoiceCategory: [],
  }

  @Action({ rawError: true })
  async getDictionaries (carParkId: any) {
    const { data } = await DictionariesInvoicesCarParkIdGetRequest(carParkId)
    this.setDictionaries(data)

    return data
  }

  @Mutation
  setDictionaries (payload: any) {
    Vue.set(this, 'dictionaries', payload)
  }

  // ================================== REQUEST-PARAMS ===========================================>

  billsFilter: InvoiceCarParkIdGetParams = {
    // page: 1, // default
    // perPage: 5, // default
  }

  @Mutation
  setBillsFilter (payload: any) {
    Vue.set(this, 'billsFilter', deleteEmpty({ ...this.billsFilter, ...payload }))
  }
}

const BillsModule = getModule(Bills)

export default BillsModule
