import { render, staticRenderFns } from "./TechWorkPage.vue?vue&type=template&id=9c11e06a&scoped=true&lang=pug&"
import script from "./TechWorkPage.vue?vue&type=script&lang=ts&"
export * from "./TechWorkPage.vue?vue&type=script&lang=ts&"
import style0 from "./TechWorkPage.vue?vue&type=style&index=0&id=9c11e06a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c11e06a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VContainer,VMain})
