// CORE
import Vue from 'vue'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

// STORE
import store from '@/store'

// INTERFACES
import { ProfileRequestBody, ProfilePutRequest } from './../types/schema'
import { NameValueResource, UserResource } from '../types'
import { IMessage } from './../types/auth'
import {
  ChangePasswordRequestBody,
  ChangePasswordPutRequest,
} from './../types/schema'

/**
 * Захардкоженные ссылки для сайдбара по ролям
 * Данные юзера (ФИО, Телефоны, e-mail, Автопарки, Автомобили, Роли)
 * Выбранная роль, автомобиль, автопарк.
 * Настройки виджетов
 * Изменения профиля
 */

@Module({
  dynamic: true,
  name: 'profile',
  store,
  namespaced: true,
})
class Profile extends VuexModule {
  // ------------------------------------ CONSTS --------------------------------------------=+o>

  // Списки ссылок для сайдбара по ролям
  readonly FLEET_NAVLIST = [
    {
      url: { name: 'carpark' },
      name: 'Автопарк',
      icon: 'mdi-car-multiple',
    },
    {
      url: { name: 'orders' },
      name: 'Заявки',
      icon: 'mdi-format-list-bulleted-square',
      newButton: true,
    },
    {
      url: { name: 'penalty' },
      name: 'Штрафы',
      icon: 'mdi-alert-octagon',
    },
    {
      url: { name: 'policies' },
      name: 'Полисы',
      icon: '$shieldOk',
    },
    {
      url: { name: 'bills' },
      name: 'Счета',
      icon: 'mdi-bank',
    },
    {
      url: { name: 'documents' },
      name: 'Документы',
      icon: 'mdi-file-document',
    },
    {
      url: { name: 'reports' },
      name: 'Отчеты',
      icon: 'mdi-chart-box',
    },
    {
      url: { name: 'contacts' },
      name: 'Контакты',
      icon: 'mdi-account-box-multiple',
    },
  ]

  readonly CAR_DRIVER_NAVLIST = [
    {
      url: { name: 'orders' },
      name: 'Заявки',
      icon: 'mdi-format-list-bulleted-square',
      newButton: true,
    },
    {
      url: { name: 'penalty' },
      name: 'Штрафы',
      icon: 'mdi-alert-octagon',
    },
    {
      url: { name: 'documents' },
      name: 'Документы',
      icon: 'mdi-file-document',
    },
    {
      url: { name: 'reports' },
      name: 'Отчеты',
      icon: 'mdi-chart-box',
    },
    {
      url: { name: 'contacts' },
      name: 'Контакты',
      icon: 'mdi-account-box-multiple',
    },
  ]

  readonly MECHANIC_NAVLIST = [
    {
      url: { name: 'carpark' },
      name: 'Автопарк',
      icon: 'mdi-car-multiple',
    },
    {
      url: { name: 'orders' },
      name: 'Заявки',
      icon: 'mdi-format-list-bulleted-square',
      newButton: true,
    },
    {
      url: { name: 'penalty' },
      name: 'Штрафы',
      icon: 'mdi-alert-octagon',
    },

  ]

  // ------------------------------------ USER ----------------------------------------------=+o>

  userResource = {} as UserResource

  userRoles: NameValueResource[] = []

  @Mutation
  setUserRoles (payload: NameValueResource[]) {
    Vue.set(this, 'userRoles', payload)
  }

  @Mutation
  setUserResource (payload: UserResource) {
    Vue.set(this.userResource, 'user', payload.user)
    Vue.set(this.userResource, 'carParks', payload.carParks)
    Vue.set(this.userResource, 'carGarages', payload.carGarages)
    Vue.set(this.userResource, 'cars', payload.cars)
  }

  @Action({ rawError: true })
  handleUserResource (payload: UserResource) {
    const userRoles: NameValueResource[] = []


    payload.carParks?.forEach(carPark => {
      userRoles.push({
        name: 'fleet',
        value: carPark.partnerNumber,
      })
    })


    payload.cars?.forEach(car => {
      userRoles.push({
        name: 'driver',
        value: car.vin,
      })
    })


    payload.carGarages?.forEach(carGarage => {
      userRoles.push({
        name: 'mechanic',
        value: carGarage.partnerNumber,
      })
    })


    this.setUserRoles(userRoles)
    this.setUserResource(payload)
  }

  @Mutation
  clearUserData () {
    Vue.set(this, 'userResource', {})
    Vue.set(this, 'userRoles', {})

    localStorage.getItem('userRoleID') && localStorage.removeItem('userRoleID')
  }

  // ------------------------------------ CHANGE PROFILE ----------------------------------------------=+o>

  @Action({ rawError: true })
  async changePassword (payload: ChangePasswordRequestBody) {
    const { status } = await ChangePasswordPutRequest(payload)

    if (status === 204) {
      return {
        success: true,
        message: 'Пароль успешно изменён',
      } as IMessage
    } else {
      return {
        success: false,
        message: 'Что-то пошло не так',
      } as IMessage
    }
  }

  @Action({ rawError: true })
  async changeProfile (payload: ProfileRequestBody) {
    const { data, status } = await ProfilePutRequest(payload)

    if (status === 200 || status === 204) {
      this.setUserResource(data)
    }

    // if (status === 204) {
    //   return {
    //     success: true,
    //     message: 'Данные успешно изменены',
    //   } as IMessage
    // } else {
    //   return {
    //     success: false,
    //     message: 'Что-то пошло не так',
    //   } as IMessage
    // }
  }
}

const ProfileModule = getModule(Profile)

export default ProfileModule
