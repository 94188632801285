












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LinkFile extends Vue {
  @Prop({ required: true })
  readonly url!: string

  @Prop({
    default: 'a',
    validator (value: string): boolean {
      return !!value.match(/(span|a|router-link|div)/)
    },
  })
  readonly tag!: string
}
