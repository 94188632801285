




























// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemMixin from '@/mixins/SystemMixin'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'
import SmallModalFooter from '@/components/modals/components/SmallModalFooter.vue'

// TYPES
import { IModalExportOptions } from '@/store/types'

@Component({
  components: {
    ModalHeader,
    SmallModalFooter,
  },
})

export default class PDFDownloadModal extends Mixins(NotifyMixin, SystemMixin) {
  @Prop({ required: true })
  private options!: IModalExportOptions

  private get warnList () {
    const warnlist = this.options.checkedItems?.filter(item => !item.fileLink)
      .map(item => item.protocolNumber) ?? []

    const errorList = this.options.checkedItems?.filter(item => {
      return this.options.errorItems?.includes(item.protocolNumber as any)
    }).map(item => item.protocolNumber) ?? []

    return warnlist.concat(errorList)
  }

  private handleDownload () {
    if (this.options.exportURL) {
      const tab = window.open(this.options.exportURL, '_blank')

      if (tab) {
        tab.focus()
      }

      this.$closeModal()
    }
  }
}
