

























































import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import AvatarCard from '@/components/cards/AvatarCard.vue'
import NavigateList from '@/components/_uikit/NavigateList.vue'
import NewsWidget from '@/components/widgets/NewsWidget.vue'
import IntermarkLabel from '@/components/IntermarkLabel.vue'

// INTERFACES
import { INavigateItem, INews } from '@/store/types'

// STORE
import NewsModule from '@/store/modules/news'
import ProfileModule from '@/store/modules/profile'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import ThemeMixin from '@/mixins/ThemeMixin'

@Component({
  components: {
    AvatarCard,
    NewsWidget,
    NavigateList,
    IntermarkLabel,
  },
})
export default class Sidebar extends Mixins(SystemMixin, ThemeMixin) {
  private isBurgerOpen = false

  private get slides (): INews[] {
    return NewsModule.news
  }

  private get navList (): INavigateItem[] {
    let profile: INavigateItem[]
    if (this.role === 'fleet') {
      profile = ProfileModule.FLEET_NAVLIST
    } else if (this.role === 'driver') {
      profile = ProfileModule.CAR_DRIVER_NAVLIST
    } else if (this.role === 'mechanic') {
      profile = ProfileModule.MECHANIC_NAVLIST
    } else {
      profile = []
    }
    return profile
  }

  private get width (): number | string {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return 322
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return 258
    } else {
      return '100%'
    }
  }

  private mounted () {
    NewsModule.getNews()
  }

  private handleChangePage () {
    this.$nextTick(() => {
      this.isBurgerOpen = false
    })
  }
}
