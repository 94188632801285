import auth from './_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  {
    path: 'reports',
    name: 'reports',
    meta: { middleware: [auth, roleValidate] },
    component: () => import('@/views/role/entity/reports/ReportsLayout.vue'),
  },
  {
    path: 'reports',
    name: 'report',
    meta: { middleware: [auth, roleValidate] },
    component: () => import('@/views/role/entity/reports/ReportLayout.vue'),
    children: [
      {
        path: 'new',
        name: 'report.new',
        meta: { middleware: [auth, roleValidate] },
        component: () => import('@/views/role/entity/reports/views/ReportNew.vue'),
      },
      {
        path: ':reportId',
        name: 'report.edit',
        meta: { middleware: [auth, roleValidate] },
        component: () => import('@/views/role/entity/reports/views/ReportEdit.vue'),
      },
      {
        path: ':reportId/copy',
        name: 'report.copy',
        meta: { middleware: [auth, roleValidate] },
        component: () => import('@/views/role/entity/reports/views/ReportCopy.vue'),
      },
    ],
  },
]
