



















import { Component, Prop, Vue } from 'vue-property-decorator'

import { INameValueItem } from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class Dropdown extends Vue {
  @Prop({ default: () => ([]) })
  private eventsList!: INameValueItem[]

  @Prop({
    default: 'primary',
    validator (value:string): boolean {
      return !!value.match(/(primary|secondary)/)
    },
  })
  private buttonMod!: string

  @Prop({ default: false })
  private isMini!: boolean

  @Prop({ default: 'Dropdown' })
  private label!: string

  private handleClick (eventName: string) {
    this.$emit(eventName)
  }
}
