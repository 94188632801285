// CORE
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// Stores
import store from '@/store'

import {
  ReportsDriverCarIdPostRequest,
  CreateReportRequest,
  ReportsDriverReportIdCarIdPutRequest,
  ReportsDriverCarCarIdGetRequest,
  ReportsCarParkCarParkIdGetParams,
  ReportDataResource,
  ReportsResource,
  ReportsDriverSettingsCarIdPostRequest,
} from '@/store/types'

@Module({
  dynamic: true,
  name: 'driver-reports',
  namespaced: true,
  store,
})
class DriverReports extends VuexModule {
  reports: ReportsResource[] = []

  get customReports (): ReportDataResource[] {
    const customRep = this.reports.find((item) => item.type === 'custom')

    return customRep?.reports ?? []
  }

  get defaultReports (): ReportDataResource[] {
    const defaultRep = this.reports.find((item) => item.type === 'default')

    return defaultRep?.reports ?? []
  }

  get allReports (): ReportDataResource[] {
    return this.defaultReports.concat(this.customReports)
  }

  @Mutation
  setReports (payload: ReportsResource[]) {
    this.reports = payload
  }

  // Получить список конфигураций отчётов
  @Action({ rawError: true })
  async fetchReports ({
    carId,
    params,
  }: {
    carId: number,
    params?: ReportsCarParkCarParkIdGetParams,
  }) {
    const { data } = await ReportsDriverCarCarIdGetRequest(carId, params)

    this.setReports(data)
  }

  // Отправить шаблон отчёта без формирования
  @Action({ rawError: true })
  async createReportSetting ({
    carId,
    body,
  }: {
    carId: number,
    body: CreateReportRequest,
  }) {
    const { data } = await ReportsDriverSettingsCarIdPostRequest(carId, body)

    return data
  }

  // Отправить конфигурацию отчёта и сформировать отчёт
  @Action({ rawError: true })
  async createReport ({
    carId,
    body,
  }: {
    carId: number,
    body: CreateReportRequest,
  }) {
    const { data } = await ReportsDriverCarIdPostRequest(carId, body)

    return data
  }

  // Редактировать отчёт
  @Action({ rawError: true })
  async updateReport ({
    reportId,
    carId,
    body,
  }: {
    reportId: number,
    carId: number,
    body: CreateReportRequest,
  }) {
    const { data } = await ReportsDriverReportIdCarIdPutRequest(
      reportId,
      carId,
      body,
    )

    return data
  }
}

const DriverReportsModule = getModule(DriverReports)

export default DriverReportsModule
