






















import { Component, Prop, Vue } from 'vue-property-decorator'

import NewsCard from '@/components/cards/NewsCard.vue'
import { NewsResource } from '@/store/types'

@Component({
  components: {
    NewsCard,
  },
})
export default class NewsWidget extends Vue {
  @Prop({ default: () => ([]) })
  private readonly slides!: NewsResource[]
}
