















































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import BillsModule from '@/store/modules/fleet/bills'

// Mixins
import SystemMixin from '@/mixins/SystemMixin'

// Interfaces
import NotifyMixin from '@/mixins/NotifyMixin'
import { tableOptionsToFilters } from '@/utils/functions'


interface IFilterForm {
  invoiceNumber?: string,
  invoiceCategory?: string,
  postStartDate?: string,
  postEndDate?: string,
  checkoutStartDate?: string,
  checkoutEndDate?: string,
}

@Component({
  components: {
    DrawerFooter,
  },
})

export default class BillsFilterForm extends Mixins(SystemMixin, NotifyMixin) {
  private get dictionaries () {
    return BillsModule.dictionaries
  }

  private form: IFilterForm = {
    invoiceNumber: undefined,
    invoiceCategory: undefined,
    postStartDate: '',
    postEndDate: '',
    checkoutStartDate: '',
    checkoutEndDate: '',
  }

  private get activeFilters () {
    return tableOptionsToFilters(BillsModule.billsFilter)
  }

  private get activeFiltersCount () {
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof IFilterForm] && item !== 'endDate'){
        count++
      }
    }
    return count
  }

  private get postPeriod (): any {
    const period = []
    if (this.form.postStartDate && this.form.postEndDate){
      period.push(this.form.postStartDate)
      period.push(this.form.postEndDate)
    }
    return period
  }

  private set postPeriod (value: any) {
    this.form.postStartDate = value[0]
    this.form.postEndDate = value[1]
  }

  private get checkoutPeriod (): any {
    const period = []
    if (this.form.checkoutStartDate && this.form.checkoutEndDate){
      period.push(this.form.checkoutStartDate)
      period.push(this.form.checkoutEndDate)
    }
    return period
  }

  private set checkoutPeriod (value: any) {
    this.form.checkoutStartDate = value[0]
    this.form.checkoutEndDate = value[1]
  }

  private created () {
    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }

  private clearFilters () {
    this.form = {
      invoiceNumber: undefined,
      invoiceCategory: undefined,
      postStartDate: '',
      postEndDate: '',
      checkoutStartDate: '',
      checkoutEndDate: '',
    }

    this.applyFilters(false)
  }

  private applyFilters (close = true) {
    BillsModule.setBillsFilter(this.form)
    BillsModule.getBills(+this.entity)
      .catch(this.notifyError)

    if (close){
      this.$closeDrawer()
    }
  }
}
