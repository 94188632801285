













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class Link extends Vue {
  @Prop({ default: null })
  readonly url!: string

  @Prop({
    default: null,
    validator (value: string) :boolean {
      return !!value.match(/(dark|light|inherit)/)
    },
  })
  readonly theme!: string

  @Prop({
    default: 'line',
    validator (value: string) :boolean {
      return !!value.match(/(red|dark-blue|line|dark-blue-line)/)
    },
  })
  readonly mod!: string

  @Prop({ default: null })
  readonly icon!: string

  @Prop({
    default: 'router-link',
    validator (value: string): boolean {
      return !!value.match(/(span|a|router-link|div)/)
    },
  })
  readonly tag!: string
}
