




















import { Component, Prop, Vue } from 'vue-property-decorator'

import { ILink } from '@/store/types'

@Component
export default class Breadcrumbs extends Vue {
  @Prop({ default: () => ({ name: 'main' }) })
  private mainPath!: string

  @Prop({ default: () => ([]) })
  private links!: ILink
}
