import auth from '@/router/_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  {
    component: () => import('@/views/role/entity/contacts/ContactsLayout.vue'),
    name: 'contacts',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/contacts',
  },
]
