var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"form person-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('Heading',{staticClass:"form__field-row form__field-row--center",attrs:{"size":"h2","mod":"medium"}},[_vm._v("Личные данные")]),_c('Paragraph',{staticClass:"form__field-row form__field-row--center",attrs:{"size":"subtitle","weight":"regular"},domProps:{"innerHTML":_vm._s('Полностью заполненные данные в&nbsp;профиле будут предзаполнены в&nbsp;полях при&nbsp;оформлении любой заявки в&nbsp;личном кабинете.')}}),_c('div',{staticClass:"form__field-row person-form__display-contents"},[_c('ValidationProvider',{staticClass:"form__field form__field--left",attrs:{"tag":"div","rules":"required","name":"surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{attrs:{"invalid":_vm.formValide.surname,"tabindex":"1","label":"Фамилия","error":errors[0],"required":true},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__field person-form__order-6",attrs:{"tag":"div","rules":"required|engOnlyEmail","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"invalid":_vm.formValide.email,"tabindex":"4","label":"E-mail","error":errors[0],"required":true,"icon":"mdi-email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"form__field-row person-form__display-contents"},[_c('ValidationProvider',{staticClass:"form__field form__field--left",attrs:{"tag":"div","rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"invalid":_vm.formValide.name,"tabindex":"2","label":"Имя","error":errors[0],"required":true},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__field person-form__order-7",attrs:{"tag":"div","rules":{ regex: _vm.regExpPhone },"name":"mobilePhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"invalid":_vm.formValide.phone,"tabindex":"5","label":"Телефон мобильный","error":errors[0],"mask":"+7 (###) ###-##-##","required":true,"icon":"mdi-phone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"form__field-row person-form__display-contents"},[_c('ValidationProvider',{staticClass:"form__field form__field--left",attrs:{"tag":"div","rules":"required","name":"patronymic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"invalid":_vm.formValide.patronymic,"tabindex":"3","label":"Отчество","error":errors[0],"required":true},model:{value:(_vm.form.patronymic),callback:function ($$v) {_vm.$set(_vm.form, "patronymic", $$v)},expression:"form.patronymic"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__field person-form__order-8",attrs:{"tag":"div","rules":{ regex: _vm.regExpPhoneWithCode },"name":"phoneWithCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"invalid":_vm.formValide.phoneCode,"tabindex":"6","label":"Телефон городской (код)","error":errors[0],"mask":"+7 (###) ###-##-## - ####","required":true,"icon":"mdi-map-marker"},model:{value:(_vm.form.phoneCode),callback:function ($$v) {_vm.$set(_vm.form, "phoneCode", $$v)},expression:"form.phoneCode"}})]}}],null,true)})],1),_c('SmallModalFooter',{staticClass:"person-form__order-9",on:{"click:action":_vm.handleSaveForm},scopedSlots:_vm._u([{key:"action",fn:function(){return [_vm._v("Сохранить")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }