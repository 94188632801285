







import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class Example extends Vue {
  @Prop({ default: 'Внимание!' })
  private heading!: boolean

  @Prop({ default: false })
  private centered!: boolean

  @Prop({ default: true })
  private closeble!: boolean

  @Prop({ default: true })
  private small!: boolean

  private handleClose () {
    this.$closeModal()
    this.$emit('close')
  }
}
