




















/**
 * Передовать надо template(v-for="item in items" v-slot:[item.value])
 * item.value это ключ для таба
 * item.name строка для вывода на экран
 */
// COTE
import { Component, Prop, Vue } from 'vue-property-decorator'

// INTERFACES
import { INameValueItem } from '@/store/types'

@Component({})
export default class Tabs extends Vue {
  @Prop({
    default: 'tabs',
    validator (value: string) :boolean {
      return !!value.match(/(tabs|links)/)
    },
  })
  readonly mod!: string

  @Prop({ default: () => ([]) })
  private items!: INameValueItem[]

  private activeTab = ''
}
