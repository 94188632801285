















import { Component, Prop, Vue } from 'vue-property-decorator'

import NavigateList from '@/components/_uikit/NavigateList.vue'

@Component({
  components: {
    NavigateList,
  },
})
export default class IntermarkLabel extends Vue {
  @Prop({
    default: null,
    validator (value: string) :boolean {
      return !!value.match(/(dark|light)/)
    },
  })
  readonly theme!: string

  @Prop({ default: 'div' })
  readonly tag!: string

  @Prop({ default: null })
  readonly url!: any

  @Prop({ default: true })
  readonly small!: boolean
}
