











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LinkBack extends Vue {
  @Prop({ default: null })
  readonly url!: string

  @Prop({
    default: 'div',
    validator (value: string): boolean {
      return !!value.match(/(span|a|router-link|div)/)
    },
  })
  readonly tag!: string

  private handleClick () {
    this.$emit('click')
    if (!this.url) {
      this.$router.go(-2)
    }
  }
}
