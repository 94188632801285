















import { Component, Vue, Watch } from 'vue-property-decorator'

type SnackbarType = 'success' | 'error' | 'info' | 'warn'

interface ISnackbarMessage {
  text: string,
  timeout: number,
  type: SnackbarType,
  alias?: string,
}

@Component
export default class Snackbar extends Vue {
  private visible = false
  private text = ''
  private timeout = 1500
  private type: SnackbarType = 'success'
  private buffer: ISnackbarMessage[] = []

  private mounted () {
    this.$bus.$on('snackbar-message', this.handleNewMessage)
    this.$bus.$on('snackbar-remove-message', this.handleRemoveMessage)
  }

  private handleNewMessage (message: ISnackbarMessage) {
    if (message.alias) {
      this.updateMessage(message)
    } else if (message.type === 'error') {
      this.closeMessage()
      this.buffer.push(message)
      this.showNextMessage()
    } else {
      this.closeMessage()
      this.buffer.push(message)
      this.showNextMessage()
    }
  }

  private handleRemoveMessage (alias: string) {
    const isVisibleMessage = this.buffer.findIndex(item => item.alias === alias) === 0

    this.buffer = this.buffer.filter(item => item.alias !== alias)

    if (isVisibleMessage) this.closeMessage(false)
  }

  private showNextMessage () {
    if (!this.visible && this.buffer.length > 0) {
      const message = this.buffer[0]

      this.text = message.text
      this.timeout = message.timeout
      this.type = message.type
      this.visible = true
    }
  }

  private updateMessage (message: ISnackbarMessage) {
    const index = this.buffer.findIndex(item => item.alias === message.alias)

    if (index >= 0) {
      this.buffer[index] = message
      if (index === 0) this.text = message.text
    } else {
      this.buffer.push(message)
      this.showNextMessage()
    }
  }

  private closeMessage (isNeedSlice = true) {
    this.visible = false
    this.handleVisibleChange(this.visible, isNeedSlice)
  }

  private handleVisibleChange (value: boolean, isNeedSlice = true) {
    if (!value) {
      setTimeout(() => {
        if (isNeedSlice) this.buffer = this.buffer.slice(1)
        this.showNextMessage()
      }, 150)
    }
  }

  private clear () {
    this.buffer = []
    this.visible = false
  }

  @Watch('$route.fullPath')
  private onRouterChange () {
    this.clear()
  }
}
