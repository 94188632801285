// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

//INTERFACE
import { InsuranceStatisticsPaginator, InsuredEventCarParkIdGetParams, DictionaryInsuranceStatisticsResource, CountResource, InsuredEventCarParkIdGetRequest, DictionariesInsuredEventCarParkIdGetRequest } from '@/store/types'
import { DataTableHeader } from 'vuetify'

// HELPERS
import { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from './../index'
import { tableOptionsToParams } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'insuranceStatistics',
  store,
  namespaced: true,
})

class Insurance extends VuexModule{
  /**
   * Работа со статусами - чипсами
   */
  statuses: CountResource[] = []

  @Mutation
  setStatuses (payload: CountResource[]) {
    Vue.set(this, 'statuses', payload)
  }

  @Mutation
  setActiveStatus (payload: number | string | undefined) {
    Vue.set(this.filters, 'status', payload)
    Vue.set(this.filters, 'page', 1)
  }

  /**
   * Работа с таблицей статистики страхования
   */
  insurances: InsuranceStatisticsPaginator = GET_DEFAULT_PAGINATOR()

  tableHeaders: DataTableHeader[] = [
    {
      text: 'VIN',
      value: 'vin',
    },
    {
      text: 'Гос. №',
      value: 'stateNumber',
      cellClass: 'small-cell',
      class: 'small-cell',
    },
    {
      text: 'Дата ДТП',
      value: 'crashDate',
    },
    {
      text: 'Категория',
      value: 'category',
    },
    {
      text: 'Тип',
      value: 'type',
    },
    {
      text: 'Повреждения',
      value: 'damage',
    },
    {
      text: 'Виновность',
      value: 'guilt',
    },
  ]

  @Action({ rawError: true })
  async getInsurances (carParkId: number) {
    const { data } = await InsuredEventCarParkIdGetRequest(carParkId, tableOptionsToParams(this.filters))
    this.setInsurances(data)
  }

  @Mutation
  setInsurances (payload: InsuranceStatisticsPaginator) {
    Vue.set(this, 'insurances', payload)
  }

  // ================================== DICTIONARIES ===========================================>

  insurancesDictionaries: DictionaryInsuranceStatisticsResource = {
    vin: [],
    stateNumber: [],
    category: [],
    guilt: [],
    numberPolicy: [],
    insuranceCompany: [],
    regionId: [],
    carModelId: [],
  }

  @Action({ rawError: true })
  async getFiltersDictionary (carParkId: number) {
    const { data } = await DictionariesInsuredEventCarParkIdGetRequest(carParkId)
    this.setFiltersDictionary(data)
  }

  @Mutation
  setFiltersDictionary (payload: DictionaryInsuranceStatisticsResource) {
    Vue.set(this, 'insurancesDictionaries', payload)
  }

  // ================================== REQUEST-PARAMS ===========================================>

  filters: InsuredEventCarParkIdGetParams = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setFilters (payload: any) {
    Vue.set(this, 'filters', { ...GET_DEFAULT_TABLE_FILTER(), ...payload })
  }

  @Mutation
  resetFilters () {
    Vue.set(this, 'filters', GET_DEFAULT_TABLE_FILTER())
  }
}

const InsuranceModule = getModule(Insurance)

export default InsuranceModule
