






























// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// INTERFACES
import { NameValueResource } from '@/store/types'

// MODULES
import OrderModule from '@/store/modules/order'

@Component
export default class AddServicesDrawer extends Vue {
  @Prop({ default: () => ({ name: 'Техническое обслуживание', type: 'planned', btnLabel: 'Продолжить' }) })
  readonly options!: { name: string, type: string, btnLabel: string }

  private get items (): NameValueResource[] {
    if (this.options.name === 'Техническое обслуживание') {
      return OrderModule.maintenanceServicesDictionaries
    } else {
      return OrderModule.tireServicesDictionaries
    }
  }

  private innerValues: NameValueResource[] = []

  private get values (): NameValueResource[] {
    let result = []
    if (this.$route.params.services) {
      result = JSON.parse(decodeURI(this.$route.params.services))

      result = result.filter((item: any) => this.items.map(item => item.value).includes(item.value))
    }

    return result
  }

  private created () {
    if (!this.items?.length) {
      OrderModule.getMaintenanceServicesDictionaries()
      OrderModule.getTireServicesDictionaries()
    }
  }

  private mounted () {
    this.innerValues = this.values
  }

  private handleClick () {
    const other = this.innerValues.find(item => item.name === 'Другое')

    if (other) {
      this.innerValues = this.innerValues.filter(item => item.name !== 'Другое')
      this.innerValues.push(other)
    }

    if (this.options.btnLabel === 'Продолжить') {
      this.$router.push({
        name: 'create-order.maintenance',
        params: { maintenanceType: this.options.type, services: encodeURI(JSON.stringify(this.innerValues)) },
      })
    } else {
      if (this.$route.name?.includes('maintenance')) {
        this.$router.replace({
          name: 'create-order.maintenance',
          params: {
            maintenanceType: this.$route.params.maintenanceType,
            services: encodeURI(JSON.stringify(this.innerValues)),
          },
        })
      } else {
        this.$router.replace({
          name: 'create-order.tire_service',
          params: {
            services: encodeURI(JSON.stringify(this.innerValues)),
          },
        })
      }
    }

    this.$closeDrawer()
  }
}
