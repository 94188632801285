




// CORE
import { Component, Vue } from 'vue-property-decorator'

// COMPONENTS
import TableSettingForm from '@/components/forms/TableSettingForm.vue'

// STORE
import RepairModule from '@/store/modules/fleet/repair'

// INTERFACES
import { ITableHeader } from '@/store/types'

@Component({
  components: {
    TableSettingForm,
  },
})
export default class RepairTableSettingModal extends Vue {
  private get tableHeaders (): ITableHeader[] {
    return RepairModule.tableHeaders
  }

  private set tableHeaders (value: ITableHeader[]) {
    RepairModule.setTableHeaders(value)
  }

  private save (value: ITableHeader[]) {
    this.tableHeaders = value.map((item: ITableHeader) => ({ ...item }))
  }
}
