export default [
  {
    component: () => import('@/views/auth/AuthLayout.vue'),
    name: 'auth',
    path: '/auth',
    redirect: '/auth/login',
    children: [
      {
        component: () => import('@/views/auth/drawers/Login.vue'),
        name: 'auth.login',
        path: '/auth/login',
      },
      {
        component: () => import('@/views/auth/drawers/Registration.vue'),
        name: 'auth.registration',
        path: '/auth/registration',
      },
      {
        component: () => import('@/views/auth/drawers/EmailForRecovery.vue'),
        name: 'auth.mailForRestore',
        path: '/auth/recovery',
      },
      {
        component: () => import('@/views/auth/drawers/PasswordRecovery.vue'),
        name: 'auth.restore',
        path: '/auth/restore/:token',
      },
    ],
  },
]
