































// CORE
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// COMPONENT
import IntermarkLabel from '@/components/IntermarkLabel.vue'
import AuthFormWrapper from '@/components/AuthFormWrapper.vue'
import TechWork from '@/components/TechWork.vue'

@Component({
  components: {
    IntermarkLabel,
    AuthFormWrapper,
    TechWork,
  },
})
export default class ErrorPage extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'TechWork',
    }
  }
}
