





import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Компонент спиннера. Управляется из SystemModule.
 * Перед каждым асинхронным запросом увеличивает счетчик на 1, после ответа или ошибки уменьшает счетчик на 1
 * Пока счетчик больше 0, спиннер крутится
 *
 * Если есть запросы, для которых не нужен спиннер (например для асинхронного селекта):
 * 1) в такие запросы в params нужно подложить какой-то параметр (например, spinner: false)
 * 2) в @/utils/services/api.ts нужно будет добавить проверку на этот параметр и не увеличивать счетчик, если этот параметр есть
 */

@Component
export default class Loader extends Vue {
  @Prop({ default: false })
  private visible!: boolean
}
