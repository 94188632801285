
























































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import InsuranceModule from '@/store/modules/insurance-statistics'
import SystemMixin from '@/mixins/SystemMixin'
import { DictionaryInsuranceStatisticsResource, InsuranceCarParkIdGetParams } from '@/store/types'
import { tableOptionsToFilters } from '@/utils/functions'

interface IInsuranceFilterForm {
  vin?: string,
  stateNumber?: string,
  passportNumber?: string,
  accidentStartDate?: string,
  accidentEndDate?: string,
  category?: string,
  guilt?: string,
}

@Component({
  components: {
    DrawerFooter,
  },
})
export default class InsuranceFilterForm extends Mixins(SystemMixin) {
  private get dictionaries (): DictionaryInsuranceStatisticsResource {
    return InsuranceModule.insurancesDictionaries
  }

  private form: IInsuranceFilterForm = {
    vin: undefined,
    stateNumber: undefined,
    accidentStartDate: '',
    accidentEndDate: '',
    category: undefined,
    guilt: undefined,
  }

  private get activeFiltersCount (){
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof IInsuranceFilterForm] && item !== 'endDate'){
        count++
      }
    }
    return count
  }

  private get activeFilters (): InsuranceCarParkIdGetParams {
    return tableOptionsToFilters(InsuranceModule.filters)
  }

  private get accidentPeriod (): any {
    const period = []
    if (this.form.accidentStartDate && this.form.accidentEndDate){
      period.push(this.form.accidentStartDate)
      period.push(this.form.accidentEndDate)
    }
    return period
  }

  private set accidentPeriod (value: any) {
    this.form.accidentStartDate = value[0]
    this.form.accidentEndDate = value[1]
  }

  private created () {
    InsuranceModule.getFiltersDictionary(+this.entity)

    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }

  private clearFilters () {
    this.form = {
      vin: undefined,
      stateNumber: undefined,
      passportNumber: undefined,
      accidentStartDate: '',
      accidentEndDate: '',
      category: undefined,
      guilt: undefined,
    }

    this.applyFilters()
  }

  private applyFilters () {
    InsuranceModule.setFilters(this.form)
    InsuranceModule.getInsurances(+this.entity)
    this.$closeDrawer()
  }
}
