

























// CORE
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// INTERFACES
import { CountResource } from '@/store/types'

/**
 * Компонент для фильтров в FiltersPanel
 */
@Component({
  inheritAttrs: false,
})
export default class Chips extends Vue {
  @Prop({ required: true })
  readonly items!: CountResource[]

  @Prop({ default: 'all' })
  private value!: string

  @Prop({ default: true })
  private isAllChips!: boolean

  @Prop({ default: false })
  private isNoCount!: boolean

  private get allCount (): number {
    let result = 0

    this.items.forEach(item => {
      result += +item.count
    })

    return result
  }

  private get innerItems (): CountResource[] {
    return [{ name: 'все', value: 'all', count: this.allCount }, ...this.items]
  }

  private get innerValue (): string {
    return this.value
  }

  private set innerValue (value: string) {
    this.$emit('input', value === 'all' ? undefined : value)
  }

  private collapse = false
  private breakpointWidth = 0

  private mounted () {
    // NOTE: на маунте не отрисованы все чипсы
    setTimeout(() => {
      this.checkWidth()
    }, 300)

    window.addEventListener('resize', this.checkWidth.bind(this))
  }

  private checkWidth () {
    const container = (this.$refs.chips as HTMLElement)
    /**
     * Проверяем ширину скролла и ширину контейнера
     * если ширина скролла больше то сворачиваем в селект
     * и записываем на какой ширине экрана это должно происходить,
     * если ширина скролла не равна ширине контейнера + 1,
     * то увеличиваем записанную ширину экрана на разницу между шириной скролла и шириной контейнера + 1
     * в момент сворачивания, на мобилке багует потому что на маунте не все чипсы отрисовываются
     * NOTE: Если не запоминать ширину экрана в момент сворачивания чипсинок в селект,
     * то элемент будет менять своё отображение при каждом изменении ширины экрана,
     * так как селект не вылезает за края контейнера
     */
    if (container) {
      this.collapse = container.clientWidth < container.scrollWidth

      if (this.breakpointWidth) {
        if (window.innerWidth > this.breakpointWidth) {
          this.collapse = false
          // NOTE: Чтобы не баговало на мобилке
          this.breakpointWidth = 0
        } else {
          this.collapse = true
        }
      } else {
        if (container.clientWidth < container.scrollWidth) {
          this.breakpointWidth = window.innerWidth
          this.collapse = true

          if (container.clientWidth + 1 !== container.scrollWidth) {
            this.breakpointWidth += container.scrollWidth - (container.clientWidth + 1)
          }
        }
      }
    }
  }

  @Watch('items')
  private itemsWatch () {
    this.$nextTick(() => {
      this.breakpointWidth = 0
      this.checkWidth()
    })
  }
}
