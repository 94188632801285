// CORE
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// Stores
import store from '@/store'

import {
  ReportsFieldsGetRequest,
  ReportFieldsResource,
  ReportsReportIdDeleteRequest,
  ReportsReportIdGetRequest,
  ReportEditResource,
  ReportsCreateReportIdPutRequest,
} from '../types/schema'

/**
 * Удалить отчёт
 * Пересоздать файл отчёта (отправить на формирование)
 * Получить конкретный отчёт
 * Получить список полей для создания отчёта
 */
@Module({
  dynamic: true,
  name: 'reports',
  store,
})
class Reports extends VuexModule {
  fields: ReportFieldsResource[] = []
  report: ReportEditResource | null = null

  @Mutation
  setFields (payload: ReportFieldsResource[]) {
    this.fields = payload
  }

  @Mutation
  setReport (payload: ReportEditResource | null) {
    this.report = payload
  }

  @Action({ rawError: true })
  async fetchFields () {
    const { data } = await ReportsFieldsGetRequest()

    this.setFields(data)
  }

  // Получить данные для дровера ("Подробнее об отчёте")
  @Action({ rawError: true })
  async fetchReport (reportId: number) {
    const { data } = await ReportsReportIdGetRequest(reportId)

    this.setReport(data)

    return data
  }

  @Action({ rawError: true })
  async deleteReport (reportId: number) {
    await ReportsReportIdDeleteRequest(reportId)
  }

  @Action({ rawError: true })
  async refreshReportFile (reportId: number) {
    const { data } = await ReportsCreateReportIdPutRequest(reportId)

    return data
  }
}

const ReportsModule = getModule(Reports)

export default ReportsModule
