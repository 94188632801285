import { CarParksResource, CarsResource } from '@/store/types/schema'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import ProfileModule from '@/store/modules/profile'

@Component
export default class SystemMixin extends Vue {

  protected get role (): string {
    return this.$route.params.role
  }

  protected get entity (): string {
    return this.$route.params.entity
  }

  protected get carId (): string {
    return this.$route.params.carId
  }

  protected get entityFull (): CarParksResource | CarsResource | undefined {
    let result

    if (this.role === 'fleet' && ProfileModule.userResource?.carParks?.length) {
      result = ProfileModule.userResource.carParks
        .find((item: CarParksResource) => (
          item.id === +this.entity
        ))
    } else if (this.role === 'driver' && ProfileModule.userResource?.cars?.length) {
      result = ProfileModule.userResource.cars
        .find((item: CarsResource) => (
          item.id === +this.entity
        ))
    } else if (this.role === 'mechanic' && ProfileModule.userResource?.carGarages?.length) {
      result = ProfileModule.userResource.carGarages
        .find((item: CarParksResource) => (
          item.id === +this.entity
        ))
    }

    return result
  }

  protected get entityId (): string | undefined {
    let result

    if (this.role === 'fleet' && this.entityFull) {
      result = (this.entityFull as CarParksResource).partnerNumber
    } else if (this.role === 'driver' && this.entityFull) {
      result = (this.entityFull as CarsResource).vin
    } else if (this.role === 'mechanic' && this.entityFull) {
      result = (this.entityFull as CarParksResource).partnerNumber
    }

    return result
  }
}
