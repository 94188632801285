import Cookies from 'js-cookie'

export const recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY

export const authTokenName = process.env.VUE_APP_TOKEN_NAME || 'auth-token'
export const XSRFTokenName = process.env.VUE_APP_XSRF_TOKEN_NAME || 'XSRF-TOKEN'

export const baseURL =
  process.env.VUE_APP_BACKEND_API_ENDPOINT || 'https://test.ru/api'
export const endpoint =
  process.env.VUE_APP_BACKEND_ENDPOINT || 'https://test.ru/'

export function getAuthToken () {
  return Cookies.get(authTokenName)
}

export function getXSRFToken () {
  return Cookies.get(XSRFTokenName)
}
