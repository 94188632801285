// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// LIBRARIES
import { omit } from 'lodash'

// STORE
import store, { GET_DEFAULT_PAGINATOR } from '@/store'

//INTERFACE
import {
  CountResource,
  DictionaryInsuranceResource,
  InsuranceCarParkIdGetParams,
  InsuranceCountCarParkIdGetRequest,
  InsurancePaginator,
  InsuranceZipCarParkIdGetParams,
  InsuranceZipCarParkIdGetRequest,
} from '../types'

//REQUESTS
import {
  DictionariesInsuranceCarParkIdGetRequest,
  InsuranceCarParkIdGetRequest,
} from '@/store/types/schema'

// HELPERS
import { deleteEmpty } from '@/utils/functions'

@Module({
  dynamic: true,
  name: 'policies',
  store,
  namespaced: true,
})

class Policies extends VuexModule {
  /**
   * Работа со статусами - чипсами
   */
  statuses: CountResource[] = []

  statusId: number | string | undefined = 0

  @Action({ rawError: true })
  async getStatuses (payload: number) {
    const { data } = await InsuranceCountCarParkIdGetRequest(payload, deleteEmpty({ ...omit(this.policiesFilter, 'status') }))

    this.setStatuses(data)
  }

  @Mutation
  setStatuses (payload: CountResource[]) {
    Vue.set(this, 'statuses', payload)
  }

  @Mutation
  setActiveStatus (payload: string | undefined) {
    Vue.set(this.policiesFilter, 'status', payload)
    Vue.set(this.policiesFilter, 'page', 1)
  }

  /**
   * Работа с данными по полисам
   */
  policies: InsurancePaginator = GET_DEFAULT_PAGINATOR()

  @Action({ rawError: true })
  async getPolicies (carParkId: number) {
    const { data } = await InsuranceCarParkIdGetRequest(carParkId, this.policiesFilter)
    this.setPolicies(data)

    return data
  }

  @Mutation
  setPolicies (payload: InsurancePaginator) {
    Vue.set(this.policies, 'data', payload.data)
    Vue.set(this.policies, 'meta', payload.meta)
  }

  /**
   * Справочники для селектов
   */
  dictionaries: DictionaryInsuranceResource = {
    vin: [],
    carModelId: [],
    stateNumber: [],
    insuranceCompany: [],
    regionId: [],
    orderNumber: [],
    policyNumber: [],
  }

  @Action({ rawError: true })
  async getDictionaries (carParkId: any) {
    const { data } = await DictionariesInsuranceCarParkIdGetRequest(carParkId)
    this.setDictionaries(data)

    return data
  }

  @Mutation
  setDictionaries (payload: any) {
    Vue.set(this, 'dictionaries', payload)
  }

  // ================================== REQUEST-PARAMS ===========================================>

  policiesFilter: InsuranceCarParkIdGetParams = {
    page: 1, // default
    perPage: 5, // default
  }

  @Mutation
  setPoliciesFilter (payload: any) {
    Vue.set(this, 'policiesFilter', deleteEmpty({ ...this.policiesFilter, ...payload }))
  }

  // 0======================================== DOWNLOAD =========================================+o>

  @Action({ rawError: true })
  async getPoliciesPDFLink ({ carParkId, params }: { carParkId: number, params: InsuranceZipCarParkIdGetParams}) {
    const { data } = await InsuranceZipCarParkIdGetRequest(carParkId, params)

    return data
  }
}

const PoliciesModule = getModule(Policies)

export default PoliciesModule
