





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ShieldOk extends Vue {}
