





































































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import FleetPenaltyModule from '@/store/modules/fleet/penalty'
import { DictionaryPenaltyResource, IPenaltyFilterForm } from '@/store/types'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'

// HELPERS
import { tableOptionsToFilters } from '@/utils/functions'

@Component({
  components: {
    DrawerFooter,
  },
})
export default class PenaltyFilterForm extends Mixins(SystemMixin) {
  private form: IPenaltyFilterForm = {
    vin: undefined,
    carModelId: undefined,
    protocolNumber: undefined,
    articleViolation: undefined,
    eventPlace: undefined,
    authority: undefined,
    dateProtocolStartDate: undefined,
    dateProtocolEndDate: undefined,
    eventDateStartDate: undefined,
    eventDateEndDate: undefined,
    gettingOriginalStartDate: undefined,
    gettingOriginalEndDate: undefined,
    endSaleStartDate: undefined,
    endSaleEndDate: undefined,
    stateNumber: undefined,
    paymentInvoiceNumber: undefined,
  }

  private get activeFiltersCount (){
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof IPenaltyFilterForm] && item !== 'endDate'){
        count++
      }
    }
    return count
  }

  private get activeFilters (): IPenaltyFilterForm {
    return tableOptionsToFilters(FleetPenaltyModule.penaltyFilter)
  }

  private get filtersDictionary (): DictionaryPenaltyResource {
    return FleetPenaltyModule.filtersDictionary
  }

  private get dateProtocolPeriod (): any {
    const period = []
    if (this.form.dateProtocolStartDate && this.form.dateProtocolEndDate){
      period.push(this.form.dateProtocolStartDate)
      period.push(this.form.dateProtocolEndDate)
    }
    return period
  }

  private set dateProtocolPeriod (value: any) {
    this.form.dateProtocolStartDate = value[0]
    this.form.dateProtocolEndDate = value[1]
  }

  private get eventDatePeriod (): any {
    const period = []
    if (this.form.eventDateStartDate && this.form.eventDateEndDate){
      period.push(this.form.eventDateStartDate)
      period.push(this.form.eventDateEndDate)
    }
    return period
  }

  private set eventDatePeriod (value: any) {
    this.form.eventDateStartDate = value[0]
    this.form.eventDateEndDate = value[1]
  }

  private get gettingOriginalPeriod (): any {
    const period = []
    if (this.form.gettingOriginalStartDate && this.form.gettingOriginalEndDate){
      period.push(this.form.gettingOriginalStartDate)
      period.push(this.form.gettingOriginalEndDate)
    }
    return period
  }

  private set gettingOriginalPeriod (value: any) {
    this.form.gettingOriginalStartDate = value[0]
    this.form.gettingOriginalEndDate = value[1]
  }

  private get endSalePeriod (): any {
    const period = []
    if (this.form.endSaleStartDate && this.form.endSaleEndDate){
      period.push(this.form.endSaleStartDate)
      period.push(this.form.endSaleEndDate)
    }
    return period
  }

  private set endSalePeriod (value: any) {
    this.form.endSaleStartDate = value[0]
    this.form.endSaleEndDate = value[1]
  }

  private created () {
    FleetPenaltyModule.getFiltersDictionary(+this.entity)

    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }

  private clearFilters () {
    this.form = {
      vin: undefined,
      carModelId: undefined,
      protocolNumber: undefined,
      articleViolation: undefined,
      eventPlace: undefined,
      authority: undefined,
      dateProtocolStartDate: '',
      dateProtocolEndDate: '',
      eventDateStartDate: '',
      eventDateEndDate: '',
      gettingOriginalStartDate: '',
      gettingOriginalEndDate: '',
      endSaleStartDate: '',
      endSaleEndDate: '',
      stateNumber: '',
    }

    this.applyFilters(false)
  }

  private applyFilters (close = true) {
    FleetPenaltyModule.setFilters(this.form)
    FleetPenaltyModule.getFleetPenalties(+this.entity)
    FleetPenaltyModule.getStatusesDictionary(+this.entity)

    if (close) {
      this.$closeDrawer()
    }
  }
}
