





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class TextInput extends Vue {
  @Prop({ default: null })
  private value!: string

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: false })
  private small!: boolean

  @Prop({ default: null })
  private error!: string

  @Prop({ default: null })
  private hint!: string

  @Prop({ default: false })
  private required!: boolean

  @Prop({ default: null })
  private icon!: string

  @Prop({ default: null })
  private mask!: string

  // NOTE: Сделано для удобства использования, каждый props(hint, disabled, icon) может управляться отдельно.
  @Prop({ default: false })
  private readonly!: boolean

  // NOTE: Сделано для удобства использования, каждый props(hint, disabled, icon) может управляться отдельно.
  @Prop({ default: false })
  readonly isLoading!: boolean

  // NOTE: Value работает и без этого, однако не работает маска
  private innerValue = ''

  private mounted () {
    this.innerValue = this.value
  }

  private handleInput (value: string) {
    this.innerValue = value
    this.$emit('input', this.innerValue)
  }

  @Watch('value')
  private watchValue (value: string) {
    this.innerValue = value
  }
}
