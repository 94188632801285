









import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class NewsCard extends Vue {
  @Prop({ default: 'mdi-car-multiple' })
  readonly icon!: string
}
