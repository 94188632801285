import auth from '@/router/_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  {
    name: 'orders',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/orders',
    component: () => import('@/views/role/entity/orders/OrdersPageWrapper.vue'),
  },
  {
    component: () => import('@/views/role/entity/orders/FleetOrders.vue'),
    name: 'orders.tabs',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/orders/:tab',
  },
]
