



























































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DrawerFooter from '@/components/drawers/DrawerFooter.vue'

// STORE
import PoliciesModule from '@/store/modules/policies'

// Mixins
import SystemMixin from '@/mixins/SystemMixin'

// Interfaces
import NotifyMixin from '@/mixins/NotifyMixin'

import { tableOptionsToFilters } from '@/utils/functions'

interface IFilterForm {
  vin?: string,
  stateNumber?: string,
  carModelId?: string,
  policyNumber?: string,
  orderNumber?: string,
  startDate: string,
  endDate: string,
  insuranceCompany?: string,
  regionId?: string,
}

@Component({
  components: {
    DrawerFooter,
  },
})

export default class PoliciesFilterForm extends Mixins(SystemMixin, NotifyMixin) {
  private get dictionaries () {
    return PoliciesModule.dictionaries
  }

  private form: IFilterForm = {
    vin: undefined,
    stateNumber: undefined,
    carModelId: undefined,
    policyNumber: undefined,
    orderNumber: undefined,
    startDate: '',
    endDate: '',
    insuranceCompany: undefined,
    regionId: undefined,
  }

  private get period (): any {
    const period = []
    if (this.form.startDate && this.form.endDate){
      period.push(this.form.startDate)
      period.push(this.form.endDate)
    }
    return period
  }

  private set period (value: any) {
    this.form.startDate = value[0]
    this.form.endDate = value[1]
  }

  private get activeFilters () {
    return tableOptionsToFilters(PoliciesModule.policiesFilter)
  }

  private get activeFiltersCount () {
    let count = 0
    for (let item in this.form){
      if (this.form[item as keyof IFilterForm] && item !== 'endDate'){
        count++
      }
    }
    return count
  }

  private clearFilters () {
    this.form = {
      vin: undefined,
      stateNumber: undefined,
      carModelId: '',
      policyNumber: undefined,
      orderNumber: undefined,
      startDate: '',
      endDate: '',
      insuranceCompany: undefined,
      regionId: undefined,
    }

    this.applyFilters(false)
  }

  private applyFilters (close = true) {
    PoliciesModule.setPoliciesFilter(this.form)
    PoliciesModule.getPolicies(+this.entity)
      .catch(this.notifyError)
    PoliciesModule.getStatuses(+this.entity)

    if (close){
      this.$closeDrawer()
    }
  }

  private created () {
    this.form = {
      ...this.form,
      ...this.activeFilters,
    }
  }
}
