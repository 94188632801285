// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// SCHEMA TYPES
import {
  DictionariesRequestsCarParkIdGetParams,
  RequestTypeEnum,
  CreateRequestMaintenanceRequest,
  CreateTireServiceRequest,
  CreateActOfReconciliationRequest,
  CreateCarDrivingRequest,
  CreateCarCrashRequest,
  CreateCarBuybackRequest,
  CreateReplacementCarRequest,
  CreateReviewRequest,
  CreateRatingRequest,
  NameValueResource,
  RequestDictionary,
  RequestCrashCategory,
} from '@/store/types/schema'

// SCHEMA METHODS
import {
  RequestsRequestIdTypeTypeGetRequest,
  RequestsCarParkIdMaintenancePostRequest,
  RequestsCarParkIdTireServicePostRequest,
  RequestsCarParkIdActOfReconciliationPostRequest,
  RequestsCarParkIdCarBuybackPostRequest,
  RequestsCarParkIdRequestReviewPostRequest,
  RequestsCarParkIdCarDrivingPostRequest,
  RequestsCarParkIdCarCrashPostRequest,
  RequestsCarParkIdReplacementCarPostRequest,
  RequestsCarParkIdReviewRequestIdPostRequest,
  DictionariesRequestsCarParkIdGetRequest,
  DictionariesRequestsCrashCategoriesGetRequest,
  DictionariesRequestsCarParkIdInServiceGetRequest,
} from '@/store/types/schema'
import { booleanToNumber } from '@/utils/functions'

/**
 * Просматриваемая заявка (ORDER)
 * Создание заявки на ТО (MAINTENANCE)
 * Создание заявки на Шинный сервис (TAIR SERVICE)
 * Создание заявки на Акт сверки (ACT OF RECONCILIATION)
 * Создание заявки на Выкуп автомобиля (CAR BUYBACK)
 * Создание заявки на Отзы (REVIEW)
 * Создание заявки на Перегон авто (CAR DRIVING)
 * Создание заявки на ДТП (CAR CRASH)
 * Оценить заявку (SEND RATING)
 */

@Module({
  dynamic: true,
  name: 'fleet-order',
  store,
  namespaced: true,
})
class FleetOrder extends VuexModule {

  // 0==================================================== ORDER ====================================================+o>

  order: any = {}

  @Mutation
  setOrder (payload: any) {
    Vue.set(this, 'order', payload)
  }

  @Action({ rawError: true })
  async getOrder (payload: { id: number, type: RequestTypeEnum }) {
    const { data } = await RequestsRequestIdTypeTypeGetRequest(payload.id, payload.type)

    this.setOrder(data)
  }

  // 0==================================================== MAINTENANCE ====================================================+o>

  @Action({ rawError: true })
  async sendOrderMaintenance (payload: { entity: number, body: CreateRequestMaintenanceRequest}) {
    const { data } = await RequestsCarParkIdMaintenancePostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== TAIR SERVICE ====================================================+o>

  @Action({ rawError: true })
  async sendTairService (payload: { entity: number, body: CreateTireServiceRequest }) {
    const { data } = await RequestsCarParkIdTireServicePostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== ACT OF RECONCILIATION ====================================================+o>

  @Action({ rawError: true })
  async sendActOfReconciliation (payload: { entity: number, body: CreateActOfReconciliationRequest }) {
    const { data } = await RequestsCarParkIdActOfReconciliationPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== CAR BUYBACK ====================================================+o>

  @Action({ rawError: true })
  async sendCarBuyback (payload: { entity: number, body: CreateCarBuybackRequest }) {
    const { data } = await RequestsCarParkIdCarBuybackPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== REVIEW ====================================================+o>

  @Action({ rawError: true })
  async sendReview (payload: { entity: number, body: CreateReviewRequest }) {
    const { data } = await RequestsCarParkIdRequestReviewPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== CAR DRIVING ====================================================+o>

  @Action({ rawError: true })
  async sendCarDriving (payload: { entity: number, body: CreateCarDrivingRequest }) {
    const { data } = await RequestsCarParkIdCarDrivingPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== CAR CRASH ====================================================+o>

  @Action({ rawError: true })
  async sendCarCrash (payload: { entity: number, body: CreateCarCrashRequest }) {
    const { data } = await RequestsCarParkIdCarCrashPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== REPLACEMENT CAR ====================================================+o>

  @Action({ rawError: true })
  async sendReplacementCar (payload: { entity: number, body: CreateReplacementCarRequest }) {
    const { data } = await RequestsCarParkIdReplacementCarPostRequest(payload.entity, payload.body)
    return data
  }

  // 0==================================================== SEND RATING ====================================================+o>

  @Action({ rawError: true })
  async sendOrderRating (payload: { entity: number, orderID: number, body: CreateRatingRequest }) {
    const { data } = await RequestsCarParkIdReviewRequestIdPostRequest(payload.entity, payload.orderID, payload.body)
    return data
  }

  // 0============================== DICTIONARIES FOR ORDER ==============================+o>

  vinDictionary: string[] = []
  stateNumberDictionary: string[] = []
  orderNumberDictionary: string[] = []

  //В обслуживании
  vinServiceDictionary: string[] = []
  stateNumberServiceDictionary: string[] = []
  orderNumberServiceDictionary: string[] = []


  operationCitiesDictionary: string[] = []
  serviceCitiesDictionary: string[] = []

  carClassesDictionary: NameValueResource[] = []
  damageClassesDictionary: NameValueResource[] = []

  @Mutation
  setOrderDictionaries (payload: RequestDictionary) {
    this.vinDictionary = payload.vins
    this.stateNumberDictionary = payload.stateNumbers
    this.orderNumberDictionary = payload.orderNumbers
    this.operationCitiesDictionary = payload.operationCities
    this.serviceCitiesDictionary = payload.serviceCities
    this.carClassesDictionary = payload.carClasses
    this.damageClassesDictionary = payload.damageClasses
  }

  @Mutation
  setOrderServiceDictionaries (payload: RequestDictionary) {
    this.vinServiceDictionary = payload.vins
    this.stateNumberServiceDictionary = payload.stateNumbers
    this.orderNumberServiceDictionary = payload.orderNumbers
    this.operationCitiesDictionary = payload.operationCities
    this.serviceCitiesDictionary = payload.serviceCities
    this.carClassesDictionary = payload.carClasses
    this.damageClassesDictionary = payload.damageClasses
  }

  @Action({ rawError: true })
  async getOrderDictionaries (payload: { entity: number, params: DictionariesRequestsCarParkIdGetParams }) {
    const { data } = await DictionariesRequestsCarParkIdGetRequest(payload.entity, booleanToNumber(payload.params))
    this.setOrderDictionaries({
      ...data,
      stateNumbers: data.stateNumbers.map(item => item.slice(0, 6) + ' ' + item.slice(6)),
    })
  }

  @Action({ rawError: true })
  async getOrderServiceDictionaries (payload: { entity: number, params: DictionariesRequestsCarParkIdGetParams }) {
    const { data } = await DictionariesRequestsCarParkIdInServiceGetRequest(payload.entity, booleanToNumber(payload.params))
    this.setOrderServiceDictionaries({
      ...data,
      stateNumbers: data.stateNumbers.map(item => item.slice(0, 6) + ' ' + item.slice(6)),
    })
  }

  // 0============================== SUBCATEGORIES FOR ORDER ==============================+o>

  @Action({ rawError: true })
  async getSubcategories (payload: RequestCrashCategory ) {
    const { data } = await DictionariesRequestsCrashCategoriesGetRequest(payload.type)

    return data
  }
}

const FleetOrderModule = getModule(FleetOrder)

export default FleetOrderModule
