// CORE
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// Stores
import store from '@/store'

import {
  ReportsCarParkIdPostRequest,
  CreateReportRequest,
  ReportsReportIdCarParkIdPutRequest,
  ReportsCarParkCarParkIdGetRequest,
  ReportsCarParkCarParkIdGetParams,
  ReportDataResource,
  ReportsResource,
  ReportsSettingsCarParkIdPostRequest,
} from '@/store/types'

@Module({
  dynamic: true,
  name: 'fleet-reports',
  store,
  namespaced: true,
})
class FleetReports extends VuexModule {
  reports: ReportsResource[] = []

  get customReports (): ReportDataResource[] {
    const customRep = this.reports.find((item) => item.type === 'custom')

    return customRep?.reports ?? []
  }

  get defaultReports (): ReportDataResource[] {
    const defaultRep = this.reports.find((item) => item.type === 'default')

    return defaultRep?.reports ?? []
  }

  get allReports (): ReportDataResource[] {
    return this.defaultReports.concat(this.customReports)
  }

  @Mutation
  setReports (payload: ReportsResource[]) {
    this.reports = payload
  }

  // Получить список конфигураций отчётов
  @Action({ rawError: true })
  async fetchReports ({
    carParkId,
    params,
  }: {
    carParkId: number,
    params?: ReportsCarParkCarParkIdGetParams,
  }) {
    const { data } = await ReportsCarParkCarParkIdGetRequest(carParkId, params)
    this.setReports(data)
  }

  // Отправить шаблон отчёта без формирования
  @Action({ rawError: true })
  async createReportSetting ({
    carParkId,
    body,
  }: {
    carParkId: number,
    body: CreateReportRequest,
  }) {
    const { data } = await ReportsSettingsCarParkIdPostRequest(carParkId, body)

    return data
  }

  // Отправить конфигурацию отчёта и сформировать отчёт
  @Action({ rawError: true })
  async createReport ({
    carParkId,
    body,
  }: {
    carParkId: number,
    body: CreateReportRequest,
  }) {
    const { data } = await ReportsCarParkIdPostRequest(carParkId, body)

    return data
  }

  // Редактировать отчёт
  @Action({ rawError: true })
  async updateReport ({
    reportId,
    carParkId,
    body,
  }: {
    reportId: number,
    carParkId: number,
    body: CreateReportRequest,
  }) {
    const { data } = await ReportsReportIdCarParkIdPutRequest(
      reportId,
      carParkId,
      body,
    )

    return data
  }
}

const FleetReportsModule = getModule(FleetReports)

export default FleetReportsModule
