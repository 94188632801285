import auth from '@/router/_middleware/auth'
import roleValidate from './_middleware/roleValidate'

export default [
  {
    component: () => import('@/views/role/entity/policies/PoliciesLayout.vue'),
    name: '',
    meta: { middleware: [auth, roleValidate] },
    path: '/:role/:entity/policies',
    redirect: '/:role/:entity/policies/policies',
    children: [
      {
        name: 'policies',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/policies',
        component: () => import('@/views/role/entity/policies/tabs/PoliciesTab.vue'),
      },
      {
        name: 'policies.insurance',
        meta: { middleware: [auth, roleValidate] },
        path: '/:role/:entity/insurance-statistics',
        component: () => import('@/views/role/entity/policies/tabs/InsuranceStatisticsTab.vue'),
      },
    ],
  },
]
