/* eslint-disable */
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

// ICONS
import AlphaVBox from '@/components/_uikit/icons/AlphaVBox.vue'
import Autorenew from '@/components/_uikit/icons/Autorenew.vue'
import CardsDiamond from '@/components/_uikit/icons/CardsDiamond.vue'
import CarouselPagination from '@/components/_uikit/icons/CarouselPagination.vue'
import Close from '@/components/_uikit/icons/Close.vue'
import DragVertical from '@/components/_uikit/icons/DragVertical.vue'
import ProgressBar from '@/components/_uikit/icons/ProgressBar.vue'
import ShieldOk from '@/components/_uikit/icons/ShieldOk.vue'
import ViewColumn from '@/components/_uikit/icons/ViewColumn.vue'
// CAR BRAND
import Audi from '@/components/_uikit/icons/car-brand/Audi.vue'
import BMW from '@/components/_uikit/icons/car-brand/BMW.vue'
import Cadillac from '@/components/_uikit/icons/car-brand/Cadillac.vue'
import Chevrolet from '@/components/_uikit/icons/car-brand/Chevrolet.vue'
import Citroen from '@/components/_uikit/icons/car-brand/Citroen.vue'
import Dodge from '@/components/_uikit/icons/car-brand/Dodge.vue'
import Fiat from '@/components/_uikit/icons/car-brand/Fiat.vue'
import Ford from '@/components/_uikit/icons/car-brand/Ford.vue'
import Geely from '@/components/_uikit/icons/car-brand/Geely.vue'
import Haval from '@/components/_uikit/icons/car-brand/Haval.vue'
import Honda from '@/components/_uikit/icons/car-brand/Honda.vue'
import Hyundai from '@/components/_uikit/icons/car-brand/Hyundai.vue'
import Infiniti from '@/components/_uikit/icons/car-brand/Infiniti.vue'
import Iveco from '@/components/_uikit/icons/car-brand/Iveco.vue'
import Jaguar from '@/components/_uikit/icons/car-brand/Jaguar.vue'
import Jeep from '@/components/_uikit/icons/car-brand/Jeep.vue'
import Kia from '@/components/_uikit/icons/car-brand/Kia.vue'
import Lada from '@/components/_uikit/icons/car-brand/Lada.vue'
import LandRover from '@/components/_uikit/icons/car-brand/LandRover.vue'
import Lexus from '@/components/_uikit/icons/car-brand/Lexus.vue'
import Mazda from '@/components/_uikit/icons/car-brand/Mazda.vue'
import Mercedes from '@/components/_uikit/icons/car-brand/Mercedes.vue'
import Mini from '@/components/_uikit/icons/car-brand/Mini.vue'
import Mitsubishi from '@/components/_uikit/icons/car-brand/Mitsubishi.vue'
import Nissan from '@/components/_uikit/icons/car-brand/Nissan.vue'
import Opel from '@/components/_uikit/icons/car-brand/Opel.vue'
import Peugeot from '@/components/_uikit/icons/car-brand/Peugeot.vue'
import Porsche from '@/components/_uikit/icons/car-brand/Porsche.vue'
import Renault from '@/components/_uikit/icons/car-brand/Renault.vue'
import Skoda from '@/components/_uikit/icons/car-brand/Skoda.vue'
import SsangYong from '@/components/_uikit/icons/car-brand/SsangYong.vue'
import Subaru from '@/components/_uikit/icons/car-brand/Subaru.vue'
import Suzuki from '@/components/_uikit/icons/car-brand/Suzuki.vue'
import Toyota from '@/components/_uikit/icons/car-brand/Toyota.vue'
import Uaz from '@/components/_uikit/icons/car-brand/Uaz.vue'
import Volkswagen from '@/components/_uikit/icons/car-brand/Volkswagen.vue'
import Volvo from '@/components/_uikit/icons/car-brand/Volvo.vue'

Vue.use(Vuetify)

/*
  * Цвета на всякий случай. Пользоваться темой не удобно из-за ховеров
  * и того, что 'rgba(217, 43, 38, 0.8)' не является валидным значением.
  * Вместо невалидного значения подставится дефолтный белый (#FFFFFF !important).
  * Однако можно пользоваться в случаях когда используется base цвет
*/

const COLORS = {
  red: {
    base: '#D92B26',
    lighten4: 'rgba(217, 43, 38, 0.2)',
    lighten3: 'rgba(217, 43, 38, 0.4)',
    lighten2: 'rgba(217, 43, 38, 0.6)',
    lighten1: 'rgba(217, 43, 38, 0.8)',
    darken1: '#B82520',
    darken2: '#981E1B',
    darken3: '#771815',
    darken4: '#57110F',
  },
  darkBlue: {
    base: '#315EAB',
    lighten4: 'rgba(49, 94, 171, 0.2)',
    lighten3: 'rgba(49, 94, 171, 0.4)',
    lighten2: 'rgba(49, 94, 171, 0.6)',
    lighten1: 'rgba(49, 94, 171, 0.8)',
    darken1: '#2A5091',
    darken2: '#224278',
    darken3: '#1B345E',
    darken4: '#142644',
  },
  greyBlue: {
    base: '#C9D6ED',
    lighten4: '#F4F7FB',
    lighten3: '#EAEFF8',
    lighten2: '#DFE7F4',
    lighten1: '#D4DEF1',
    darken1: '#ABB6C9',
    darken2: '#8D96A6',
    darken3: '#6F7682',
    darken4: '#50565F',
  },
  greyBlueDarkTheme: {
    base: '#5A606B',
    lighten4: '#2F3032',
    lighten3: '#3A3C41',
    lighten2: '#44484E',
    lighten1: '#4F545D',
    darken1: '#ABB6C9',
    darken2: '#8D96A6',
    darken3: '#6F7682',
    darken4: '#50565F',
  },
  blue: {
    base: '#32A2F3',
    lighten1: 'rgba(50, 162, 243, 0.2)',
  },
  orange: {
    base: '#FEA236',
    lighten1: 'rgba(254, 162, 54, 0.2)',
  },
  green: {
    base: '#1FC187',
    lighten1: '#D2F3E7',
  },
  greenDarkTheme: {
    base: '#1FC187',
    lighten1: '#234438',
  },
  gray: '#999999',
}

const CUSTOM_ICONS = {
  alphaVBox: { component: AlphaVBox },
  autorenew: { component: Autorenew },
  cardsDiamond: { component: CardsDiamond },
  carouselPagination: { component: CarouselPagination },
  closeChips: { component: Close },
  dragVertical: { component: DragVertical },
  progressBar: { component: ProgressBar },
  shieldOk: { component: ShieldOk },
  viewColumn: { component: ViewColumn },
  // CAR BRAND
  audi : { component: Audi },
  bmw : { component: BMW },
  cadillac : { component: Cadillac },
  chevrolet : { component: Chevrolet },
  citroen : { component: Citroen },
  dodge : { component: Dodge },
  fiat : { component: Fiat },
  ford : { component: Ford },
  geely : { component: Geely },
  haval : { component: Haval },
  honda : { component: Honda },
  hyundai : { component: Hyundai },
  infiniti : { component: Infiniti },
  iveco : { component: Iveco },
  jaguar : { component: Jaguar },
  jeep : { component: Jeep },
  kia : { component: Kia },
  lada : { component: Lada },
  landRover : { component: LandRover },
  lexus : { component: Lexus },
  mazda : { component: Mazda },
  mercedes : { component: Mercedes },
  mini : { component: Mini },
  mitsubishi : { component: Mitsubishi },
  nissan : { component: Nissan },
  opel : { component: Opel },
  peugeot : { component: Peugeot },
  porsche: { component: Porsche },
  renault : { component: Renault },
  skoda : { component: Skoda },
  ssangYong : { component: SsangYong },
  subaru : { component: Subaru },
  suzuki : { component: Suzuki },
  toyota: { component: Toyota },
  uaz : { component: Uaz },
  volkswagen : { component: Volkswagen },
  volvo : { component: Volvo },
}

export default new Vuetify({
  icons: {
    values: CUSTOM_ICONS,
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        black: '#242424', // Станет белым в тёмной теме
        white: '#FFFFFF', // Станет чёрным в тёмной теме
        error: COLORS.red.base,
        primary: COLORS.darkBlue.base,
        'grey-blue': COLORS.greyBlue,
        disabled: COLORS.greyBlue,
        success: COLORS.green,
        warning: COLORS.orange,
      },
      dark: {
        black: '#FFFFFF', // Станет чёрным в светлой теме
        white: '#242424', // Станет белым в светлой теме
        error: COLORS.red.base,
        primary: COLORS.darkBlue.base,
        'grey-blue': COLORS.greyBlueDarkTheme,
        disabled: COLORS.greyBlueDarkTheme,
        success: COLORS.greenDarkTheme,
        warning: COLORS.orange,
      },
    },
  },
  breakpoint: {
    /**
     * +16 ко всем брейк поинтам (так и не понял зачем так если менять scrollBarWidth ничего не меняется
     * также для некоторых брейпоинтов (1024 на пример) не надо плюсовать 16)
     * значение указывает где заканчивается размер
     * например mdOnly это от 1041(sm) до 1297(md)
     */
    thresholds: {
      xs: 768,
      sm: 1024,
      md: 1296,
      lg: 1696,
    },
    mobileBreakpoint: 1024,
    scrollBarWidth: 16,
  },
})
