
// CORE
import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// STORE
import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'

// INTERFACE
import { ITableHeader } from '../../types/vuetufy'

// SCHEMA METHODS
import {
  CarParksRepairCarParkIdGetRequest,
  DictionariesCarParkRepairCarParkIdGetRequest,
  InspectionReportAgreementInspectionReportIdPutRequest,
} from '../../types/schema'

// SCHEMA TYPES
import {
  CarParksRepairCarParkIdGetParams,
  CountResource,
  CarParkRepairPaginator,
  DictionaryCarParkRepairResource,
  AgreementInspectionReportRequestBody,
} from '../../types/schema'

import { tableOptionsToParams } from '@/utils/functions'
interface IRepairTableGetParamsWithStatus extends CarParksRepairCarParkIdGetParams {
  status?: string,
}

/**
 * Хардкодные заголовки для таблицы
 * Настройки таблицы
 * Сохранение их в локал сторейдж
 * Запрос/фильтрация данных для таблицы
 */

@Module({
  dynamic: true,
  name: 'repair',
  store,
  namespaced: true,
})
class Repair extends VuexModule {
  // 0==================================================== TABLE HEADERS ====================================================+o>
  tableHeaders: ITableHeader[] = [
    {
      visible: true,
      text: 'VIN',
      value: 'vin',
      align: 'start',
    },
    {
      visible: true,
      text: 'Гос. №',
      value: 'stateNumber',
    },
    {
      visible: true,
      text: '№ заказа',
      value: 'orderNumber',
      cellClass: 'small-cell',
      class: 'small-cell',
    },
    {
      visible: true,
      text: 'Модель',
      value: 'car',
    },
    {
      visible: true,
      text: 'Город',
      value: 'region',
    },
    {
      visible: false,
      text: 'Дата передачи',
      value: 'dateOfActOfTransfer',
    },
    {
      visible: true,
      text: 'Дата акта возврата',
      value: 'dateOfActOfReturn',
    },
    {
      visible: true,
      text: 'Всего',
      value: 'costAll',
    },
    {
      visible: true,
      text: 'Естественный износ',
      value: 'costStandard',
    },
    {
      visible: true,
      text: 'Неестественный износ',
      value: 'costNotstandard',
    },
    {
      visible: true,
      text: 'К оплате',
      value: 'costReinvoiceservice',
    },
    {
      visible: true,
      text: 'Детализация и отчет',
      value: 'fileExcel',
      sortable: false,
      align: 'center',
    },
    {
      visible: true,
      text: 'Статус',
      value: 'status',
      sortable: false,
    },
    // {
    //   visible: true,
    //   text: 'Отчет',
    //   value: 'filePdf',
    //   sortable: false,
    // },
  ]

  @Mutation
  setTableHeaders (payload: any, storage = true) {
    Vue.set(this, 'tableHeaders', payload)

    if (storage) { // чтобы не записывать при чтении из локал сторейдж
      // Сохранили в локал сторейдж
      localStorage.setItem('repairTableHeaders', JSON.stringify(this.tableHeaders))
    }
  }

  @Action
  syncTableHeaders () {
    // проверяем наличие сохранённых параметров
    if (localStorage.carparkTableHeaders) {
      // записываем из локал сторейдж в стор
      this.setTableHeaders(JSON.parse(localStorage.getItem('repairTableHeaders') as string), false)
    }
  }

  // 0==================================================== TABLE DATA ====================================================+o>

  tableData: CarParkRepairPaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setTableData (payload: CarParkRepairPaginator) {
    Vue.set(this, 'tableData', payload)
  }

  @Action({ rawError: true })
  async getTableData (payload: number) {
    const { data } = await CarParksRepairCarParkIdGetRequest(payload, tableOptionsToParams(this.filters))

    this.setTableData(data)
  }

  // 0==================================================== DICTIONARIES ====================================================+o>

  dictionaries: DictionaryCarParkRepairResource = {
    vin: [],
    stateNumber: [],
    orderNumber: [],
    carModelId: [],
    regionId: [],
    repairCost: [],
  }

  @Action({ rawError: true })
  async getFiltersDictionary (carParkId: any) {
    const { data } = await DictionariesCarParkRepairCarParkIdGetRequest(carParkId)
    this.setFiltersDictionaries(data)

    return data
  }

  @Mutation
  setFiltersDictionaries (payload: any) {
    Vue.set(this, 'dictionaries', payload)
  }

  // 0==================================================== STATUSES DICTIONARIES ====================================================+o>

  statusDictionary: CountResource[] = []

  @Action({ rawError: true })
  // eslint-disable-next-line
  async getStatusDictionary (carParkId?: number) {
    const data: CountResource[] = [
      {
        name: 'вовзращено',
        count: this.tableData?.meta?.total ?? 0,
        value: 'all',
      },
    ]

    this.setStatusDictionary(data)

    return data
  }

  @Mutation
  setStatusDictionary (payload: any) {
    Vue.set(this, 'statusDictionary', payload)
  }

  // 0==================================================== DICTIONARY ====================================================+o>

  repairStatuses: CountResource[] = []

  activeStatus = 'returned'

  @Mutation
  setRepairStatuses (payload: CountResource[]) {
    Vue.set(this, 'repairStatuses', payload)
  }

  // 0==================================================== REQUEST-PARAMS ====================================================+o>

  filters: IRepairTableGetParamsWithStatus = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setActiveStatus (payload: string | undefined) {
    Vue.set(this.filters, 'status', payload)
    Vue.set(this.filters, 'page', 1)
  }

  @Mutation
  setFilters (payload: IRepairTableGetParamsWithStatus) {
    Vue.set(this, 'filters', { ...GET_DEFAULT_TABLE_FILTER(), ...payload })
  }

  @Mutation
  resetFilters () {
    Vue.set(this, 'filters', GET_DEFAULT_TABLE_FILTER())
  }

  // 0==================================================== SEND APPROVE ====================================================+o>

  @Action({ rawError: true })
  async sendApprove (payload: { inspectionReportId: number, body: AgreementInspectionReportRequestBody }) {
    const { data } = await InspectionReportAgreementInspectionReportIdPutRequest(payload.inspectionReportId, payload.body)
    return data
  }
}

const RepairModule = getModule(Repair)

export default RepairModule
