var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"form auth-form__form registration-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('Heading',{staticClass:"form__field-row form__field-row--center",attrs:{"size":"h2","mod":"medium"}},[_vm._v("Изменение пароля")]),_c('div',{staticClass:"form__field-row"},[_c('ValidationProvider',{key:"oldPassword",staticClass:"form__field",attrs:{"tag":"div","rules":"required","name":"oldPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"error":errors[0],"required":true,"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Старый пароль"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"form__field-row"},[_c('ValidationProvider',{key:"newPassword",staticClass:"form__field",attrs:{"tag":"div","rules":"required|passwordRequirements","name":"newPassword","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"error":errors[0],"required":true,"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Новый пароль"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"form__field-subrow"},[_c('ValidationProvider',{key:"newPasswordConfirmation",staticClass:"form__field",attrs:{"tag":"div","rules":"required|confirmed:newPassword","name":"newPasswordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"error":errors[0],"required":true,"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.newPasswordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "newPasswordConfirmation", $$v)},expression:"form.newPasswordConfirmation"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Повторите пароль"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"form__field-subrow"},[_c('ExpansionMessageCard',[_c('Paragraph',{attrs:{"size":"body","weight":"regular"},domProps:{"innerHTML":_vm._s('мин. 10&nbsp;символов, латинские буквы, 1&nbsp;большая буква, 1&nbsp;цифра, 1&nbsp;спецсимвол (!.,?-*;$ и т.д).')}})],1)],1),_c('SmallModalFooter',{attrs:{"disabled":invalid},on:{"click:action":_vm.handleClickSave},scopedSlots:_vm._u([{key:"action",fn:function(){return [_vm._v("Сохранить")]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }