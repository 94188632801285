import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class ThemeMixin extends Vue {
  private get isDarkTheme (): boolean {
    if (localStorage.getItem('theme')?.length) {
      this.$vuetify.theme.dark = !!localStorage.getItem('theme')
    }

    return this.$vuetify.theme.dark
  }

  private set isDarkTheme (value: boolean) {
    this.$vuetify.theme.dark = value

    if (value) {
      localStorage.setItem('theme', 'dark')
    } else {
      localStorage.removeItem('theme')
    }
  }
}
