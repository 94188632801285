





















































// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// INTERFACES
import { CreateRatingRequest, RatingRequest, RequestTypeEnum } from '@/store/types'

// COMPONENTS
import ModalHeader from '@/components/modals/components/ModalHeader.vue'
import FleetOrderModule from '@/store/modules/fleet/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import DriverOrderModule from '@/store/modules/driver/order'

@Component({
  components: {
    ModalHeader,
  },
})
export default class OrderRatingModal extends Mixins(SystemMixin) {
  @Prop({ default: () => ({}) })
  private options!: { id: number, type: RequestTypeEnum }

  private isDone = false

  private form: CreateRatingRequest = {
    rating: 0,
    comment: '',
  }

  private response: RatingRequest = {
    id: 0,
    rating: 0,
    comment: '',
  }

  private get color (): string {
    switch (this.response.rating) {
    case 5:
      return 'green'
    case 4:
      return 'blue'
    case 3:
      return 'orange'
    case 2:
    case 1:
      return 'red'
    default: return 'transparent'
    }
  }

  private send () {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      FleetOrderModule.sendOrderRating({
        entity: +this.entity,
        orderID: this.options.id,
        body: this.form,
      }).then((res) => {
        this.response = res
        this.isDone = true
      })
    } else {
      DriverOrderModule.sendOrderRating({
        entity: +this.entity,
        orderID: this.options.id,
        body: this.form,
      }).then((res) => {
        this.response = res
        this.isDone = true
      })
    }
  }

  private toOrder () {
    this.$router.push({ name: `view-order.${this.options.type}`, params: { orderId: this.options.id.toString() } })
    this.$closeModal()
  }
}
